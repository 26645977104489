import { Container, experimentalStyled as styled } from "@material-ui/core";

const FloatingItem = styled(Container)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    height: "auto",
    textAlign: "flex-start",
    // color: theme.palette.text.secondary,
}));

export { FloatingItem };
