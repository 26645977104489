import React from "react";
import ServicesSection from "src/components/services/ServicesSection";
import {
    Box,
    Button,
    Container,
    List,
    ListItem,
    ListItemText,
    Typography,
    Grid,
    Paper,
    useTheme,
    experimentalStyled as styled,
    ListItemAvatar,
    ListItemIcon,
    Avatar,
} from "@material-ui/core";
import { CheckCircle } from "@material-ui/icons";
import LockOpen from "@material-ui/icons/LockOpen";
import { FloatingItem } from "../shared/FloatingItem";
import { useNavigate } from "react-router";
import ContactUsButton from "../shared/ContactUsButton";

const Drone = () => {
    const navigate = useNavigate();
    const theme = useTheme();

    return (
        <Grid
            container
            height="auto"
            display="flex"
            flexDirection="row"
            justifyContent="space-evenly"
            pt={{ xs: 5, sm: 10 }}
            alignItems="center"
            id="products"
        >
            <Grid item xs={12} sm={12} md={6} zeroMinWidth alignSelf="center">
                <FloatingItem>
                    <Typography
                        variant="h3"
                        p={1}
                        color={theme.palette.primary.main}
                    >
                        With Logit, you can fly
                    </Typography>
                </FloatingItem>
                <FloatingItem>
                    <Typography variant="h4" p={1} color="text.secondary">
                        Whether you need a stunning 4k video of your industry
                        leading cattle ranch, or a point-cloud assessment of
                        your gravel pit, Logit has you covered.
                    </Typography>
                </FloatingItem>
            </Grid>
            <Grid item>
                <ContactUsButton title="Book a flight" />
            </Grid>
        </Grid>
    );
};

export default Drone;
