import React from "react";
import ServicesSection from "src/components/services/ServicesSection";
import {
    Box,
    Button,
    Container,
    List,
    ListItem,
    ListItemText,
    Typography,
    Grid,
    Paper,
    useTheme,
    experimentalStyled as styled,
    ListItemAvatar,
    ListItemIcon,
    Avatar,
} from "@material-ui/core";
import { CheckCircle } from "@material-ui/icons";
import LockOpen from "@material-ui/icons/LockOpen";
import { FloatingItem } from "../shared/FloatingItem";
import { useNavigate } from "react-router";
import ContactUsButton from "../shared/ContactUsButton";

const Automate = () => {
    const navigate = useNavigate();
    const theme = useTheme();

    return (
        <Grid
            container
            height="auto"
            display="flex"
            flexDirection="row"
            justifyContent="space-evenly"
            pt={{ xs: 5, sm: 10 }}
            alignItems="center"
            id="products"
        >
            <Grid item xs={12} sm={12} md={6} zeroMinWidth alignSelf="center">
                <FloatingItem>
                    <Typography
                        variant="h3"
                        p={1}
                        color={theme.palette.primary.main}
                    >
                        Tired of repetitive tasks? Nagging feeling there's a
                        better way? There is
                    </Typography>
                </FloatingItem>
                <FloatingItem>
                    <Typography variant="h4" p={1} color="text.secondary">
                        At Logit, we leverage our programming skill to save you
                        time and money.
                    </Typography>
                </FloatingItem>

                <FloatingItem>
                    <Typography
                        variant="h5"
                        p={1}
                        color={theme.palette.secondary.main}
                    >
                        A Concrete Example: Automating Trucking Statements for a
                        Logging Contractor
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <CheckCircle
                                    sx={{
                                        color: theme.palette.primary.main,
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText
                                primary="Contractor receives a statement of shipments via Email"
                                primaryTypographyProps={{
                                    variant: "body1",
                                }}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <CheckCircle
                                    sx={{
                                        color: theme.palette.primary.main,
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText
                                primary="A Logit service recognizes the email automatically"
                                primaryTypographyProps={{
                                    variant: "body1",
                                }}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <CheckCircle
                                    sx={{
                                        color: theme.palette.primary.main,
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText
                                primary="Total payment is calculated for each Trucking company"
                                primaryTypographyProps={{
                                    variant: "body1",
                                }}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <CheckCircle
                                    sx={{
                                        color: theme.palette.primary.main,
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText
                                primary="An Email is sent to each Trucking company with its calculated statement"
                                primaryTypographyProps={{
                                    variant: "body1",
                                }}
                            />
                        </ListItem>
                    </List>
                </FloatingItem>
                <FloatingItem>
                    <Typography variant="h4" p={1} color="text.secondary">
                        This example has saved our client's countless hours and
                        removed user-error from their billing process.
                    </Typography>
                </FloatingItem>
            </Grid>
            <Grid item>
                <ContactUsButton title="Reach out" />
            </Grid>
        </Grid>
    );
};

export default Automate;
