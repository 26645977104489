import * as Yup from 'yup';

export default  
  Yup.object().shape({
    email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
    name: Yup.string().max(255).required('Name is required'),
    phonenumber: Yup.string().max(11).matches(/^[0-9]*$/, "Must contain numbers ranging from 0-9"),
    password: Yup.string().max(255).matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&,:{}<>.])[A-Za-z\d@$!%*#?&,:{}<>.]{8,}$/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    ).required("Password is required"),
    passwordConfirmation: Yup.string()
     .oneOf([Yup.ref('password'), null], 'Passwords must match').required("Password confirmation is required"),
    policy: Yup.boolean().oneOf([true], 'This field must be checked')
  })
