import { Navigate } from "react-router-dom";
import Payment from "./pages/Payment";
import NotFound from "src/pages/NotFound";
import MainLayout from "./components/MainLayout";

const paymentRoutes = [
    {
        path: "payment",
        element: <MainLayout />,
        children: [
            { path: "", element: <Payment /> },
            { path: "*", element: <Navigate to="/404" /> },
            { path: "404", element: <NotFound /> },
        ],
    },
];

export default paymentRoutes;
