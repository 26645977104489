import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import App from "./App";
import { persistor } from "src/redux/Store";
import { PersistGate } from "redux-persist/integration/react";

ReactDOM.render(
    <BrowserRouter>
        <PersistGate loading={null} persistor={persistor}>
            <App />
        </PersistGate>
    </BrowserRouter>,
    document.getElementById("root")
);

serviceWorker.unregister();
