import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import {
    AppBar,
    Box,
    Hidden,
    IconButton,
    Toolbar,
    Typography,
} from "@material-ui/core";
import { User as UserIcon } from "react-feather";
import MenuIcon from "@material-ui/icons/Menu";
import Thumbnail from "./Thumbnail";

import { LANDING_PAGE } from "src/Constants";

const DashboardNavbar = ({ onMobileNavOpen, ...rest }) => {
    return (
        <AppBar elevation={0} {...rest}>
            <Toolbar>
                <RouterLink to={LANDING_PAGE}>
                    <Thumbnail />
                </RouterLink>
                <Typography variant="h6" color="white" sx={{ marginLeft: 2 }}>
                    LogitNow
                </Typography>
                <Box sx={{ flexGrow: 1 }} />
                <Hidden lgDown>
                    <RouterLink to={"/app/account"}>
                        <IconButton>
                            <UserIcon color="white" />
                        </IconButton>
                    </RouterLink>
                    {/* <IconButton color="inherit">

                        <Badge
                            badgeContent={notifications.length}
                            color="primary"
                            variant="dot"
                        >
                            <NotificationsIcon />
                        </Badge>
                    </IconButton> */}
                </Hidden>
                <Hidden lgUp>
                    <IconButton color="inherit" onClick={onMobileNavOpen}>
                        <MenuIcon />
                    </IconButton>
                </Hidden>
            </Toolbar>
        </AppBar>
    );
};

DashboardNavbar.propTypes = {
    onMobileNavOpen: PropTypes.func,
};

export default DashboardNavbar;
