
function IsSaleValid(sale){
    if(sale){
        if(sale.data){
            if(sale.ffid && sale.region && sale.geographic_location && sale.net_volume && sale.stumpage_rate &&sale.closed_time){
                return true;
            }
        }
        else if(sale.ffid){
            return true;
        }
    }
    return false;
}

export {IsSaleValid};