import { useNavigate } from "react-router";

const { Button } = require("@mui/material");
const { FloatingItem } = require("./FloatingItem");
const { LockOpen } = require("@material-ui/icons");

const SignUpButton = () => {
    const navigate = useNavigate();
    return (
        <FloatingItem>
            <Button
                large
                display="inline"
                color="accent"
                sx={{
                    color: "primary",
                    marginRight: 0.5,
                }}
                variant="contained"
                onClick={() => navigate("/register")}
                endIcon={<LockOpen />}
            >
                Start Free Trial
            </Button>
        </FloatingItem>
    );
};

export default SignUpButton;
