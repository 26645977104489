import { useEffect, useState } from "react";
import {
    Box,
    Card,
    CardHeader,
    Divider,
    List,
    ListItem,
    ListItemText,
    TextField,
    useTheme,
    InputAdornment,
    Icon,
} from "@material-ui/core";

import { GetSaleDocument } from "../../utils/data";
import { isMobile } from "../../utils/helpers";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import SearchIcon from "@material-ui/icons/Search";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

const AttachmentList = (props) => {
    const [saleDocuments, setSaleDocuments] = useState([]);
    const [showedDocuments, setShowedDocuments] = useState([]);
    const [initalLoad, setInitialLoad] = useState(false);

    const [selectedDocument, setSelectedDocument] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [saleId, setSaleId] = useState(props.ffid);
    const [searchButtonClicked, setSearchButtonClicked] = useState(false);
    const theme = useTheme();
    useEffect(() => {
        async function QuerySaleDocuments() {
            var document = await GetSaleDocument(props.ffid);
            document.sort((document) => {
                return document.id.toLowerCase().includes("map") ? -1 : 0;
            });

            document.sort((document) => {
                return document.id.toLowerCase().includes("cruise") ? -1 : 0;
            });
            document.sort((document) => {
                return document.id.toLowerCase().includes("highlight") ? -1 : 0;
            });
            setShowedDocuments(document);
            setSaleDocuments(document);

            if (!initalLoad) {
                setInitialLoad(true);
                if (isMobile()) {
                    return; // We don't want to initiate loading a document without the user asking for it
                }
                setSelectedDocument(document[0]);

                props.onSelect(document[0]);
            }
        }

        QuerySaleDocuments();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let idx = showedDocuments.findIndex(
            (obj) => obj.id === props.currentAttachmentName
        );
        setSelectedDocument(idx);
    }, [props, props.currentAttachmentName, showedDocuments, saleDocuments]);

    function handleItemClick(e, i) {
        if (showedDocuments[i]) {
            props.onSelect(showedDocuments[i]);
        }
    }

    function handleSearchButtonClick() {
        setShowedDocuments(saleDocuments);
        setSearchButtonClicked(!searchButtonClicked);
    }

    function handleSearchChange(e) {
        if (e.target.value === "") {
            setShowedDocuments(saleDocuments);
        } else {
            var filteredResults = saleDocuments.filter((attachment) => {
                return attachment.id
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase());
            });

            setShowedDocuments(filteredResults);
        }
    }

    return (
        <Card {...props}>
            {searchButtonClicked ? (
                <TextField
                    autoFocus
                    fullWidth
                    onChange={(e) => handleSearchChange(e)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Icon
                                    edge="end"
                                    size="small"
                                    onClick={handleSearchButtonClick}
                                >
                                    <HighlightOffIcon />
                                </Icon>
                            </InputAdornment>
                        ),
                    }}
                    placeholder="Search Attachments"
                    variant="outlined"
                />
            ) : (
                <CardHeader
                    subtitle={`${saleDocuments.length} in total`}
                    title="Search Attachments"
                    onClick={handleSearchButtonClick}
                    avatar={
                        <div>
                            {" "}
                            <Icon edge="end" size="small">
                                <SearchIcon styles={{}} />
                            </Icon>
                        </div>
                    }
                />
            )}
            <Divider />
            <List>
                <div
                    style={{
                        overflow: "hidden",
                        overflowY: "scroll",
                        overflowX: "scroll",
                        height: isMobile() ? window.innerHeight * 0.75 : 250,
                    }}
                >
                    {showedDocuments.length > 0
                        ? showedDocuments.map((attachment, i) => (
                              <ListItem
                                  divider={i < showedDocuments.length - 1}
                                  key={i}
                                  id={i}
                                  onClick={(event) => handleItemClick(event, i)}
                                  // sx={{
                                  // 	backgroundColor:
                                  // 		selectedDocument == i
                                  // 			? theme.palette.accent.main
                                  // 			: theme.palette.white.main,
                                  // }}
                              >
                                  <Box
                                      sx={{
                                          display: "flex",
                                          justifyContent: "flex-end",
                                          p: 1,
                                      }}
                                  ></Box>
                                  <div id={i} key={i}>
                                      <Icon
                                          edge="end"
                                          size="medium"
                                          sx={{
                                              color:
                                                  selectedDocument === i
                                                      ? theme.palette.accent
                                                            .main
                                                      : theme.palette.primary
                                                            .main,
                                          }}
                                      >
                                          <PictureAsPdfIcon />
                                      </Icon>
                                  </div>
                                  <ListItemText
                                      primary={
                                          attachment ? attachment.id : "NA"
                                      }
                                      variant="body2"
                                  />
                              </ListItem>
                          ))
                        : null}
                </div>
            </List>
            <Divider />
        </Card>
    );
};

export default AttachmentList;
