import {
    Box,
    Card,
    CardContent,
    TextField,
    InputAdornment,
    SvgIcon,
    Grid,
    Skeleton,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { Search as SearchIcon } from "react-feather";
import RegionFilter from "./RegionFilter";
import SaleStateSwitch from "./SaleStateSwitch";

const TimberSaleListToolbar = (props) => {
    const [searchChange, setSearchChange] = useState("");
    useEffect(() => {
        props.handleSearchChange();
    }, [searchChange]);
    function handleSearchChange(event) {
        props.setSearchSaleFilter(event.target.value);
        setSearchChange(event.target.value);
    }
    return (
        <Box {...props}>
            <Box sx={{ mt: 3 }}>
                <Card>
                    <CardContent>
                        <Grid
                            container
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                md={4}
                                // alignItems="center"
                                sx={{ maxWidth: 300 }}
                                pl={1}
                            >
                                <TextField
                                    fullWidth
                                    onChange={handleSearchChange}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SvgIcon
                                                    fontSize="small"
                                                    color="action"
                                                >
                                                    <SearchIcon />
                                                </SvgIcon>
                                            </InputAdornment>
                                        ),
                                    }}
                                    placeholder="Search Sale Id or Location"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <RegionFilter
                                    handleSearchChange={
                                        props.handleSearchChange
                                    }
                                    setRegionFilter={props.setRegionFilter}
                                    saleRegions={props.saleRegions}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                md={4}
                                pl={{
                                    xs: 2,
                                    sm: 1,
                                    md: 5,
                                }}
                            >
                                <SaleStateSwitch
                                    handleSearchChange={
                                        props.handleSearchChange
                                    }
                                    setSaleStateFilter={
                                        props.setSaleStateFilter
                                    }
                                    isLoading={props.isLoading}
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Box>
        </Box>
    );
};

export default TimberSaleListToolbar;
