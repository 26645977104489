import React from "react";

import { Link as RouterLink } from "react-router-dom";
import { Typography, Grid, useTheme, Paper } from "@material-ui/core";

const ServicesSection = (props) => {
    const theme = useTheme();

    return (
        <Paper
            sx={{
                minHeight: 500,
            }}
        >
            <Grid
                container
                display="flex"
                flexDirection="row"
                spacing={5}
                justifyContent="flex-start"
                alignItems="flex-start"
                p={5}
            >
                <Grid item xs={12} md={2} lg={2} xl={2} />
                <Grid item xs={12} md={3} lg={3} xl={4}>
                    <Typography
                        title
                        variant="h1"
                        color={theme.palette.text.secondary}
                    >
                        {props.Title}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={7} lg={6} xl={6}>
                    {props.content.map((content, index) =>
                        content.link ? (
                            <Typography
                                display="block"
                                variant="h4"
                                pb={1}
                                pt={1}
                            >
                                <RouterLink
                                    style={{ color: theme.palette.accent.main }}
                                    to={content.link}
                                >
                                    {content.data}
                                </RouterLink>
                            </Typography>
                        ) : (
                            <Typography
                                display="block"
                                variant="h4"
                                color={theme.palette.text.secondary}
                                pb={1}
                            >
                                {content.data}
                            </Typography>
                        )
                    )}
                </Grid>
            </Grid>
        </Paper>
    );
};

export default ServicesSection;
