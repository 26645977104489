import { useState, useRef } from "react";

import {
    Box,
    Button,
    Card,
    Container,
    FormControl,
    Grid,
    InputAdornment,
    Skeleton,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TextField,
    Typography,
    useTheme,
} from "@material-ui/core";
import Scrollbar from "react-perfect-scrollbar";
import {
    AttachMoney,
    CheckCircle,
    Close,
    Cancel,
    NewReleases,
} from "@material-ui/icons";
import LogitIcon from "src/components/shared/LogitIcon";
import LogitCard from "src/components/timbersale/LogitCard";
import { yellow, green } from "@material-ui/core/colors";
import { useNavigate } from "react-router";
import { TableCell, Tooltip } from "@mui/material";
import { useEffect } from "react";

import { GetBidderHistoryFromCache } from "src/utils/cache";
import { formatCurrency } from "src/utils/helpers";
import LogitTableSkeleton from "../shared/LogitTableSkeleton";

const BidTab = (props) => {
    const navigate = useNavigate();
    const sale = props.sale;
    const [biddersBidHistory, setBiddersBidHistory] = useState({});
    const [bidderHistoryIsLoaded, setBidderHistoryIsLoaded] = useState(false);
    const [bidSpec, setBidSpec] = useState("");

    const [
        caclulatedTotalStumpagePerCubicM,
        setCalculatedTotalStumpagePerCubicM,
    ] = useState(sale.stumpage_rate_per_cubic_m);
    const [calculatedTotalStumpage, setCalculatedTotalStumpage] = useState(
        sale.stumpage_rate_per_cubic_m *
            (sale.net_volume ? sale.net_volume : sale.total_volume)
    );

    const [bonusBidTotalStumpage, setBonusBidTotalStumpage] = useState(0);
    const [bonusBidTotalStumpagePerCubicM, setbonusBidTotalStumpagePerCubicM] =
        useState(0);

    const [deposits, setDeposits] = useState({
        level1: 0,
        level2: 0,
        level3: 0,
    });

    const theme = useTheme();
    const xs = 12;
    const sm = 12;
    const lg = 4;
    const xl = 4;

    function setDepositFromStumpage(totalStumpage) {
        let baseDeposit = 0;
        if (totalStumpage <= 100000) {
            baseDeposit = totalStumpage * 0.1;
        } else {
            // 10 % of the first 100,000, plus 5% of the remainder
            baseDeposit = 100000 * 0.1 + (totalStumpage - 100000) * 0.05;
        }
        setDeposits({
            level1: baseDeposit,
            level2: baseDeposit * 2,
            level3: baseDeposit * 3,
        });
    }
    const handleChange = (event) => {
        const input = event.target.value;
        if (/^\d*\.?\d{0,2}$/.test(input)) {
            let vol = sale.net_volume ? sale.net_volume : sale.total_volume;
            setBidSpec(input);
            if (input === "" || input === "0" || isNaN(input)) {
                setCalculatedTotalStumpagePerCubicM(
                    sale.stumpage_rate_per_cubic_m
                );
                setBonusBidTotalStumpage(0);
                setbonusBidTotalStumpagePerCubicM(0);

                let totalCalcStumpage =
                    parseFloat(sale.stumpage_rate_per_cubic_m) *
                    parseFloat(vol);
                setCalculatedTotalStumpage(totalCalcStumpage);

                setDepositFromStumpage(totalCalcStumpage);

                return;
            }

            setBonusBidTotalStumpage(parseFloat(input) * parseFloat(vol));
            setbonusBidTotalStumpagePerCubicM(parseFloat(input));
            const totalStumpagePerM =
                parseFloat(input) + parseFloat(sale.stumpage_rate_per_cubic_m);

            setCalculatedTotalStumpagePerCubicM(totalStumpagePerM);

            const totalStumpage =
                parseFloat(vol) * parseFloat(totalStumpagePerM);
            setCalculatedTotalStumpage(totalStumpage);

            // Calculate the deposits
            setDepositFromStumpage(totalStumpage);
        }
    };

    useEffect(() => {
        async function GetSaleBiddersBidHistory() {
            let bidderHistoryForBiddersInThisSale = {};
            props.bids.forEach(async (bid) => {
                if (bid.client_number) {
                    let bidderHistory = await GetBidderHistoryFromCache(
                        bid.client_number
                    );
                    bidderHistoryForBiddersInThisSale[bid.client_number] = {
                        client_number: bid.client_number,
                        client_name: bid.client_name,
                        history: bidderHistory,
                    };
                }
            });
            setBiddersBidHistory(bidderHistoryForBiddersInThisSale);
            setBidderHistoryIsLoaded(true);
        }

        GetSaleBiddersBidHistory();
        setDepositFromStumpage(
            sale.stumpage_rate_per_cubic_m *
                (sale.net_volume ? sale.net_volume : sale.total_volume)
        );
    }, []);

    return (
        <Container maxWidth={false}>
            <Grid container spacing={3}>
                {!sale.winning_bid_finalized &&
                Date.parse(sale.closed_time) < Date.now() ? (
                    <Grid item xs={12}>
                        <LogitCard
                            Primary="Results Not Yet Final"
                            Icon={<NewReleases />}
                            IconBGColor={yellow[600]}
                        />
                    </Grid>
                ) : null}
                <Grid item lg={lg} sm={sm} xl={xl} xs={xs}>
                    {Date.parse(sale.closed_time) > Date.now() ? null : (
                        <LogitCard
                            Title="Winning Bid"
                            Primary={
                                sale.winning_bid_total
                                    ? sale.winning_bid_total === ""
                                        ? sale.bid_note
                                        : sale.winning_bid_per_cubic_m === 0
                                        ? "less than $0.01 m3"
                                        : `${formatCurrency(
                                              sale.winning_bid_per_cubic_m
                                          )} m3`
                                    : sale.bid_note
                            }
                            Icon={<LogitIcon />}
                            IconBGColor={theme.palette.primary.main}
                            Secondary={
                                sale.winning_bid_total
                                    ? sale.winning_bid_total === ""
                                        ? ""
                                        : `Total: ${formatCurrency(
                                              sale.winning_bid_total
                                          )}`
                                    : null
                            }
                        />
                    )}
                </Grid>
                {Date.parse(sale.closed_time) > Date.now() ? (
                    <Grid item xs={xs}>
                        <Card>
                            <Grid
                                container
                                flexDirection={"row"}
                                justifyContent={"center"}
                                alignItems={"center"}
                                alignContent={"center"}
                                p={2}
                                m={2}
                            >
                                <Grid item xs={12} m={2}>
                                    <Typography variant="h3">
                                        Get started with your bid
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} m={2}>
                                    <TextField
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    $
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    /m3
                                                </InputAdornment>
                                            ),
                                        }}
                                        label="Enter bonus bid"
                                        value={bidSpec}
                                        onChange={handleChange}
                                        inputProps={{ maxLength: 10 }}
                                        autoFocus
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h3">
                                        Stumpage
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} mt={2} mb={2}>
                                    <Typography>
                                        {sale.net_volume
                                            ? `Net Volume: ${sale.net_volume.toLocaleString(
                                                  "en-US"
                                              )}m3`
                                            : `Total Volume: ${sale.total_volume.toLocaleString(
                                                  "en-US"
                                              )}m3`}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Typography>Upset Stumpage:</Typography>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Typography
                                        sx={{
                                            fontFamily: "monospace",
                                            width: "20ch",
                                            textAlign: "right",
                                        }}
                                    >
                                        {formatCurrency(
                                            sale.stumpage_rate_per_cubic_m
                                        )}
                                        /m3
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={5}>
                                    <Typography
                                        sx={{
                                            fontFamily: "monospace",
                                            width: "25ch",
                                            textAlign: "right",
                                        }}
                                    >
                                        {formatCurrency(
                                            sale.stumpage_rate_per_cubic_m *
                                                (sale.net_volume
                                                    ? sale.net_volume
                                                    : sale.total_volume)
                                        )}
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={3}>
                                    <Typography>Bonus Bid:</Typography>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Typography
                                        sx={{
                                            fontFamily: "monospace",
                                            width: "20ch",
                                            textAlign: "right",
                                        }}
                                    >
                                        {formatCurrency(
                                            bonusBidTotalStumpagePerCubicM
                                        )}
                                        /m3
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={5}>
                                    <Typography
                                        sx={{
                                            fontFamily: "monospace",
                                            width: "25ch",
                                            textAlign: "right",
                                        }}
                                    >
                                        {formatCurrency(bonusBidTotalStumpage)}
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={3} mt={1}>
                                    <Typography>Total Stumpage:</Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={4}
                                    borderTop={"1px solid black"}
                                >
                                    <Typography
                                        sx={{
                                            fontFamily: "monospace",
                                            width: "20ch",
                                            textAlign: "right",
                                        }}
                                    >
                                        {formatCurrency(
                                            caclulatedTotalStumpagePerCubicM
                                        )}
                                        /m3
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={5}
                                    borderTop={"1px solid black"}
                                >
                                    <Typography
                                        sx={{
                                            fontFamily: "monospace",
                                            width: "25ch",
                                            textAlign: "right",
                                        }}
                                    >
                                        {formatCurrency(
                                            calculatedTotalStumpage
                                        )}
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} m={2}>
                                    <Typography variant="4">
                                        Approximate Deposits
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Typography>Level 1:</Typography>
                                </Grid>
                                <Grid item xs={12} sm={9}>
                                    <Typography
                                        sx={{
                                            fontFamily: "monospace",
                                            width: "25ch",
                                            textAlign: "right",
                                        }}
                                    >
                                        {formatCurrency(deposits.level1)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Typography>Level 2:</Typography>
                                </Grid>
                                <Grid item xs={12} sm={9}>
                                    <Typography
                                        sx={{
                                            fontFamily: "monospace",
                                            width: "25ch",
                                            textAlign: "right",
                                        }}
                                    >
                                        {formatCurrency(deposits.level2)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Typography>Level 3:</Typography>
                                </Grid>
                                <Grid item xs={12} sm={9}>
                                    <Typography
                                        sx={{
                                            fontFamily: "monospace",
                                            width: "25ch",
                                            textAlign: "right",
                                        }}
                                    >
                                        {formatCurrency(deposits.level3)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} mt={3} mb={-1}>
                                    <Typography
                                        fontSize={9}
                                        fontStyle={"italic"}
                                    >
                                        This calculator is provided for your
                                        convenience only. It is not intended to
                                        be a substitute for professional advice.
                                        The results are based on information
                                        posted on bcbid (not information from
                                        the cruise documents) and may not be
                                        accurate. Please consult a professional
                                        for advice specific to your situation.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                ) : (
                    <Grid item lg={lg} sm={sm} xl={xl} xs={xs}>
                        <LogitCard
                            Title="Winning Bidder"
                            Primary={
                                sale.winning_client_name
                                    ? sale.winning_client_name === ""
                                        ? sale.bid_note
                                        : sale.winning_client_name.replace(
                                              "amp;",
                                              ""
                                          )
                                    : sale.bid_note
                            }
                            Icon={<LogitIcon />}
                            IconBGColor={theme.palette.secondary.main}
                        />
                    </Grid>
                )}

                <Grid item lg={lg} sm={sm} xl={xl} xs={xs}>
                    {Date.parse(sale.closed_time) > Date.now() ? null : (
                        <LogitCard
                            Title="Total Stumpage"
                            Primary={
                                sale.winning_bid_total
                                    ? sale.winning_bid_total === ""
                                        ? sale.bid_note
                                        : sale.winning_bid_per_cubic_m === 0
                                        ? sale.stumpage_rate_per_cubic_m
                                        : `${formatCurrency(
                                              parseFloat(
                                                  sale.stumpage_rate_per_cubic_m
                                              ) +
                                                  parseFloat(
                                                      sale.winning_bid_per_cubic_m
                                                  )
                                          )} m3`
                                    : sale.bid_note
                            }
                            Secondary={
                                sale.winning_bid_total
                                    ? sale.winning_bid_total === ""
                                        ? ""
                                        : `Total: ${formatCurrency(
                                              parseFloat(
                                                  sale.stumpage_rate_total
                                              ) +
                                                  parseFloat(
                                                      sale.winning_bid_total
                                                  )
                                          )}`
                                    : null
                            }
                            Icon={<AttachMoney />}
                            IconBGColor={green[600]}
                        />
                    )}
                </Grid>

                <Grid item xs={12}>
                    <Card>
                        <Scrollbar>
                            <Box>
                                {bidderHistoryIsLoaded &&
                                props.bids.length > 0 ? (
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                    <Typography variant="h6">
                                                        Client Name
                                                    </Typography>
                                                </TableCell>

                                                <TableCell>
                                                    <Typography variant="h6">
                                                        Bid (m3)
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant="h6">
                                                        Bid Total
                                                    </Typography>
                                                </TableCell>

                                                <TableCell>
                                                    Total Stumpage (m3)
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant="h6">
                                                        Winner
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant="h6">
                                                        Eligble
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {bidderHistoryIsLoaded ? (
                                                props.bids.map((bid, index) => (
                                                    <TableRow
                                                        hover
                                                        key={index}
                                                        onClick={(e) => {
                                                            navigate(
                                                                `/app/bidder/${bid.client_number}`,
                                                                {
                                                                    state: bid.client_number,
                                                                }
                                                            );
                                                        }}
                                                        id={index}
                                                    >
                                                        <TableCell id={index}>
                                                            <Typography>
                                                                {bid.client_name.replace(
                                                                    "amp;",
                                                                    ""
                                                                )}
                                                            </Typography>
                                                        </TableCell>

                                                        <TableCell id={index}>
                                                            <Typography>
                                                                {formatCurrency(
                                                                    bid.bid_per_cubic_m
                                                                )}
                                                            </Typography>{" "}
                                                            {bid.difference ===
                                                                0 ||
                                                            index ===
                                                                0 ? null : (
                                                                <>
                                                                    <Tooltip
                                                                        title={
                                                                            "The difference between the winning bid and this bid"
                                                                        }
                                                                    >
                                                                        <Typography color="red">
                                                                            (
                                                                            {formatCurrency(
                                                                                bid.difference
                                                                            )}
                                                                            )
                                                                        </Typography>
                                                                    </Tooltip>
                                                                </>
                                                            )}
                                                        </TableCell>
                                                        <TableCell
                                                            id={index}
                                                            show={{
                                                                xs: false,
                                                                md: true,
                                                            }}
                                                        >
                                                            <Typography>
                                                                {formatCurrency(
                                                                    bid.bid_total
                                                                )}
                                                            </Typography>
                                                        </TableCell>

                                                        <TableCell id={index}>
                                                            <Typography>
                                                                {formatCurrency(
                                                                    Number.parseFloat(
                                                                        bid.bid_per_cubic_m
                                                                    ) +
                                                                        Number.parseFloat(
                                                                            sale.stumpage_rate_per_cubic_m
                                                                        )
                                                                )}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell id={index}>
                                                            {bid.winner ? (
                                                                <CheckCircle
                                                                    sx={{
                                                                        color: theme
                                                                            .palette
                                                                            .accent
                                                                            .main,
                                                                    }}
                                                                />
                                                            ) : !bid.winning_bid_finalized &&
                                                              !bid.winning_bid_total &&
                                                              index === 0 ? (
                                                                <Tooltip title="The bid results are not yet final">
                                                                    <CheckCircle
                                                                        sx={{
                                                                            color: yellow[600],
                                                                        }}
                                                                    />
                                                                </Tooltip>
                                                            ) : (
                                                                <Cancel
                                                                    sx={{
                                                                        color: theme
                                                                            .palette
                                                                            .closing
                                                                            .main,
                                                                    }}
                                                                />
                                                            )}
                                                        </TableCell>
                                                        <TableCell id={index}>
                                                            {bid.eligible ? (
                                                                <CheckCircle
                                                                    sx={{
                                                                        color: theme
                                                                            .palette
                                                                            .primary
                                                                            .main,
                                                                    }}
                                                                />
                                                            ) : (
                                                                <Cancel
                                                                    sx={{
                                                                        color: theme
                                                                            .palette
                                                                            .closing
                                                                            .main,
                                                                    }}
                                                                />
                                                            )}
                                                        </TableCell>
                                                    </TableRow>
                                                ))
                                            ) : (
                                                <LogitTableSkeleton
                                                    rowCount={5}
                                                    cellCount={5}
                                                />
                                            )}
                                        </TableBody>
                                    </Table>
                                ) : null}
                            </Box>
                        </Scrollbar>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    );
};

export default BidTab;
