import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Box, Button, Container, Typography } from "@material-ui/core";
import { getAuth } from "firebase/auth";
import LogitHelmet from "src/components/shared/LogitHelmet";

const Verification = () => {
    const navigate = useNavigate();
    // const location = useLocation();
    // eslint-disable-next-line no-unused-vars
    const [showVerifyModal, setShowVerifyModal] = useState(false);

    useEffect((navigate) => {
        var currentUser = getAuth().currentUser;
        if (!currentUser) {
            // navigate('/signin', { replace: true, state:{...location.state} });
            navigate("/signin", { replace: true });
        }
    }, []);

    function handleClick(i) {
        if (i === 0) {
            var currentUser = getAuth().currentUser;
            currentUser.reload();
            // eslint-disable-next-line no-unused-vars
            var error = currentUser
                ? currentUser.emailVerified
                    ? navigate("/app/opensales")
                    : setShowVerifyModal(true)
                : null;
        } else {
            navigate("/signin");
        }
    }

    return (
        <>
            <LogitHelmet pageName={"Verify"} />
            <Box
                sx={{
                    backgroundColor: "background.default",
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    justifyContent: "center",
                }}
            >
                <Container maxWidth="sm">
                    <Box sx={{ mb: 3 }}>
                        <Typography color="textPrimary" variant="h2">
                            Verify your email
                        </Typography>
                        <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="body2"
                        >
                            Verification email has been sent to your email,
                            please verify your email to continue.
                        </Typography>
                        <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="body2"
                        >
                            You will be automatically redirected once you verify
                            your email
                        </Typography>
                    </Box>
                    <Box sx={{ py: 2 }}>
                        <Button
                            color="secondary"
                            fullWidth
                            size="large"
                            variant="contained"
                            onClick={(e) => handleClick(1)}
                        >
                            Go to Sign In Page
                        </Button>
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default Verification;
