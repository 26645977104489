import {
    Avatar,
    Card,
    Chip,
    CardContent,
    Grid,
    Typography,
    useTheme,
    Zoom,
} from "@material-ui/core";
import { Skeleton } from "@mui/material";

const LogitCard = (props) => {
    const theme = useTheme();

    return (
        // <Zoom in={true}>
        <Card sx={{ height: "100%", backgroundColor: "accent" }} {...props}>
            <CardContent>
                <Grid
                    container
                    spacing={3}
                    sx={{
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    {props.Icon ? (
                        <Grid item xs={4}>
                            <Avatar
                                sx={{
                                    backgroundColor: props.IconBGColor
                                        ? props.IconBGColor
                                        : theme.palette.accent.main,
                                    height: 40,
                                    width: 40,
                                }}
                            >
                                {props.Icon}
                            </Avatar>
                        </Grid>
                    ) : null}
                    <Grid item xs={8}>
                        <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="h6"
                        >
                            {props.Title}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {props.Primary !== undefined ? (
                            <Typography color="textPrimary" variant="h4" pb={1}>
                                {props.Primary}
                            </Typography>
                        ) : (
                            <Skeleton />
                        )}
                    </Grid>
                    {props.Secondary ? (
                        <Grid item xs={12}>
                            {props.SecondaryIcon ? props.SecondaryIcon : null}
                            <Typography
                                sx={{
                                    mr: 1,
                                }}
                                variant="body2"
                            >
                                {props.Secondary}
                            </Typography>
                        </Grid>
                    ) : null}
                    {props.Chip ? (
                        <Grid item xs={12}>
                            {props.Chip ? (
                                <Chip
                                    color="accent"
                                    label={props.Chip}
                                    variant="outlined"
                                ></Chip>
                            ) : null}
                        </Grid>
                    ) : null}
                </Grid>
            </CardContent>
        </Card>
        // </Zoom>
    );
};

export default LogitCard;
