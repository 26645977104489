import React from "react";
import ServicesSection from "src/components/services/ServicesSection";
const AboutContent = () => {
    let content = [
        {
            data: "The ideas that formed Logit Analytics date back to 2018.",
        },
        // { data: "We speak app." },
        // { data: "Get in touch", link: "/home" },
    ];
    return <ServicesSection Title="Humble Beginnings" content={content} />;
};

export default AboutContent;
