import { CheckCircle } from "@material-ui/icons";
import {
    List,
    Typography,
    ListItemText,
    ListItem,
    ListItemIcon,
    useTheme,
} from "@mui/material";
import SignUpButton from "../shared/SignUpButton";

const ProductFeatureBreakdown = () => {
    const theme = useTheme();

    const productFeatureMap = [
        {
            title: "For Timber Buyers",
            features: [
                "All the data you need in one platform",
                "Bid Deposit Calculator",
                "Future Timber Sale Schedule",
                "All sale documents, one click away (No need to download!)",
                "Interactive Maps",
                "Competition bidding history and operating area",
            ],
        },
        {
            title: "For Managers",
            features: [
                "License Term",
                "Tracking active licenses per licensee",
                "One-click documents, no more BC Bid",
                "Links to HBS for daily shipments",
            ],
        },
        // {
        //     title: "Coming Soon",
        //     features: [
        //         "Harvest Billing System data: Making it possible to identify where timber is going before it's shipped",
        //         "Waste assessment summaries",
        //         "Ability to create private notes per sale",
        //         "Roll-up analysis of per region tenders",
        //         "Active road permits per licensee",
        //     ],
        // },
    ];

    return (
        <>
            {productFeatureMap.map((productFeature) => {
                return (
                    <>
                        <Typography
                            variant="h5"
                            p={1}
                            color={theme.palette.secondary.main}
                        >
                            {productFeature.title}
                        </Typography>
                        <List>
                            {productFeature.features.map((feature) => {
                                return (
                                    <ListItem>
                                        <ListItemIcon>
                                            <CheckCircle
                                                sx={{
                                                    color: theme.palette.primary
                                                        .main,
                                                }}
                                            />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={feature}
                                            primaryTypographyProps={{
                                                variant: "body1",
                                            }}
                                        />
                                    </ListItem>
                                );
                            })}
                        </List>
                        <SignUpButton />
                    </>
                );
            })}
        </>
    );
};

export default ProductFeatureBreakdown;
