import { Navigate } from "react-router-dom";
import DashboardLayout from "src/components/DashboardLayout";
import Account from "src/pages/Account";
import Sale from "src/pages/Sale";
import SaleList from "./pages/SaleList";
import Loads from "./pages/Loads";
import Bidder from "./pages/Bidder";
import Bidders from "./pages/Bidders";
import FutureSales from "./pages/FutureSales";
import TodayDashboard from "./pages/TodayDashboard";
const privateRoutes = [
    {
        path: "app",
        element: <DashboardLayout />,
        children: [
            { path: "account", element: <Account /> },
            // { path: "timberToday", element: <TodayDashboard /> },
            { path: "sale/:saleId", element: <Sale /> },
            { path: "sale", element: <Sale /> },
            { path: "futureSales", element: <FutureSales /> },
            { path: "bidder", element: <Bidder /> },
            { path: "bidder/:bidderId", element: <Bidder /> },
            { path: "loads", element: <Loads /> },
            { path: "sales", element: <SaleList /> },
            { path: "bidders", element: <Bidders /> },
            { path: "*", element: <Navigate to="/404" /> },
        ],
    },
];

export default privateRoutes;
