import React, { useState } from "react";

import {
    Box,
    Button,
    Card,
    CardHeader,
    CircularProgress,
    Divider,
} from "@material-ui/core";

import { getAuth } from "firebase/auth";

import { httpsCallable } from "firebase/functions";

import { STRIPE_PAYMENT_PORTAL_LINK } from "src/Constants";
import { IsOnFreeTrial } from "src/utils/auth";
import { functions } from "src/Firebase";

const Subscription = () => {
    const [loading, setLoading] = useState(false);

    async function paymentPortal() {
        setLoading(true);

        if (await IsOnFreeTrial(getAuth().currentUser)) {
            alert(
                "You are currently on a free trial. When your trial expires, you will be redirected to the payment portal."
            );
            setLoading(false);
            return;
        }

        const paymentPortal = httpsCallable(
            functions,
            STRIPE_PAYMENT_PORTAL_LINK
        );

        paymentPortal({
            returnUrl: window.location.href,
            // locale: "auto",
            // configuration: "bpc_1JSEAKHYgolSBA358VNoc2Hs", // Optional ID of a portal configuration: https://stripe.com/docs/api/customer_portal/configuration
        })
            .then((result) => {
                window.location.assign(result.data.url);
            })
            .catch((error) => {
                setLoading(false);
            });
    }

    return (
        <>
            <Card>
                <CardHeader subheader="" title="Billing" />
                <Divider />
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        p: 2,
                    }}
                >
                    <Box sx={{ py: 2 }}>
                        <Button
                            color="accent"
                            disabled={loading}
                            fullWidth
                            size="large"
                            variant="outlined"
                            onClick={() => paymentPortal()}
                        >
                            {loading && <CircularProgress size={14} />}
                            {!loading && "Access Payment Portal"}
                        </Button>
                    </Box>
                </Box>
            </Card>
        </>
    );
};

export default Subscription;
