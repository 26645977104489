import {
    Box,
    Card,
    CardContent,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    IconButton,
    Collapse,
    Tab,
    Button,
    CircularProgress, // Import CircularProgress
} from "@material-ui/core";
import { Fragment, useEffect, useState } from "react";
import { ExpandMore, ExpandLess } from "@material-ui/icons";
import LogitHelmet from "src/components/shared/LogitHelmet";
import MapNew from "src/components/timbersale/MapNew";
import { GetSalesFromCacheByIds } from "src/utils/cache";
import {
    GetAllSales,
    GetFutureSales,
    GetGeojsonCutblocksForFutrueSales,
} from "src/utils/data";
import { formatNumber } from "src/utils/helpers";
import Map from "src/components/timbersale/Map";
import { set } from "lodash";
import { useNavigate } from "react-router";

const FutureSales = (props) => {
    const navigate = useNavigate();
    const [futureSalesRegions, setFutureSalesRegions] = useState([]);
    const [prettyQuarters, setPrettyQuarters] = useState({});
    const [sales, setSales] = useState([]);
    const [expandedQuarters, setExpandedQuarters] = useState({});
    const [expandedRows, setExpandedRows] = useState({});
    const [activeRegion, setActiveRegion] = useState(null);
    const [geo, setGeo] = useState();
    const [theseSaleIds, setTheseSaleIds] = useState([]);
    const [aggregatedSalesByQuarter, setAggregatedSalesByQuarter] = useState(
        []
    );
    const [loading, setLoading] = useState(false); // State to manage loading

    const handleQuarterClick = (quarter) => {
        setExpandedQuarters((prev) => ({
            ...prev,
            [quarter]: !prev[quarter],
        }));
    };

    const handleRowClick = (licenceId) => {
        setExpandedRows((prev) => ({
            ...prev,
            [licenceId]: !prev[licenceId],
        }));
    };

    useEffect(() => {
        async function GetFutureSalesLocal() {
            setLoading(true); // Start loading
            let fSales = await GetGeojsonCutblocksForFutrueSales(
                activeRegion.toUpperCase()
            );
            setGeo(fSales);
            setLoading(false); // Stop loading
        }
        if (activeRegion) {
            GetFutureSalesLocal();
        }
    }, [activeRegion]);

    useEffect(() => {
        async function aggregateBlocksToSales() {
            if (!geo) {
                return;
            }
            const aggregated = {};
            const saleIds = [];
            geo.features.forEach((item) => {
                const fiscalQuarter = `${item.properties.AUCTION_FISCAL}${item.properties.AUCTION_QUARTER}`;
                setPrettyQuarters((prev) => ({
                    ...prev,
                    [fiscalQuarter]: `${item.properties.AUC_QUART_MONTH}`,
                }));
                const licenceId = item.properties.LICENCE_ID;
                const saleIsListed = sales.find(
                    (sale) => sale.ffid === licenceId
                );
                if (saleIsListed) {
                    item.properties.SALE_IS_LISTED = true;
                    item.properties.SALE_ID = saleIsListed.ffid;
                    item.properties.SALE_ID +=
                        saleIsListed.oppid === undefined
                            ? ""
                            : saleIsListed.oppid;
                } else {
                    item.properties.SALE_IS_LISTED = false;
                }

                if (!aggregated[fiscalQuarter]) {
                    aggregated[fiscalQuarter] = {};
                }
                if (!aggregated[fiscalQuarter][licenceId]) {
                    aggregated[fiscalQuarter][licenceId] = [];
                }
                aggregated[fiscalQuarter][licenceId].push(item);
            });
            setTheseSaleIds(new Set(saleIds));
            // sort aggregated by fiscalQuarter
            let x = Object.keys(aggregated).sort((a, b) => {
                return a - b;
            });
            console.log(aggregated);
            setAggregatedSalesByQuarter(aggregated);
        }
        aggregateBlocksToSales();
    }, [geo]);

    useEffect(() => {}, [theseSaleIds]);

    useEffect(() => {
        async function GetFutureSalesLocal() {
            setLoading(true); // Start loading
            let futureSales = await GetFutureSales();
            futureSales.sort((a, b) => {
                return a.name - b.name;
            });
            setFutureSalesRegions(futureSales);
            setLoading(false); // Stop loading
        }
        async function getSales() {
            setSales(await GetAllSales());
        }
        getSales();
        GetFutureSalesLocal();
    }, []);

    const calculateAveragePieceSize = (blocks) => {
        let totalVolume = 0;
        let totalPieceSize = 0;

        blocks.forEach((block) => {
            const volume = block.properties.BLOCK_VOLUME;
            const pieceSize = block.properties.PIECE_SIZE;

            totalVolume += volume;
            totalPieceSize += volume * pieceSize;
        });

        return totalPieceSize / totalVolume;
    };

    return (
        <>
            <LogitHelmet pageName={`Future Sales`} />
            <Grid container p={2} spacing={2} m={2}>
                <FormControl
                    sx={{
                        m: 1,
                        width: "100%",
                        height: "auto",
                        maxWidth: "350px",
                    }}
                >
                    <InputLabel id="region-select-label">Region</InputLabel>
                    <Select
                        labelId="region-select-label"
                        id="region-select"
                        value={activeRegion}
                        input={<OutlinedInput label="Region" />}
                        onChange={(event) =>
                            setActiveRegion(event.target.value)
                        }
                    >
                        {futureSalesRegions.map((region) => (
                            <MenuItem key={region.id} value={region.id}>
                                {region.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center">
                    <CircularProgress /> {/* Show progress while loading */}
                </Box>
            ) : (
                <>
                    {geo && activeRegion && (
                        <MapNew
                            geo={geo}
                            geoTextMapLayer={{
                                "text-field": [
                                    "concat",
                                    ["get", "LICENCE_ID"],
                                    "\nListing Date: ",
                                    ["get", "AUC_QUART_MONTH"],
                                    "\nVolume: ",
                                    ["get", "BLOCK_VOLUME"],
                                    "\nPiece Size: ",
                                    ["get", "PIECE_SIZE"],
                                    "\nSpecies: ",
                                    ["get", "SPECIES_COMPOSITION"],
                                ],
                                "text-font": ["Open Sans Regular"],
                                "text-size": 16,
                                "text-anchor": "left",
                                "text-offset": [1, 0],
                                "symbol-avoid-edges": true,
                            }}
                        />
                    )}
                    <Grid
                        container
                        p={2}
                        spacing={2}
                        justifyContent={"space-around"}
                    >
                        {Object.keys(aggregatedSalesByQuarter)
                            .sort()
                            .map((quarter) => (
                                <Grid item xs={12} key={quarter}>
                                    <Card sx={{ minWidth: 275 }}>
                                        <CardContent>
                                            <Typography
                                                variant="h4"
                                                color="text.secondary"
                                                gutterBottom
                                                onClick={() =>
                                                    handleQuarterClick(quarter)
                                                }
                                                style={{ cursor: "pointer" }}
                                            >
                                                {prettyQuarters[quarter]}
                                                <IconButton size="small">
                                                    {expandedQuarters[
                                                        quarter
                                                    ] ? (
                                                        <ExpandLess />
                                                    ) : (
                                                        <ExpandMore />
                                                    )}
                                                </IconButton>
                                            </Typography>
                                            <Collapse
                                                in={expandedQuarters[quarter]}
                                                timeout="auto"
                                                unmountOnExit
                                            >
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>
                                                                Licence ID
                                                            </TableCell>
                                                            <TableCell>
                                                                Volume
                                                            </TableCell>
                                                            <TableCell>
                                                                Piece Size
                                                            </TableCell>
                                                            <TableCell>
                                                                Location
                                                            </TableCell>
                                                            <TableCell>
                                                                Listing
                                                            </TableCell>
                                                            <TableCell>
                                                                Expand
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {Object.keys(
                                                            aggregatedSalesByQuarter[
                                                                quarter
                                                            ]
                                                        ).map((licenceId) => (
                                                            <Fragment
                                                                key={licenceId}
                                                            >
                                                                <TableRow
                                                                    onClick={() =>
                                                                        handleRowClick(
                                                                            licenceId
                                                                        )
                                                                    }
                                                                >
                                                                    <TableCell>
                                                                        {
                                                                            licenceId
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {formatNumber(
                                                                            Math.round(
                                                                                aggregatedSalesByQuarter[
                                                                                    quarter
                                                                                ][
                                                                                    licenceId
                                                                                ].reduce(
                                                                                    (
                                                                                        totalVolume,
                                                                                        feature
                                                                                    ) =>
                                                                                        totalVolume +
                                                                                        feature
                                                                                            .properties
                                                                                            .BLOCK_VOLUME,
                                                                                    0
                                                                                )
                                                                            )
                                                                        )}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {formatNumber(
                                                                            calculateAveragePieceSize(
                                                                                aggregatedSalesByQuarter[
                                                                                    quarter
                                                                                ][
                                                                                    licenceId
                                                                                ]
                                                                            )
                                                                        )}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {
                                                                            aggregatedSalesByQuarter[
                                                                                quarter
                                                                            ][
                                                                                licenceId
                                                                            ][0]
                                                                                .properties
                                                                                .GEOGRAPHIC_LOCATION
                                                                        }
                                                                    </TableCell>

                                                                    <TableCell>
                                                                        {aggregatedSalesByQuarter[
                                                                            quarter
                                                                        ][
                                                                            licenceId
                                                                        ][0]
                                                                            .properties
                                                                            .SALE_IS_LISTED ? (
                                                                            <Button
                                                                                onClick={() => {
                                                                                    navigate(
                                                                                        "/app/sale/" +
                                                                                            aggregatedSalesByQuarter[
                                                                                                quarter
                                                                                            ][
                                                                                                licenceId
                                                                                            ][0]
                                                                                                .properties
                                                                                                .SALE_ID
                                                                                    );
                                                                                }}
                                                                            >
                                                                                View
                                                                                Sale
                                                                            </Button>
                                                                        ) : null}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <IconButton
                                                                            size="small"
                                                                            onClick={() =>
                                                                                handleRowClick(
                                                                                    licenceId
                                                                                )
                                                                            }
                                                                        >
                                                                            {expandedRows[
                                                                                licenceId
                                                                            ] ? (
                                                                                <ExpandLess />
                                                                            ) : (
                                                                                <ExpandMore />
                                                                            )}
                                                                        </IconButton>
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell
                                                                        style={{
                                                                            paddingBottom: 0,
                                                                            paddingTop: 0,
                                                                        }}
                                                                        colSpan={
                                                                            6
                                                                        }
                                                                    >
                                                                        <Collapse
                                                                            in={
                                                                                expandedRows[
                                                                                    licenceId
                                                                                ]
                                                                            }
                                                                            timeout="auto"
                                                                            unmountOnExit
                                                                        >
                                                                            <Box
                                                                                margin={
                                                                                    1
                                                                                }
                                                                            >
                                                                                <Typography
                                                                                    variant="h6"
                                                                                    gutterBottom
                                                                                    component="div"
                                                                                >
                                                                                    Block
                                                                                    Details
                                                                                </Typography>
                                                                                <Table
                                                                                    size="small"
                                                                                    aria-label="block details"
                                                                                >
                                                                                    <TableHead>
                                                                                        <TableRow>
                                                                                            <TableCell>
                                                                                                Block
                                                                                            </TableCell>
                                                                                            <TableCell>
                                                                                                Volume
                                                                                            </TableCell>
                                                                                            <TableCell>
                                                                                                Area
                                                                                            </TableCell>
                                                                                            <TableCell>
                                                                                                m3/ha
                                                                                            </TableCell>
                                                                                            <TableCell>
                                                                                                Piece
                                                                                                Size
                                                                                            </TableCell>

                                                                                            <TableCell>
                                                                                                Species
                                                                                            </TableCell>
                                                                                            <TableCell>
                                                                                                Silviculture
                                                                                            </TableCell>
                                                                                        </TableRow>
                                                                                    </TableHead>
                                                                                    <TableBody>
                                                                                        {aggregatedSalesByQuarter[
                                                                                            quarter
                                                                                        ][
                                                                                            licenceId
                                                                                        ]
                                                                                            .sort(
                                                                                                (
                                                                                                    a,
                                                                                                    b
                                                                                                ) =>
                                                                                                    b
                                                                                                        .properties
                                                                                                        .BLOCK_VOLUME -
                                                                                                    a
                                                                                                        .properties
                                                                                                        .BLOCK_VOLUME
                                                                                            )
                                                                                            .map(
                                                                                                (
                                                                                                    feature,
                                                                                                    index
                                                                                                ) => (
                                                                                                    <TableRow
                                                                                                        key={
                                                                                                            index
                                                                                                        }
                                                                                                    >
                                                                                                        <TableCell>
                                                                                                            {
                                                                                                                feature
                                                                                                                    .properties
                                                                                                                    .BLOCK_ID
                                                                                                            }
                                                                                                        </TableCell>
                                                                                                        <TableCell>
                                                                                                            {formatNumber(
                                                                                                                Math.round(
                                                                                                                    feature
                                                                                                                        .properties
                                                                                                                        .BLOCK_VOLUME
                                                                                                                )
                                                                                                            )}
                                                                                                        </TableCell>
                                                                                                        <TableCell>
                                                                                                            {formatNumber(
                                                                                                                Math.round(
                                                                                                                    feature
                                                                                                                        .properties
                                                                                                                        .FEATURE_AREA_SQM /
                                                                                                                        10000,
                                                                                                                    1
                                                                                                                )
                                                                                                            )}
                                                                                                        </TableCell>
                                                                                                        <TableCell>
                                                                                                            {formatNumber(
                                                                                                                Math.round(
                                                                                                                    feature
                                                                                                                        .properties
                                                                                                                        .BLOCK_VOLUME /
                                                                                                                        (feature
                                                                                                                            .properties
                                                                                                                            .FEATURE_AREA_SQM /
                                                                                                                            10000)
                                                                                                                )
                                                                                                            )}
                                                                                                        </TableCell>

                                                                                                        <TableCell>
                                                                                                            {formatNumber(
                                                                                                                feature
                                                                                                                    .properties
                                                                                                                    .PIECE_SIZE
                                                                                                            )}
                                                                                                        </TableCell>

                                                                                                        <TableCell>
                                                                                                            {
                                                                                                                feature
                                                                                                                    .properties
                                                                                                                    .SPECIES_COMPOSITION
                                                                                                            }
                                                                                                        </TableCell>
                                                                                                        <TableCell>
                                                                                                            {
                                                                                                                feature
                                                                                                                    .properties
                                                                                                                    .SILVICULTURE_SYSTEM
                                                                                                            }
                                                                                                        </TableCell>
                                                                                                    </TableRow>
                                                                                                )
                                                                                            )}
                                                                                    </TableBody>
                                                                                </Table>
                                                                            </Box>
                                                                        </Collapse>
                                                                    </TableCell>
                                                                </TableRow>
                                                            </Fragment>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </Collapse>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                    </Grid>
                </>
            )}
        </>
    );
};

export default FutureSales;
