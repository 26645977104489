import PropTypes from "prop-types";
import { useState } from "react";
import {
    Box,
    Button,
    Card,
    CardContent,
    CardMedia,
    CardHeader,
    Divider,
    Grid,
    Typography,
    useTheme,
} from "@material-ui/core";

const ProductCard = (props) => {
    const [selectedPriceId, setSelectedPriceId] = useState(null);
    const theme = useTheme();
    function handleSelect(priceId) {
        props.handleSelect(priceId);
        setSelectedPriceId(priceId);
    }

    return (
        <Card
            sx={{
                display: "flex",
                flexDirection: "column",
                boxShadow: 10,
            }}
            {...props}
        >
            <CardHeader
                subheader={props.product.description}
                title={props.product.name}
            />
            <CardMedia image="../public/static/images/assets/LogitTree.png/" />
            <Divider />
            <CardContent display="flex" flexDirection="column" align="center">
                <Box sx={{ flexGrow: 10 }} />
                <Box sx={{ p: 5, m: 1 }} display="flex">
                    <Grid
                        container
                        spacing={3}
                        sx={{ justifyContent: "space-around" }}
                    >
                        {props.prices.map((price, idx) => {
                            return (
                                <Button
                                    onClick={() => {
                                        handleSelect(price.id);
                                    }}
                                    color={
                                        price.id === selectedPriceId
                                            ? "accent"
                                            : "white"
                                    }
                                    sx={{
                                        p: 3,
                                        m: 1,
                                        boxShadow: 5,
                                        justifyContent: "center",
                                    }}
                                    display="flex"
                                    variant="contained"
                                >
                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                        justifyContent="space-between"
                                    >
                                        <Typography
                                            color={
                                                price.id === selectedPriceId
                                                    ? "white"
                                                    : "primary"
                                            }
                                            variant="h4"
                                        >
                                            $
                                            {(price.unit_amount / 100).toFixed(
                                                2
                                            )}{" "}
                                            every{" "}
                                            {price.recurring.interval_count ===
                                            1
                                                ? price.recurring.interval
                                                : price.recurring
                                                      .interval_count +
                                                  " " +
                                                  price.recurring.interval +
                                                  "s"}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                color:
                                                    price.id === selectedPriceId
                                                        ? theme.palette.white
                                                              .main
                                                        : theme.palette.accent
                                                              .main,
                                            }}
                                            variant="body2"
                                        >
                                            {price.description
                                                ? price.description
                                                : ""}
                                        </Typography>
                                    </Box>
                                </Button>
                            );
                        })}
                    </Grid>
                </Box>
            </CardContent>
        </Card>
    );
};

ProductCard.propTypes = {
    product: PropTypes.object.isRequired,
};

export default ProductCard;
