import { useState } from "react";
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    TextField,
    Alert,
    Snackbar,
    InputAdornment,
    IconButton,
} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import EditIcon from "@material-ui/icons/Edit";
import { UpdateUser } from "src/utils/data";
import PhoneInput from "react-phone-input-2";
import CancelIcon from "@material-ui/icons/Cancel";
import { getAuth } from "firebase/auth";
import "react-phone-input-2/lib/material.css";

const AccountProfileDetails = (props) => {
    const [user, setUser] = useState(props.user);
    const [updateSuccess, setUpdateSuccess] = useState(false);
    const [isEditing, setIsEditing] = useState(false);

    const handleChange = (event) => {
        setUser({
            ...user,
            [event.target.name]: event.target.value,
        });
    };

    const handleClose = () => {
        setUpdateSuccess(false);
    };

    const handleSubmit = async () => {
        console.debug("Updating user ", user);
        var response = await UpdateUser(user);
        if (response) {
            setUpdateSuccess(true);
            setIsEditing(false);
        }
    };

    return (
        <>
            <Snackbar
                open={updateSuccess}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity="success">
                    Successfully updated your information
                </Alert>
            </Snackbar>
            <form
                autoComplete="off"
                noValidate
                onSubmit={(event) => {
                    event.preventDefault();
                    handleSubmit();
                }}
                {...props}
            >
                <Card>
                    <CardHeader
                        subheader=""
                        title="Profile"
                        action={
                            <IconButton
                                aria-label="settings"
                                onClick={() => setIsEditing(!isEditing)}
                            >
                                {isEditing ? <CancelIcon /> : <EditIcon />}
                            </IconButton>
                        }
                    />
                    <Divider />
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item lg={6} xs={12}>
                                <TextField
                                    fullWidth
                                    name="name"
                                    label="Name"
                                    onChange={handleChange}
                                    disabled={!isEditing}
                                    required
                                    value={user.name}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item lg={6} xs={12}>
                                <TextField
                                    fullWidth
                                    name="organization"
                                    label="Organization"
                                    disabled={!isEditing}
                                    onChange={handleChange}
                                    required
                                    value={user.organization}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item lg={6} xs={12}>
                                <TextField
                                    fullWidth
                                    label="Email Address"
                                    name="email"
                                    disabled={true}
                                    onChange={handleChange}
                                    required
                                    value={user.email}
                                    variant="outlined"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {getAuth().currentUser
                                                    .emailVerified ? (
                                                    <CheckCircleIcon />
                                                ) : null}
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item lg={6} xs={12}>
                                <PhoneInput
                                    country={"ca"}
                                    value={user.phone_number}
                                    disabled={!isEditing}
                                    disableCountryCode={!isEditing}
                                    onChange={(phone) =>
                                        setUser({
                                            ...user,
                                            ["phone_number"]: phone,
                                        })
                                    }
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                    <Divider />
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            p: 2,
                        }}
                    >
                        {isEditing && (
                            <Button
                                color="primary"
                                variant="contained"
                                type="submit"
                            >
                                Update
                            </Button>
                        )}
                    </Box>
                </Card>
            </form>
        </>
    );
};

export default AccountProfileDetails;
