import {
    Card,
    CardContent,
    Grid,
    InputAdornment,
    Skeleton,
    SvgIcon,
    TextField,
    Typography,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { useEffect, useState } from "react";
import { GetBidders } from "src/utils/data";
import { useNavigate } from "react-router";

// React page
const Bidders = () => {
    const [bidders, setBidders] = useState([]);
    const [filteredBidders, setFilteredBidders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchValue, setSearchValue] = useState("");
    const navigate = useNavigate();
    useEffect(() => {
        async function GetBiddersLocal() {
            let bidders = await GetBidders();

            setBidders(bidders);

            setLoading(false);
        }
        GetBiddersLocal();
    }, []);

    const handleSearchChange = (event) => {
        setSearchValue(event.target.value);
    };

    useEffect(() => {
        let filteredBidders = bidders.filter((obj) =>
            obj.client_name
                .toLowerCase()
                .includes(searchValue.toLowerCase().trim())
        );
        setFilteredBidders(filteredBidders);
    }, [bidders, searchValue]);

    return (
        <Card>
            <CardContent>
                <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Grid
                        item
                        xs={12}
                        // alignItems="center"
                        sx={{ maxWidth: 300 }}
                        pl={1}
                    >
                        {loading ? (
                            <Skeleton height={10} width={"100%"} />
                        ) : (
                            <TextField
                                fullWidth
                                onChange={handleSearchChange}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SvgIcon
                                                fontSize="small"
                                                color="action"
                                            >
                                                <SearchIcon />
                                            </SvgIcon>
                                        </InputAdornment>
                                    ),
                                }}
                                placeholder="Search Bidder Name"
                                variant="outlined"
                            />
                        )}
                    </Grid>

                    <Grid
                        container
                        direction="row"
                        xs={12}
                        spacing={1}
                        p={{
                            xs: 2,
                            sm: 1,
                            md: 5,
                        }}
                    >
                        {loading ? (
                            <Skeleton height={10} width={"100%"} />
                        ) : (
                            filteredBidders.map((bidder, index) => (
                                <Grid item spacing={2} xs={12} key={bidder.id}>
                                    <Card
                                        sx={{
                                            p: 3,
                                            transition:
                                                "transform 0.2s ease-in-out",
                                            "&:hover": {
                                                transform: "scale(1.05)",
                                            },
                                        }}
                                        onClick={() => {
                                            navigate(
                                                `/app/bidder/${bidder.client_number}`,
                                                {
                                                    state: bidder.client_number,
                                                }
                                            );
                                        }}
                                    >
                                        <Typography>
                                            {bidder.client_name.replace(
                                                "&amp;",
                                                "&"
                                            )}
                                        </Typography>
                                    </Card>
                                </Grid>
                            ))
                        )}
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default Bidders;
