import { Icon } from "@material-ui/core";

const LogitIcon = (props) => {
    return (
        <Icon
            sx={{
                display: "flex",
                height: "inherit",
                width: "inherit",
                justifyContent: "center",
            }}
        >
            <img
                src="/static/images/assets/LogitTree_White.svg"
                alt="Alternate Logit Tree Logo"
            />
        </Icon>
    );
};

export default LogitIcon;
