import React, { useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";

import * as Yup from "yup";
import { Formik } from "formik";
import {
    Box,
    Button,
    Container,
    Grid,
    Link,
    TextField,
    Typography,
    Alert,
} from "@material-ui/core";
import { SignInWithEmailAndPassword } from "src/utils/auth";
import { LANDING_PAGE } from "src/Constants";
import LogitHelmet from "src/components/shared/LogitHelmet";

import PasswordTextField from "src/components/shared/PasswordTextField";

const Login = () => {
    const navigate = useNavigate();
    const [isErrorInSignIn, setIsErrorInSignIn] = useState(false);

    return (
        <>
            <LogitHelmet pageName={"Login"} />
            <Box
                sx={{
                    backgroundColor: "paper.default",
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    justifyContent: "center",
                }}
            >
                <Container maxWidth="sm">
                    <Formik
                        initialValues={{
                            email: "",
                            password: "",
                        }}
                        validationSchema={Yup.object().shape({
                            email: Yup.string()
                                .email("Must be a valid email")
                                .max(255)
                                .required("Email is required"),
                            password: Yup.string()
                                .max(255)
                                .required("Password is required"),
                        })}
                        onSubmit={async (values, actions) => {
                            var result = await SignInWithEmailAndPassword(
                                values.email,
                                values.password
                            );

                            if (result) {
                                navigate(LANDING_PAGE, { replace: true });
                            } else {
                                setIsErrorInSignIn(true);
                            }
                        }}
                    >
                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            isSubmitting,
                            touched,
                            values,
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <Box sx={{ mb: 3 }}>
                                    <Typography
                                        color="textPrimary"
                                        variant="h2"
                                    >
                                        Welcome to Logit Now
                                    </Typography>
                                    <Typography
                                        color="textSecondary"
                                        gutterBottom
                                        variant="body2"
                                    >
                                        Sign in
                                    </Typography>
                                </Box>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={6}></Grid>
                                    <Grid item xs={12} md={6}></Grid>
                                </Grid>
                                <Box
                                    sx={{
                                        pb: 1,
                                        pt: 3,
                                    }}
                                ></Box>
                                <TextField
                                    error={Boolean(
                                        touched.email && errors.email
                                    )}
                                    fullWidth
                                    helperText={touched.email && errors.email}
                                    label="Email Address"
                                    margin="normal"
                                    name="email"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="email"
                                    value={values.email}
                                    variant="outlined"
                                />
                                <PasswordTextField
                                    errors={errors}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                    handleSubmit={handleSubmit}
                                    isSubmitting={isSubmitting}
                                    touched={touched}
                                    value={values.password}
                                    label="Password"
                                    name="password"
                                />
                                {/* <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type={showPassword ? "text" : "password"}
                  value={values.password}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={()=>setShowPassword(!showPassword)}>
                          {showPassword ? (
                            <VisibilityOffIcon />
                          ) : (
                            <VisibilityIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                /> */}
                                {isErrorInSignIn ? (
                                    <Alert
                                        onClose={() => {
                                            setIsErrorInSignIn(false);
                                        }}
                                        severity="error"
                                    >
                                        Sign In failed, Please Sign up if you
                                        don't have an account or reset your
                                        password
                                    </Alert>
                                ) : null}
                                <Box sx={{ py: 2 }}>
                                    <Button
                                        color="primary"
                                        disabled={isSubmitting}
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                    >
                                        Sign in now
                                    </Button>
                                </Box>
                                <Typography
                                    color="textSecondary"
                                    variant="body1"
                                >
                                    Don&apos;t have an account?{" "}
                                    <Link
                                        component={RouterLink}
                                        to="/register"
                                        variant="h6"
                                    >
                                        Sign up
                                    </Link>
                                </Typography>
                                <Typography
                                    color="textSecondary"
                                    variant="body1"
                                >
                                    Forgot your password?{" "}
                                    <Link
                                        component={RouterLink}
                                        to="/login/forgotpassword"
                                        variant="h6"
                                    >
                                        Reset it here
                                    </Link>
                                </Typography>
                            </form>
                        )}
                    </Formik>
                </Container>
            </Box>
        </>
    );
};

export default Login;
