import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import * as Yup from "yup";
import { Formik } from "formik";
import {
    Box,
    Button,
    Container,
    Grid,
    Link,
    TextField,
    Typography,
    Alert,
} from "@material-ui/core";
import { SendResetPasswordEmail } from "src/utils/auth";
import LogitHelmet from "src/components/shared/LogitHelmet";

const ResetPassword = () => {
    const [isErrorSendingEmail, setIsErrorSendingEmail] = useState(false);
    const [isEmailSent, setIsEmaiSent] = useState(false);

    return (
        <>
            <LogitHelmet pageName={"Forgot Password"} />
            <Box
                sx={{
                    backgroundColor: "background.default",
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    justifyContent: "center",
                }}
            >
                <Container maxWidth="sm">
                    <Formik
                        initialValues={{
                            email: "",
                        }}
                        validationSchema={Yup.object().shape({
                            email: Yup.string()
                                .email("Must be a valid email")
                                .max(255)
                                .required("Email is required"),
                        })}
                        onSubmit={async (values, actions) => {
                            var result = await SendResetPasswordEmail(
                                values.email
                            );
                            if (result) {
                                setIsEmaiSent(true);
                            } else {
                                setIsErrorSendingEmail(true);
                            }
                        }}
                    >
                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            isSubmitting,
                            touched,
                            values,
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <Box sx={{ mb: 3 }}>
                                    <Typography
                                        color="textPrimary"
                                        variant="h2"
                                    >
                                        Forgot Password
                                    </Typography>
                                    <Typography
                                        color="textSecondary"
                                        gutterBottom
                                        variant="body2"
                                    >
                                        Please enter the email associated with
                                        your account, password reset link will
                                        be sent to your email
                                    </Typography>
                                </Box>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={6}>
                                        {/* <Button
                      color="primary"
                      fullWidth
                      startIcon={<FacebookIcon />}
                      onClick={handleSubmit}
                      size="large"
                      variant="contained"
                    >
                      Login with Facebook
                    </Button> */}
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        {/* <Button
                      fullWidth
                      startIcon={<GoogleIcon />}
                      onClick={handleSubmit}
                      size="large"
                      variant="contained"
                    >
                      Login with Google
                    </Button> */}
                                    </Grid>
                                </Grid>
                                <Box
                                    sx={{
                                        pb: 1,
                                        pt: 3,
                                    }}
                                >
                                    {/* <Typography
                    align="center"
                    color="textSecondary"
                    variant="body1"
                  >
                    or login with email address
                  </Typography> */}
                                </Box>
                                <TextField
                                    error={Boolean(
                                        touched.email && errors.email
                                    )}
                                    fullWidth
                                    helperText={touched.email && errors.email}
                                    label="Email Address"
                                    margin="normal"
                                    name="email"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="email"
                                    value={values.email}
                                    variant="outlined"
                                />
                                {isErrorSendingEmail ? (
                                    <Alert
                                        onClose={() => {
                                            setIsErrorSendingEmail(false);
                                        }}
                                        severity="error"
                                    >
                                        Some error has occurred while sending
                                        password reset email
                                    </Alert>
                                ) : null}
                                {isEmailSent ? (
                                    <Alert
                                        onClose={() => {
                                            setIsErrorSendingEmail(false);
                                        }}
                                        severity="success"
                                    >
                                        Reset password link has been sent to
                                        your email
                                    </Alert>
                                ) : null}
                                <Box sx={{ py: 2 }}>
                                    <Button
                                        color="primary"
                                        disabled={isSubmitting}
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                    >
                                        Send Password Reset Email
                                    </Button>
                                </Box>
                                <Typography
                                    color="textSecondary"
                                    variant="body1"
                                >
                                    Try Sign in again?{" "}
                                    <Link
                                        component={RouterLink}
                                        to="/login"
                                        variant="h6"
                                    >
                                        Sign In
                                    </Link>
                                </Typography>
                            </form>
                        )}
                    </Formik>
                </Container>
            </Box>
        </>
    );
};

export default ResetPassword;
