import React from "react";
import ServicesSection from "src/components/services/ServicesSection";
import {
    Box,
    Button,
    Container,
    List,
    ListItem,
    ListItemText,
    Typography,
    Grid,
    Paper,
    useTheme,
    experimentalStyled as styled,
    ListItemAvatar,
    ListItemIcon,
    Avatar,
} from "@material-ui/core";
import { CheckCircle } from "@material-ui/icons";
import LockOpen from "@material-ui/icons/LockOpen";
import { FloatingItem } from "../shared/FloatingItem";
import { useNavigate } from "react-router";
import ContactUsButton from "../shared/ContactUsButton";

const Programming = () => {
    const navigate = useNavigate();
    const theme = useTheme();

    return (
        <Grid
            container
            height="auto"
            display="flex"
            flexDirection="row"
            justifyContent="space-evenly"
            pt={{ xs: 5, sm: 10 }}
            alignItems="center"
            id="products"
        >
            <Grid item xs={12} sm={12} md={6} zeroMinWidth alignSelf="center">
                <FloatingItem>
                    <Typography
                        variant="h3"
                        p={1}
                        color={theme.palette.primary.main}
                    >
                        Legit Logic from Logit.
                    </Typography>
                </FloatingItem>
                <FloatingItem>
                    <Typography variant="h4" p={1} color="text.secondary">
                        Everyone has an idea for an app, but building one can be
                        tricky. Let's work together to turn your ideas into
                        reality.
                    </Typography>
                </FloatingItem>

                <FloatingItem>
                    <Typography
                        variant="h5"
                        p={1}
                        color={theme.palette.secondary.main}
                    >
                        A Proven Track Record
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <CheckCircle
                                    sx={{
                                        color: theme.palette.primary.main,
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText
                                primary="Logit Now, our flagship app was built entirely in-house and support dozens of users"
                                primaryTypographyProps={{
                                    variant: "body1",
                                }}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <CheckCircle
                                    sx={{
                                        color: theme.palette.primary.main,
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText
                                primary="Our staff have built apps for multi-national corporations"
                                primaryTypographyProps={{
                                    variant: "body1",
                                }}
                            />
                        </ListItem>
                    </List>
                </FloatingItem>
            </Grid>
            <Grid item>
                <ContactUsButton />
            </Grid>
        </Grid>
    );
};

export default Programming;
