import { Skeleton, TableRow } from "@material-ui/core";
import { TableCell } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet";

const LogitTableSkeleton = ({ rowCount, cellCount }) => {
    return (
        <>
            {[...Array(rowCount)].map((item, index) => (
                <TableRow>
                    {[...Array(cellCount)].map((item, index) => (
                        <TableCell>
                            <Skeleton />
                        </TableCell>
                    ))}
                </TableRow>
            ))}
        </>
    );
};

export default LogitTableSkeleton;
