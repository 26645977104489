import Map from "src/components/timbersale/Map";

import { Container, Grid } from "@material-ui/core";

const MapNew = ({ geo, geoTextMapLayer }) => {
    return (
        <Container height="100%" maxWidth={false}>
            <Grid container spacing={3}>
                <Grid
                    item
                    container
                    justifyContent="flex-start"
                    xs={12}
                    spacing={3}
                >
                    <Grid item xs={12} minHeight={600}>
                        {<Map geo={geo} geoTextMapLayer={geoTextMapLayer} />}
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
};

export default MapNew;
