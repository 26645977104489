import React, { useState } from "react";

import { TextField, InputAdornment, IconButton } from "@material-ui/core";

import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

const PasswordTextField = ({
    errors,
    handleBlur,
    handleChange,
    touched,
    value,
    label,
    name,
}) => {
    const [showPassword, setShowPassword] = useState(false);

    return (
        <TextField
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            label={label}
            margin="normal"
            name={name}
            onBlur={handleBlur}
            onChange={handleChange}
            type={showPassword ? "text" : "password"}
            value={value}
            variant="outlined"
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                        >
                            {showPassword ? (
                                <VisibilityOffIcon />
                            ) : (
                                <VisibilityIcon />
                            )}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    );
};

export default PasswordTextField;
