import { Tooltip, Chip } from '@material-ui/core';
import { NEW_SALE_THRESHOLD } from 'src/Config';

const newTooltip = `Published to BCBid in the last ${NEW_SALE_THRESHOLD} days`;

const NewChip = (props) => {
  return (
    <Tooltip enterDelay={300} title={newTooltip}>
      <Chip
        color='new'
        label='New'
        size='small'
        variant='outlined'
        onClick={null}
      />
    </Tooltip>
  );
};

export default NewChip;
