import React, { useState } from "react";
import { Link as RouterLink, useNavigate, useLocation } from "react-router-dom";

import { Formik } from "formik";
import {
    Box,
    Button,
    Checkbox,
    Container,
    FormHelperText,
    Link,
    Typography,
    Alert,
} from "@material-ui/core";
import { SignUpWithEmailAndPassword } from "src/utils/auth";
import { CreateUser, UpdateUser } from "src/utils/data";
import UserRegistration from "src/components/registration/UserRegistration";
import ValidationSchema from "src/components/registration/ValidationSchema";
import InitialValues from "src/components/registration/InitialValues";
import LogitHelmet from "src/components/shared/LogitHelmet";
import { getAuth } from "firebase/auth";

const Register = (props) => {
    const navigate = useNavigate();
    const location = useLocation();

    const [isErrorInSignUp, setIsErrorInSignUp] = useState(false);

    return (
        <>
            <Box
                pt={10}
                pb={{ xs: 5, sm: 10 }}
                sx={{
                    backgroundColor: "paper.default",
                    display: "flex",
                    flexDirection: "row",
                    height: "auto",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <LogitHelmet pageName={"Register"} />

                <Container maxWidth="xl" height="100%">
                    <Formik
                        initialValues={InitialValues}
                        validationSchema={ValidationSchema}
                        onSubmit={async (values, actions) => {
                            var result = await SignUpWithEmailAndPassword(
                                values.email,
                                values.password
                            );

                            if (result) {
                                var uid = result.user.uid;
                                var user = {
                                    uid: uid,
                                    email: values.email,
                                    name: values.name,
                                    organization: values.organization,
                                    phone_number: values.phonenumber,
                                };
                                await CreateUser(user);
                            } else {
                                setIsErrorInSignUp(true);
                            }
                        }}
                    >
                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            isSubmitting,
                            touched,
                            values,
                            setFieldValue,
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <>
                                    <UserRegistration
                                        {...{
                                            errors,
                                            handleBlur,
                                            handleChange,
                                            handleSubmit,
                                            isSubmitting,
                                            touched,
                                            values,
                                            setFieldValue,
                                        }}
                                    />
                                    <Container maxWidth="sm">
                                        <Box
                                            sx={{
                                                alignItems: "center",
                                                display: "flex",
                                                ml: -1,
                                            }}
                                        >
                                            <Checkbox
                                                checked={values.policy}
                                                name="policy"
                                                onChange={handleChange}
                                            />
                                            <Typography
                                                color="textSecondary"
                                                variant="body1"
                                            >
                                                I have read the{" "}
                                                <Link
                                                    color="primary"
                                                    component={RouterLink}
                                                    to="/legal/terms"
                                                    target="_blank"
                                                    underline="always"
                                                    variant="h6"
                                                >
                                                    Terms and Conditions
                                                </Link>
                                            </Typography>
                                        </Box>
                                        {Boolean(
                                            touched.policy && errors.policy
                                        ) && (
                                            <FormHelperText error>
                                                {errors.policy}
                                            </FormHelperText>
                                        )}
                                    </Container>
                                    <Container maxWidth="sm">
                                        {isErrorInSignUp ? (
                                            <Alert
                                                onClose={() => {
                                                    setIsErrorInSignUp(false);
                                                }}
                                                severity="error"
                                            >
                                                Sign up failed, do you already
                                                have an account?
                                            </Alert>
                                        ) : null}
                                        <Box
                                            sx={{
                                                py: 2,
                                                justifyContent: "center",
                                            }}
                                        >
                                            <Button
                                                color="primary"
                                                disabled={
                                                    Boolean(
                                                        touched.policy &&
                                                            errors.policy
                                                    ) && isSubmitting
                                                }
                                                // width="auto"
                                                // size="large"
                                                fullWidth
                                                type="submit"
                                                variant="contained"
                                            >
                                                Sign up now
                                            </Button>
                                        </Box>
                                        <Typography
                                            color="textSecondary"
                                            variant="body1"
                                        >
                                            Already have an account?{" "}
                                            <Link
                                                component={RouterLink}
                                                to="/login"
                                                variant="h6"
                                            >
                                                Sign In
                                            </Link>
                                        </Typography>
                                    </Container>
                                </>
                            </form>
                        )}
                    </Formik>
                </Container>
            </Box>
        </>
    );
};

export default Register;
