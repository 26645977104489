import React from "react";
import { useNavigate } from "react-router-dom";
import {
    Box,
    Button,
    List,
    ListItem,
    ListItemText,
    Typography,
    Grid,
    useTheme,
    ListItemIcon,
    CardContent,
} from "@material-ui/core";
import CheckCircle from "@material-ui/icons/CheckCircle";

import LogitHelmet from "src/components/shared/LogitHelmet";

import {} from "@material-ui/core";

import LockOpen from "@material-ui/icons/LockOpen";
import { FloatingItem } from "src/components/shared/FloatingItem";
import { Card, Icon, Paper } from "@mui/material";
import FounderFeature from "src/components/home/FounderFeature";
import ProductFeatureBreakdown from "src/components/home/ProductFeatureBreakdown";
import SignUpButton from "src/components/shared/SignUpButton";

const Home = () => {
    const navigate = useNavigate();

    const theme = useTheme();

    return (
        <>
            <LogitHelmet pageName={"Home"}></LogitHelmet>

            <Box>
                <Grid
                    container
                    height="auto"
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-evenly"
                    pt={{ xs: 5, sm: 10 }}
                    alignItems="center"
                    id="products"
                >
                    <Grid item xs={12} md={7} zeroMinWidth alignSelf="center">
                        <FloatingItem>
                            <Card>
                                <CardContent>
                                    <FloatingItem>
                                        <Typography
                                            variant="h2"
                                            p={1}
                                            sx={{
                                                fontFamily:
                                                    "Roboto, sans-serif",
                                                color: "#333",
                                                textShadow:
                                                    "2px 2px 4px rgba(0, 0, 0, 0.2)",
                                                transition: "color 1s",
                                            }}
                                        >
                                            LogitNow
                                        </Typography>
                                        <Typography
                                            variant="h1"
                                            sx={{
                                                fontFamily:
                                                    "Roboto, sans-serif",
                                                color: "#333",
                                                textShadow:
                                                    "1px 1px 2px rgba(0, 0, 0, 0.2)",
                                                transition: "color 1s",
                                            }}
                                            p={1}
                                        >
                                            Transform your BC Timber Sales
                                            Experience
                                        </Typography>
                                        <Typography
                                            variant="body1"
                                            p={1}
                                            sx={{
                                                fontFamily:
                                                    "Roboto, sans-serif",
                                                color: "#333",
                                                textShadow:
                                                    "1px 1px 2px rgba(0, 0, 0, 0.2)",
                                                transition: "color 1s",
                                            }}
                                        >
                                            Dozens of companies use LogitNow to
                                            Buy, Sell, and Manage BC Timber
                                        </Typography>
                                    </FloatingItem>

                                    <FloatingItem>
                                        <ProductFeatureBreakdown />
                                    </FloatingItem>
                                </CardContent>
                            </Card>
                        </FloatingItem>
                    </Grid>

                    <Grid item xs={10} zeroMinWidth alignSelf="center">
                        <FloatingItem>
                            <FounderFeature theme={theme} />
                        </FloatingItem>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default Home;
