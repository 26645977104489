import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import {
    CheckCircle,
    Cancel,
    Map,
    ArrowBack,
    AddRoadOutlined,
} from "@material-ui/icons";
import LogitCard from "src/components/timbersale/LogitCard";
import { BCDATA_API_ENDPOINT_V2, MAPBOX_API_KEY } from "src/Config";
import { User } from "react-feather";
import convex from "@turf/convex";
import buffer from "@turf/buffer";

import MyLocation from "@material-ui/icons/MyLocation";
import {
    Card,
    Container,
    Chip,
    Grid,
    Box,
    IconButton,
    Table,
    TableCell,
    TableBody,
    TableHead,
    TableRow,
    Typography,
    useTheme,
    Button,
    AppBar,
    Tabs,
    Tab,
    Skeleton,
    CardContent,
    CardHeader,
    CircularProgress,
} from "@material-ui/core";
import PerfectScrollbar from "react-perfect-scrollbar";
import { formatCurrency, getAppBarTopPadding } from "src/utils/helpers";
import LogitHelmet from "src/components/shared/LogitHelmet";
import { TabPanel, a11yProps } from "src/components/shared/LogitTabs";
import {
    GetBidderById,
    GetGeojsonCenter,
    GetRegionsForSales,
    GetSaleBidsAndSaleInformationForBidder,
    GetSalesFromSaleIds,
    formatDateAsString,
    logUserBidderVisit,
} from "src/utils/data";

import ReactMapGL, {
    Source,
    Layer,
    ScaleControl,
    FullscreenControl,
    NavigationControl,
} from "react-map-gl";
import { getAuth } from "firebase/auth";
import { serverTimestamp } from "firebase/firestore";

const WON_SALES_COLOUR = "rgba(255, 0, 0, 1)";
const LOST_SALES_COLOUR = "rgba(0, 0, 102, 1)";
const OPERATING_AREA_COLOUR = "rgba(255, 255, 0, .2)";

const Legend = ({ theme }) => {
    const styles = {
        container: {
            position: "absolute",
            bottom: 0,
            right: 0,
            padding: "10px",
            margin: "10px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            zIndex: 1000,
        },
    };

    return (
        <div style={styles.container}>
            <Card maxWidth="none">
                <CardContent>
                    <Grid
                        container
                        width="200px"
                        display={"flex"}
                        alignItems={"center"}
                    >
                        <Grid item xs={12} pb={2}>
                            <Typography variant="h6">Legend</Typography>
                        </Grid>
                        <Grid item xs={3} m={1}>
                            <Chip
                                sx={{
                                    backgroundColor: WON_SALES_COLOUR,
                                }}
                            ></Chip>
                        </Grid>
                        <Grid item xs={8} justifyContent={"center"}>
                            <Typography variant="body2">Won Sales</Typography>
                        </Grid>
                        <Grid item xs={3} m={1}>
                            <Chip
                                sx={{
                                    backgroundColor: LOST_SALES_COLOUR,
                                }}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="body2">Lost Sales</Typography>
                        </Grid>

                        <Grid item xs={3} m={1}>
                            <Chip
                                sx={{
                                    backgroundColor:
                                        OPERATING_AREA_COLOUR.replace(
                                            ".2",
                                            "1"
                                        ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="body2">
                                Operating Area
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </div>
    );
};

const Bidder = (props) => {
    const navigation = useLocation();
    const navigate = useNavigate();
    const location = useLocation();
    const currentpath = location.pathname;

    const theme = useTheme();

    const [errorMessage, setErrorMessage] = useState("");
    const [roadPermitErrorMessage, setRoadPermitErrorMessage] = useState("");
    const [biddersSales, setBiddersSales] = useState({});

    const [biddersOperatingArea, setBiddersOperatingArea] = useState({});
    const [mapIsLoaded, setMapIsLoaded] = useState(false);

    const [saleIds, setSaleIds] = useState([]);
    const [salesMap, setSalesMap] = useState({});

    const [regions, setRegions] = useState([]);

    const [bidder, setBidder] = useState("");
    const [bidderClientNumber, setBidderClientNumber] = useState("");

    const [bidderObject, setBidderObject] = useState([]);

    const [appBarTopPadding, setAppBarTopPadding] = useState(64);
    const [activeTab, setActiveTab] = useState(0);

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    const [roadPermit, setRoadPermit] = useState([]);
    const [activeRoadPermitRow, setActiveRoadPermitRow] = useState(null);

    const [roadPermitsFetched, setRoadPermitsFetched] = useState(false);
    const [activeRoadPermitSection, setActiveRoadPermitSection] =
        useState(null);
    const [loadingRoadPermits, setIsLoadingRoadPermits] = useState(true);
    const [loadingRoadPermitMessage, setLoadingRoadPermitMessage] =
        useState("");
    const [totalRoadPermit, setTotalRoadPermit] = useState(0);

    /* Map */
    const [viewport, setViewport] = useState({});
    const [mapCentre, setMapCentre] = useState({});
    const salePolyonMapLayer = {
        id: "data",
        type: "fill",

        paint: {
            "fill-color": [
                "match",
                ["get", "CLIENT_NUMBER"],
                bidderClientNumber,
                WON_SALES_COLOUR,
                /* default */ LOST_SALES_COLOUR,
            ],
            "fill-outline-color": [
                "match",
                ["get", "CLIENT_NUMBER"],
                bidderClientNumber,
                WON_SALES_COLOUR,
                /* default */ LOST_SALES_COLOUR,
            ],
            "fill-opacity": 0.9,
        },
        zIndex: 1,
    };

    const saleTextMapLayer = {
        type: "symbol",
        id: "polygon-label",
        layout: {
            "text-field": [
                "concat",
                ["get", "CUT_BLOCK_FOREST_FILE_ID"],
                "\nBlock: ",
                ["get", "CUT_BLOCK_ID"],
            ],
            "text-font": ["Open Sans Regular"],
            "text-size": 16,
            "text-anchor": "left",
            "text-offset": [1, 0],
            "symbol-avoid-edges": true,
            // "halo-width": 1,
            // "halo-color": "#000",
        },
        paint: {
            "text-color": "white",
            // "text-halo-blur": 1,
            "text-halo-color": "#000",
            "text-halo-width": 1,
        },
    };

    const operatingAreaMapLayer = {
        id: "data2",
        type: "fill",
        paint: {
            "fill-color": OPERATING_AREA_COLOUR,
            "fill-outline-color": "rgba(255, 255, 0, 1)",
            "fill-opacity": 0.9,
        },
        zIndex: 0,
    };
    const [saleViewport, setSaleViewport] = useState({
        latitude: 52.1417,
        longitude: -122.1417,
        zoom: 8,
    });
    const scaleControlStyle = {
        position: "absolute",
        bottom: 36,
        left: 0,
        padding: "10px",
    };

    const fullscreenControlStyle = {
        position: "absolute",
        top: 0,
        left: 0,
        padding: "10px",
    };

    const navigationStyle = {
        position: "absolute",
        top: 36,
        left: 0,
        padding: "10px",
    };

    const geolocateStyle = {
        position: "absolute",
        top: 0,
        right: 0,
        margin: 10,
    };

    function handleRecentre() {
        setViewport(mapCentre);
    }

    // Initial call, get the bidder's history which will include all the sales they have bid on
    useEffect(() => {
        async function SetBidderAndBidderHistory() {
            let clientNumber = navigation.state;
            setBidderClientNumber(clientNumber);

            let bidder = await GetBidderById(clientNumber);
            setBidder(bidder);

            let bidderRoot = await GetSaleBidsAndSaleInformationForBidder(
                clientNumber
            );

            let biddersSalesSortedByDate = Object.values(bidderRoot).sort(
                (a, b) => {
                    const aDate = a.sale?.closed_datetime?.seconds;
                    const bDate = b.sale?.closed_datetime?.seconds;
                    return bDate - aDate;
                }
            );

            setSaleIds(Object.keys(bidderRoot));
            setBidderObject(biddersSalesSortedByDate);
        }
        SetBidderAndBidderHistory();
    }, []);

    useEffect(() => {
        async function logUserActivity() {
            if (bidderClientNumber !== undefined && bidderClientNumber !== "") {
                await logUserBidderVisit(getAuth().currentUser.uid, {
                    bidderId: bidderClientNumber,
                    url: currentpath,
                    timestamp: serverTimestamp(),
                });
            }
        }

        logUserActivity();
    }, [bidderClientNumber]);

    useEffect(() => {
        async function SetRoadPermit() {
            if (roadPermitsFetched) return;
            if (bidderClientNumber === "") return;

            setIsLoadingRoadPermits(true);
            setLoadingRoadPermitMessage("Fetching bidder road permits");
            let url = `${BCDATA_API_ENDPOINT_V2}/bcdw/road?query=CLIENT_NUMBER='${bidderClientNumber}'&drop_geometry`;
            console.log(url);
            const response = await fetch(url, {
                method: "GET",
                mode: "cors",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Access-Control-Request-Headers": "*",
                },
            })
                .then((response) => {
                    if (response.status === 200) {
                        return response.json();
                    } else {
                        return null;
                    }
                })
                .catch((error) => {
                    setRoadPermitErrorMessage(
                        "Error fetching bidder road permits"
                    );
                    return null;
                });
            if (
                response &&
                response.features &&
                Array.isArray(response.features)
            ) {
                setLoadingRoadPermitMessage("Filtering retired road permits");
                const filteredFeatures = await response.features.filter(
                    (feature) => {
                        return (
                            feature.properties.LIFE_CYCLE_STATUS_CODE !==
                            "RETIRED"
                        );
                    }
                );
                const sortedFeatures = await filteredFeatures.sort((a, b) => {
                    return a.properties?.MAP_LABEL?.toUpperCase() <
                        b.properties?.MAP_LABEL?.toUpperCase()
                        ? -1
                        : 1;
                });
                if (sortedFeatures.length === 0) {
                    setTotalRoadPermit(-1);
                    setRoadPermit([]);
                    setIsLoadingRoadPermits(false);
                } else {
                    const totalLength = sortedFeatures.reduce(
                        (acc, roadPermit) =>
                            acc + roadPermit.properties.FEATURE_LENGTH_M,
                        0
                    );
                    setTotalRoadPermit(totalLength);
                    setRoadPermit(sortedFeatures);
                    setIsLoadingRoadPermits(false);
                }
            } else {
                setRoadPermitsFetched(false);
                setRoadPermitErrorMessage("Error fetching bidder road permits");
            }
        }
        SetRoadPermit();
    }, [bidderClientNumber]);

    useEffect(() => {
        function createQueryString(ids) {
            let query = "";
            ids.forEach((id) => {
                if (id.includes(" ")) {
                    id?.split(" ").forEach((subId) => {
                        query += `CUT_BLOCK_FOREST_FILE_ID='${subId}' OR `;
                    });
                }
                query += `CUT_BLOCK_FOREST_FILE_ID='${id}' OR `;
            });
            return query.substring(0, query.length - 4);
        }

        async function FetchSales(query) {
            if (query === "") return;
            let url = `${BCDATA_API_ENDPOINT_V2}/bcdw/fta?query=${query}`;

            const response = await fetch(url, {
                method: "GET",
                mode: "cors",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Access-Control-Request-Headers": "*",
                },
            })
                .then((response) => {
                    if (response.status === 200) {
                        return response.json();
                    } else {
                        return null;
                    }
                })
                .catch((error) => {
                    setErrorMessage("Error fetching bidder sales map");
                    return null;
                });

            if (response !== null) {
                setBiddersSales(await response);
            } else {
                setErrorMessage("Error fetching bidder sales map");
            }
        }

        function CreateQueryString() {
            let cleanIds = new Set(
                saleIds.map((id) => {
                    return id.substring(0, 6);
                })
            );
            let query = createQueryString(cleanIds);
            return query;
        }
        let query = CreateQueryString();
        FetchSales(query);
    }, [saleIds]);

    useEffect(() => {
        if (
            typeof biddersSales === "object" &&
            Object.keys(biddersSales).length === 0
        )
            return;
        let center = GetGeojsonCenter(biddersSales);

        let hull = convex(biddersSales, { concavity: 0 });
        let bufferedHull = buffer(hull, 3, { units: "kilometers" });
        setBiddersOperatingArea(bufferedHull);
        setMapCentre({
            latitude: center.geometry.coordinates[1],
            longitude: center.geometry.coordinates[0],
            zoom: 8,
        });

        setMapIsLoaded(true);
    }, [biddersSales]);

    useEffect(() => {
        setViewport(mapCentre);
    }, [mapCentre]);
    useEffect(() => {
        async function SetActiveRegionsAndVerifySales() {
            let sales = await GetSalesFromSaleIds(saleIds);
            let biddersActiveRegions = await GetRegionsForSales(sales);
            setRegions(biddersActiveRegions);
        }
        SetActiveRegionsAndVerifySales();
    }, [saleIds]);

    useEffect(() => {
        setAppBarTopPadding(getAppBarTopPadding());
    }, [bidderObject]);

    return (
        <>
            <LogitHelmet pageName={`${bidder.client_name}`} />

            <Box
                sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                    elevation: 0,
                }}
            >
                <AppBar
                    color="white"
                    sx={{
                        top: appBarTopPadding,
                        left: { md: "none", lg: "256px" },
                        color: "accent",
                    }}
                >
                    <Tabs
                        position="sticky"
                        top={0}
                        aria-label="Bid Tabs"
                        indicatorColor="primary"
                        alignItems="center"
                    >
                        <Button
                            startIcon={<ArrowBack />}
                            onClick={() => window.history.back()}
                        >
                            back
                        </Button>
                        <Tab>Back</Tab>
                    </Tabs>
                </AppBar>
            </Box>
            <Box
                sx={{
                    backgroundColor: "background.default",
                    minHeight: "100%",
                    py: 3,
                    pt: 10,
                    display: "sticky",
                }}
            >
                <Container maxWidth={false}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={9} lg={4}>
                            {bidder !== "" ? (
                                <LogitCard
                                    Title="Bidder"
                                    Primary={bidder?.client_name.replace(
                                        "amp;",
                                        ""
                                    )}
                                    Icon={<User />}
                                    IconBGColor={theme.palette.primary.main}
                                />
                            ) : (
                                <Skeleton height={"100%"} />
                            )}
                        </Grid>

                        <Grid item xs={12} md={9} lg={4}>
                            {regions.length > 0 ? (
                                <LogitCard
                                    Title="Active Regions"
                                    Primary={regions.join(", ")}
                                    Icon={<Map />}
                                    IconBGColor={theme.palette.primary.main}
                                />
                            ) : (
                                <Skeleton height="100%" />
                            )}
                        </Grid>
                        <Grid item xs={12} md={9} lg={4}>
                            {totalRoadPermit !== 0 ? (
                                <LogitCard
                                    Title="Total Active Road Permit"
                                    Primary={
                                        totalRoadPermit === -1
                                            ? "No Road Permit"
                                            : `${(
                                                  totalRoadPermit / 1000
                                              ).toLocaleString("en-US")} km`
                                    }
                                    Icon={<AddRoadOutlined />}
                                    IconBGColor={theme.palette.primary.main}
                                />
                            ) : (
                                <Skeleton height="100%" />
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <Tabs
                                value={activeTab}
                                onChange={handleTabChange}
                                position="sticky"
                                top={30}
                                aria-label="Sale Tabs"
                                variant="scrollable"
                                indicatorColor="primary"
                                allowScrollButtonsMobile
                            >
                                <Tab label="Sales" {...a11yProps(0)} />
                                <Tab
                                    label="Active Road Permits"
                                    {...a11yProps(1)}
                                />
                            </Tabs>
                        </Grid>
                        <Grid item xs={12}>
                            <TabPanel value={activeTab} index={0}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Card>
                                            <PerfectScrollbar>
                                                <Box>
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>
                                                                    <Typography variant="h6">
                                                                        Sale Id
                                                                    </Typography>
                                                                </TableCell>

                                                                <TableCell>
                                                                    <Typography variant="h6">
                                                                        Region
                                                                    </Typography>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Typography variant="h6">
                                                                        Location
                                                                    </Typography>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Typography variant="h6">
                                                                        Winner
                                                                    </Typography>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Typography variant="h6">
                                                                        Bid /m3
                                                                    </Typography>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Typography variant="h6">
                                                                        Stumpage
                                                                        /m3
                                                                    </Typography>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Typography variant="h6">
                                                                        Total
                                                                        Stumpage
                                                                        /m3
                                                                    </Typography>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Typography variant="h6">
                                                                        Closed
                                                                    </Typography>
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableHead>

                                                        <TableBody>
                                                            {Object.entries(
                                                                bidderObject
                                                            ).map(
                                                                (
                                                                    [key, sale],
                                                                    index
                                                                ) => (
                                                                    <TableRow
                                                                        hover
                                                                        onClick={(
                                                                            e
                                                                        ) => {
                                                                            e.preventDefault();

                                                                            navigate(
                                                                                `/app/sale/${sale.bid.uid}`
                                                                            );
                                                                        }}
                                                                    >
                                                                        <TableCell>
                                                                            <Typography variant="body">
                                                                                {
                                                                                    sale
                                                                                        ?.sale
                                                                                        ?.ffid
                                                                                }
                                                                            </Typography>
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <Typography variant="body">
                                                                                {
                                                                                    sale
                                                                                        ?.sale
                                                                                        ?.region_name
                                                                                }
                                                                            </Typography>
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <Typography variant="body">
                                                                                {
                                                                                    sale
                                                                                        ?.sale
                                                                                        ?.location
                                                                                }
                                                                            </Typography>
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <Typography variant="body">
                                                                                {sale
                                                                                    ?.sale
                                                                                    ?.winning_client_number ===
                                                                                bidderClientNumber ? (
                                                                                    <CheckCircle
                                                                                        sx={{
                                                                                            color: theme
                                                                                                .palette
                                                                                                .primary
                                                                                                .main,
                                                                                        }}
                                                                                    />
                                                                                ) : (
                                                                                    <Cancel
                                                                                        sx={{
                                                                                            color: theme
                                                                                                .palette
                                                                                                .closing
                                                                                                .main,
                                                                                        }}
                                                                                    />
                                                                                )}
                                                                            </Typography>
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <Typography variant="body">
                                                                                {formatCurrency(
                                                                                    sale
                                                                                        ?.bid
                                                                                        ?.bid_per_cubic_m
                                                                                )}
                                                                            </Typography>
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <Typography variant="body">
                                                                                {formatCurrency(
                                                                                    sale
                                                                                        ?.sale
                                                                                        ?.stumpage_rate_per_cubic_m
                                                                                )}
                                                                            </Typography>
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <Typography variant="body">
                                                                                {formatCurrency(
                                                                                    sale
                                                                                        ?.bid
                                                                                        ?.bid_per_cubic_m +
                                                                                        parseFloat(
                                                                                            sale
                                                                                                ?.sale
                                                                                                ?.stumpage_rate_per_cubic_m
                                                                                        )
                                                                                )}
                                                                            </Typography>
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <Typography variant="body">
                                                                                {formatDateAsString(
                                                                                    sale
                                                                                        ?.sale
                                                                                        ?.closed_time,
                                                                                    "MMMM Do, YYYY"
                                                                                )}
                                                                            </Typography>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                )
                                                            )}
                                                        </TableBody>
                                                    </Table>
                                                </Box>
                                            </PerfectScrollbar>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Card>
                                            {mapIsLoaded &&
                                            errorMessage === "" ? (
                                                <Card>
                                                    <CardHeader title="Bidder Activity Map"></CardHeader>
                                                    <CardContent>
                                                        <ReactMapGL
                                                            {...viewport}
                                                            width="100%"
                                                            height={"80vh"}
                                                            mapStyle="mapbox://styles/mapbox/satellite-streets-v11"
                                                            onViewportChange={(
                                                                viewport
                                                            ) =>
                                                                setViewport(
                                                                    viewport
                                                                )
                                                            }
                                                            mapboxApiAccessToken={
                                                                MAPBOX_API_KEY
                                                            }
                                                        >
                                                            <Source
                                                                id="data2"
                                                                type="geojson"
                                                                data={
                                                                    biddersOperatingArea
                                                                }
                                                                attribution="Contains information licensed under the Open Government Licence – British Columbia"
                                                            >
                                                                <Layer
                                                                    {...operatingAreaMapLayer}
                                                                />
                                                            </Source>
                                                            <Source
                                                                id="my-data"
                                                                type="geojson"
                                                                data={
                                                                    biddersSales
                                                                }
                                                                attribution="Contains information licensed under the Open Government Licence – British Columbia"
                                                            >
                                                                <Layer
                                                                    {...salePolyonMapLayer}
                                                                />
                                                                <Layer
                                                                    {...saleTextMapLayer}
                                                                />
                                                            </Source>

                                                            <div
                                                                style={
                                                                    navigationStyle
                                                                }
                                                            >
                                                                <NavigationControl />
                                                            </div>

                                                            <div
                                                                style={
                                                                    fullscreenControlStyle
                                                                }
                                                            >
                                                                <FullscreenControl />
                                                            </div>

                                                            <div
                                                                style={
                                                                    scaleControlStyle
                                                                }
                                                            >
                                                                <ScaleControl />
                                                            </div>

                                                            <div
                                                                style={
                                                                    geolocateStyle
                                                                }
                                                            >
                                                                <IconButton
                                                                    color="white"
                                                                    style={{
                                                                        background:
                                                                            "white",
                                                                        borderRadius: 9,
                                                                        height: 35,
                                                                        width: 35,
                                                                    }}
                                                                    onClick={
                                                                        handleRecentre
                                                                    }
                                                                >
                                                                    <MyLocation />
                                                                </IconButton>
                                                            </div>

                                                            <div>
                                                                <Legend />
                                                            </div>
                                                        </ReactMapGL>
                                                    </CardContent>
                                                </Card>
                                            ) : errorMessage === "" ? (
                                                <Card
                                                    width="100%"
                                                    height={800}
                                                    display="flex"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    flexDirection="column"
                                                >
                                                    <CardContent>
                                                        <Typography variant="h5">
                                                            Loading Bidder
                                                            Map...
                                                        </Typography>
                                                        {mapIsLoaded ? null : (
                                                            <Skeleton
                                                                width="auto"
                                                                height={100}
                                                            />
                                                        )}
                                                    </CardContent>
                                                </Card>
                                            ) : (
                                                <Card
                                                    width="100%"
                                                    height={800}
                                                    display="flex"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    flexDirection="column"
                                                >
                                                    <CardContent>
                                                        <Typography variant="h5">
                                                            {
                                                                roadPermitErrorMessage
                                                            }
                                                        </Typography>
                                                    </CardContent>
                                                </Card>
                                            )}
                                        </Card>
                                    </Grid>
                                </Grid>
                            </TabPanel>
                        </Grid>
                        <Grid item xs={12}>
                            <TabPanel value={activeTab} index={1}>
                                {loadingRoadPermits ? (
                                    <>
                                        <Typography>
                                            {loadingRoadPermitMessage}
                                        </Typography>
                                        <CircularProgress />
                                    </>
                                ) : totalRoadPermit === -1 ? (
                                    <Typography>
                                        No Active Road Permit
                                    </Typography>
                                ) : (
                                    <Grid container spacing={{ xs: 1, sm: 3 }}>
                                        <Grid item xs={12} sm={6}>
                                            <Card>
                                                <Box
                                                    style={{
                                                        height: "60vh",
                                                        width: "100%",
                                                    }}
                                                >
                                                    <PerfectScrollbar>
                                                        <Table>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell>
                                                                        <Typography variant="h6">
                                                                            Timbermark
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Typography variant="h6">
                                                                            Road
                                                                            Permit
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Typography variant="h6">
                                                                            Road
                                                                            Section
                                                                        </Typography>
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {roadPermit.map(
                                                                    (road) => (
                                                                        <TableRow
                                                                            onClick={(
                                                                                e
                                                                            ) => {
                                                                                e.preventDefault();

                                                                                setActiveRoadPermitSection(
                                                                                    road
                                                                                );
                                                                                setActiveRoadPermitRow(
                                                                                    road.id
                                                                                );
                                                                            }}
                                                                            sx={{
                                                                                backgroundColor:
                                                                                    activeRoadPermitRow ===
                                                                                    road.id
                                                                                        ? theme
                                                                                              .palette
                                                                                              .primary
                                                                                              .main
                                                                                        : null,
                                                                            }}
                                                                        >
                                                                            <TableCell>
                                                                                <Typography
                                                                                    variant="body"
                                                                                    sx={{
                                                                                        color:
                                                                                            activeRoadPermitRow ===
                                                                                            road.id
                                                                                                ? theme
                                                                                                      .palette
                                                                                                      .primary
                                                                                                      .contrastText
                                                                                                : null,
                                                                                    }}
                                                                                >
                                                                                    {
                                                                                        road
                                                                                            ?.properties
                                                                                            ?.TIMBER_MARK
                                                                                    }
                                                                                </Typography>
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                <Typography
                                                                                    variant="body"
                                                                                    sx={{
                                                                                        color:
                                                                                            activeRoadPermitRow ===
                                                                                            road.id
                                                                                                ? theme
                                                                                                      .palette
                                                                                                      .primary
                                                                                                      .contrastText
                                                                                                : null,
                                                                                    }}
                                                                                >
                                                                                    {
                                                                                        road
                                                                                            ?.properties
                                                                                            ?.FOREST_FILE_ID
                                                                                    }
                                                                                </Typography>
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                <Typography
                                                                                    variant="body"
                                                                                    sx={{
                                                                                        color:
                                                                                            activeRoadPermitRow ===
                                                                                            road.id
                                                                                                ? theme
                                                                                                      .palette
                                                                                                      .primary
                                                                                                      .contrastText
                                                                                                : null,
                                                                                    }}
                                                                                >
                                                                                    {
                                                                                        road
                                                                                            ?.properties
                                                                                            ?.ROAD_SECTION_ID
                                                                                    }
                                                                                </Typography>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    )
                                                                )}
                                                            </TableBody>
                                                        </Table>
                                                    </PerfectScrollbar>
                                                </Box>
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            {activeRoadPermitSection ? (
                                                <Card>
                                                    <CardHeader title="Road Permit Section"></CardHeader>
                                                    <CardContent>
                                                        <Typography variant="h6">
                                                            Timbermark:{" "}
                                                            {
                                                                activeRoadPermitSection
                                                                    ?.properties
                                                                    ?.TIMBER_MARK
                                                            }
                                                        </Typography>
                                                        <Typography variant="h6">
                                                            Road Permit:{" "}
                                                            {
                                                                activeRoadPermitSection
                                                                    ?.properties
                                                                    ?.FOREST_FILE_ID
                                                            }
                                                        </Typography>
                                                        <Typography variant="h6">
                                                            Road Section:{" "}
                                                            {
                                                                activeRoadPermitSection
                                                                    ?.properties
                                                                    ?.ROAD_SECTION_ID
                                                            }
                                                        </Typography>
                                                        <Typography variant="h6">
                                                            Road Type:{" "}
                                                            {
                                                                activeRoadPermitSection
                                                                    ?.properties
                                                                    ?.FILE_TYPE_DESCRIPTION
                                                            }
                                                        </Typography>
                                                        <Typography variant="h6">
                                                            Length:{" "}
                                                            {
                                                                activeRoadPermitSection
                                                                    .properties
                                                                    .FEATURE_LENGTH_M
                                                            }
                                                            {"m "}
                                                        </Typography>
                                                        <Typography variant="h6">
                                                            District:{" "}
                                                            {
                                                                activeRoadPermitSection
                                                                    ?.properties
                                                                    .GEOGRAPHIC_DISTRICT_NAME
                                                            }
                                                        </Typography>
                                                    </CardContent>
                                                </Card>
                                            ) : (
                                                <Card>
                                                    <CardContent>
                                                        <Typography variant="h5">
                                                            Select a road permit
                                                            section to view
                                                            details
                                                        </Typography>
                                                    </CardContent>
                                                </Card>
                                            )}
                                        </Grid>
                                    </Grid>
                                )}
                            </TabPanel>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    );
};

export default Bidder;
