import { Tooltip, Chip } from "@material-ui/core";

const PreAdChip = (props) => {
    return (
        <Tooltip
            enterDelay={300}
            title="Posted as a pre-advertisement on BCBID"
        >
            <Chip
                color="primary"
                label="Pre-Ad"
                size="small"
                variant="outlined"
            />
        </Tooltip>
    );
};

export default PreAdChip;
