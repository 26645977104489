import React, { useCallback } from "react";
import { Grid } from "@material-ui/core";
import LogitHelmet from "src/components/shared/LogitHelmet";

import { useDropzone } from "react-dropzone";

const Loads = () => {
    const onDrop = useCallback((acceptedFiles) => {
        // Do something with the files
    }, []);
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
    });

    return (
        <>
            <LogitHelmet pageName={"Home"}></LogitHelmet>

            <Grid container backgroundColor="background.default">
                <Grid item>
                    <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        {isDragActive ? (
                            <p>Drop the files here ...</p>
                        ) : (
                            <p>
                                Drag 'n' drop some files here, or click to
                                select files
                            </p>
                        )}
                    </div>
                </Grid>
            </Grid>
        </>
    );
};

export default Loads;
