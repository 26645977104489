import Map from "src/components/timbersale/Map";
import {
    AttachMoney,
    Build,
    DescriptionOutlined,
    HourglassBottom,
    HourglassTop,
    LocationOnOutlined,
    Sell,
    SquareFoot,
    Timeline,
    LocalShipping,
    PictureAsPdf,
    OpenInBrowser,
    OpenInNew,
    PictureAsPdfOutlined,
    PictureAsPdfRounded,
    PictureAsPdfSharp,
    PictureAsPdfTwoTone,
} from "@material-ui/icons";
import {
    Card,
    CardContent,
    Chip,
    Container,
    Grid,
    Typography,
} from "@material-ui/core";
import LogitCard from "src/components/timbersale/LogitCard";
import SpeciesBreakdown from "src/components/timbersale//SpeciesBreakdown";
import {
    red,
    green,
    blue,
    orange,
    lightGreen,
    yellow,
} from "@material-ui/core/colors";
import {
    formatCurrency,
    formatDateForHBS,
    formatHBSWeightScaleSummariesLink,
    formatTimbermarkForHbs,
} from "src/utils/helpers";
import moment from "moment";
import {
    formatDate,
    formatDateAsString,
    GetGeojsonCutblocks,
    GetGeojsonCutblocksForFutrueSales,
    GetGeojsonForFfid,
} from "src/utils/data";
import { useEffect, useState } from "react";
import MapNew from "./MapNew";
const SaleTab = (props) => {
    const sale = props.sale;
    const [geo, setGeo] = useState(null);
    const dateOptions = {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
    };

    const xs = 12;
    const sm = 12;
    const lg = 4;
    const xl = 4;
    useEffect(() => {
        async function GetFutureSalesLocal() {
            console.log("called get future sales");
            let fSales = await GetGeojsonForFfid(sale.ffid);
            console.log(fSales);
            setGeo(fSales);
        }
        GetFutureSalesLocal();
    }, [sale]);

    return (
        <Container maxWidth={false}>
            <Grid container spacing={3}>
                {sale.is_cancelled ? (
                    <Grid item xs={12}>
                        {" "}
                        <Chip
                            color="error"
                            label="Cancelled"
                            size="large"
                            variant="filled"
                        />
                    </Grid>
                ) : null}

                <Grid item lg={lg} sm={sm} xl={xl} xs={xs}>
                    <LogitCard
                        Title="Timber Sale"
                        Primary={sale.ffid}
                        Chip={sale.category ? `Cat ${sale.category}` : null}
                        Icon={<DescriptionOutlined />}
                        IconBGColor={red[600]}
                    />
                </Grid>
                <Grid item lg={lg} sm={sm} xl={xl} xs={xs}>
                    <LogitCard
                        Title="Location"
                        Primary={sale.location}
                        Secondary={sale.region_name}
                        Icon={<LocationOnOutlined />}
                    />
                </Grid>

                <Grid item lg={lg} sm={sm} xl={xl} xs={xs}>
                    <LogitCard
                        Title="Volume"
                        Primary={
                            sale.net_volume
                                ? `Net: ${parseFloat(
                                      sale.net_volume
                                  ).toLocaleString("en-US")} m3`
                                : sale.total_volume
                                ? `Total: ${parseFloat(
                                      sale.total_volume
                                  ).toLocaleString("en-US")} m3`
                                : null
                        }
                        Secondary={
                            sale.net_area && sale.net_area > 0
                                ? sale.net_volume
                                    ? `Density: ${(
                                          parseFloat(sale.net_volume) /
                                          parseFloat(sale.net_area)
                                      )
                                          .toFixed(1)
                                          .toLocaleString("en-US")} m3/ha`
                                    : `Density: ${(
                                          parseFloat(sale.total_volume) /
                                          parseFloat(sale.net_area)
                                      )
                                          .toFixed(1)
                                          .toLocaleString("en-US")} m3/ha`
                                : "NA"
                        }
                        Icon={<Sell />}
                        IconBGColor={orange[700]}
                    />
                </Grid>
                <Grid
                    item
                    container
                    justifyContent="flex-start"
                    xs={12}
                    spacing={3}
                >
                    <Grid item lg={9} md={6} xl={9} xs={12} minHeight={400}>
                        <Map
                            geo={geo}
                            geoTextMapLayer={{
                                "text-field": [
                                    "concat",
                                    ["get", "CUT_BLOCK_FOREST_FILE_ID"],
                                    "\nBlock: ",
                                    ["get", "CUT_BLOCK_ID"],
                                ],
                                "text-font": ["Open Sans Regular"],
                                "text-size": 16,
                                "text-anchor": "left",
                                "text-offset": [1, 0],
                                "symbol-avoid-edges": true,
                            }}
                        />
                    </Grid>
                    <Grid item lg={3} md={6} xl={3} xs={12}>
                        <SpeciesBreakdown sale={sale} />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Card color="primary">
                        <CardContent>
                            <Grid item xs={12}>
                                <Typography
                                    // sx={{ fontSize: 14 }}
                                    variant="h6"
                                    color="textSecondary"
                                    gutterBottom
                                >
                                    Links
                                </Typography>
                            </Grid>

                            <Chip
                                label="Weight Scale Summaries"
                                sx={{ m: 1 }}
                                avatar={
                                    <PictureAsPdf
                                        sx={{
                                            fill: "#fff",
                                        }}
                                    />
                                }
                                color="accent"
                                onClick={(e) => {
                                    let from = formatDateForHBS(
                                        new Date(Date.parse(sale.closed_time))
                                    );
                                    let to = formatDateForHBS(new Date());

                                    let mark = formatTimbermarkForHbs(
                                        sale.ffid
                                    );
                                    window.open(
                                        formatHBSWeightScaleSummariesLink(
                                            from,
                                            to,
                                            mark
                                        )
                                    );
                                }}
                            ></Chip>
                            <Chip
                                label="Cut to Cruise Report"
                                sx={{ m: 1 }}
                                avatar={
                                    <PictureAsPdf
                                        sx={{
                                            fill: "#fff",
                                        }}
                                    />
                                }
                                color="accent"
                                onClick={(e) => {
                                    window.open(
                                        `https://a100.gov.bc.ca/pub/hbs/opq/CuttoCruiseReport.do?txtTimberMark=&radLicenceMark=licence&txtLicence=${sale.ffid}&txtClientNo=&txtClientLoc=&chcScaleDateFromDate=&actionType=Print+Report`
                                    );
                                }}
                            />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item lg={lg} sm={sm} xl={xl} xs={xs}>
                    <LogitCard
                        Title="Stumpage"
                        Primary={
                            sale.stumpage_rate_per_cubic_m
                                ? `${formatCurrency(
                                      sale.stumpage_rate_per_cubic_m
                                  )} m3`
                                : null
                        }
                        Secondary={
                            sale.stumpage_rate_total
                                ? `Total: ${formatCurrency(
                                      sale.stumpage_rate_total
                                  )}`
                                : null
                        }
                        Chip={sale.stumpage_type_pretty}
                        Icon={<AttachMoney />}
                        IconBGColor={green[600]}
                    />
                </Grid>

                <Grid item lg={lg} sm={sm} xl={xl} xs={xs}>
                    <LogitCard
                        Title="Silviculture System"
                        Primary={
                            sale.silviculture_system
                                ? sale.silviculture_system
                                : "Unknown"
                        }
                        Icon={<Build />}
                    />
                    {/* <Roads sale={sale} /> */}
                </Grid>

                <Grid item lg={lg} sm={sm} xl={xl} xs={xs}>
                    <LogitCard
                        Title="Net Area"
                        Primary={sale.net_area ? `${sale.net_area} ha` : ""}
                        Icon={<SquareFoot />}
                        IconBGColor={orange[400]}
                    />
                </Grid>
                <Grid item lg={lg} sm={sm} xl={xl} xs={xs}>
                    <LogitCard
                        Title="License Term"
                        Primary={
                            sale.licence_term
                                ? `${sale.licence_term} ${
                                      sale.license_term_measurement
                                          ? sale.license_term_measurement
                                          : "months"
                                  }`
                                : "NA"
                        }
                        Icon={<Timeline />}
                        IconBGColor={lightGreen[300]}
                    />
                </Grid>
                <Grid item lg={lg} sm={sm} xl={xl} xs={xs}>
                    <LogitCard
                        Title="Appraised Cycle Time"
                        Primary={
                            sale.appraised_cycle_time
                                ? `${sale.appraised_cycle_time} hours `
                                : "Unknown"
                        }
                        Icon={<LocalShipping />}
                        IconBGColor={yellow[700]}
                    />
                </Grid>
                <Grid item lg={lg} sm={sm} xl={xl} xs={xs}>
                    <LogitCard
                        Title={
                            formatDate(sale.closed_time) > Date.now()
                                ? "Sale Closes"
                                : "Sale Closed"
                        }
                        Primary={formatDateAsString(sale.closed_time)}
                        Secondary={`Opened on ${formatDateAsString(
                            sale.open_date,
                            "MMM Do, YYYY"
                        )}`}
                        Icon={
                            formatDate(sale.closed_time) > moment.now() ? (
                                <HourglassTop />
                            ) : (
                                <HourglassBottom />
                            )
                        }
                        IconBGColor={blue[700]}
                    />
                </Grid>
            </Grid>
        </Container>
    );
};

export default SaleTab;
