import { combineReducers } from "redux";
import { TIMBER_SALE_LIST_PAGE_LIMIT } from "src/Config";

const defaultState = {
    user_subscribed: false,
    user: {},
    product_id: "",
    is_trial: false,
    open_sales_current_page: 0,
    closed_sales_current_page: 0,
    open_sales_current_page_limit: TIMBER_SALE_LIST_PAGE_LIMIT,
    closed_sales_current_page_limit: TIMBER_SALE_LIST_PAGE_LIMIT,
    sales: [],
    regions: [],
    sales_get_time: undefined,
    last_sale_url: undefined,
    geojson: {},
    filters: {},
    database: {},
};

function SubscriptionReducer(state = defaultState, action) {
    switch (action.type) {
        case "Set_Is_User_Subscribed":
            state.user_subscribed = action.payload;
            return state;
        case "Set_User":
            state.user = action.payload;
            return state;
        default:
            return state;
    }
}

function SignupReducer(state = defaultState, action) {
    switch (action.type) {
        case "Set_Product_Id":
            state.product_id = action.payload;
            return state;
        case "Set_Is_Trial":
            state.is_trial = action.payload;
            return state;
        default:
            return state;
    }
}

function SalesReducer(state = defaultState, action) {
    switch (action.type) {
        case "Set_Open_Sales_Current_Page":
            state.open_sales_current_page = action.payload;
            return state;
        case "Set_Closed_Sales_Current_Page":
            state.closed_sales_current_page = action.payload;
            return state;
        case "Set_Open_Sales_Current_Page_Limit":
            state.open_sales_current_page_limit = action.payload;
            return state;
        case "Set_Closed_Sales_Current_Page_Limit":
            state.closed_sales_current_page_limit = action.payload;
            return state;
        case "Set_Sales":
            state.sales = action.payload;
            return state;
        case "Set_Open_Sales":
            state.openSales = action.payload;
            return state;
        case "Set_Closed_Sales":
            state.closedSales = action.payload;
            return state;
        case "Set_Regions":
            state.regions = action.payload;
            return state;
        case "Set_Sales_Get_Time":
            state.sales_get_time = action.payload;
            return state;
        case "Set_Last_Sale_Url":
            state.last_sale_url = action.payload;
            return state;
        default:
            return state;
    }
}

function BidReducer(state = defaultState, action) {
    switch (action.type) {
        case "Set_Bidder_History":
            state.bidder_history = action.payload;
            return state;

        case "Set_Sale_Bids":
            state.sale_bids = action.payload;
            return state;

        default:
            return state;
    }
}

function FilterReducer(state = defaultState, action) {
    switch (action.type) {
        case "Set_Region_Filter":
            state.regions = action.payload;
            return state;
        case "Set_Sale_State_Filter":
            state.sale_state = action.payload;
            return state;
        default:
            return state;
    }
}

function GeojsonReducer(state = defaultState, action) {
    switch (action.type) {
        case "Set_Sale_Geojson":
            state.geojson = action.payload;
            return state;

        default:
            return state;
    }
}

function DatabaseReducer(state = defaultState, action) {
    switch (action.type) {
        case "Set_Is_Querying":
            state.isQuerying = action.payload;
            return state;

        default:
            return state;
    }
}

const rootReducer = combineReducers({
    subscription: SubscriptionReducer,
    signup: SignupReducer,
    sales: SalesReducer,
    bid: BidReducer,
    geojson: GeojsonReducer,
    filter: FilterReducer,
    db: DatabaseReducer,
});

export default rootReducer;
