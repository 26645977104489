import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, Tabs, Tab, useTheme } from "@material-ui/core";
import LogitHelmet from "src/components/shared/LogitHelmet";
import { a11yProps, TabPanel } from "src/components/shared/LogitTabs";

import PdfContainer from "src/components/shared/PdfContainer";

import { LOGIT_LEGAL_DOCUMENTS } from "src/Constants";
import AboutContent from "src/components/about/AboutContent";
const AboutUs = (props) => {
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const theme = useTheme();
    return (
        <>
            <LogitHelmet pageName={"Legal"}></LogitHelmet>

            <Box
                elevation={3}
                pt={5}
                sx={{
                    borderTop: 1,
                    borderBottom: 1,
                    borderColor: "divider",
                    elevation: 2,
                    backgroundColor: theme.palette.secondary.main,
                }}
            >
                <Grid
                    container
                    backgroundColor="paper.default"
                    display="flex"
                    justifyContent="center"
                    p={10}
                >
                    <Grid item xs={12} md={7} pb={3}>
                        <Typography
                            variant="h1"
                            color={theme.palette.white.main}
                            xs={12}
                            elevation={10}
                        >
                            About Us
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <Typography
                            variant="body1"
                            color={theme.palette.white.main}
                        ></Typography>
                    </Grid>
                </Grid>
                {/* <Tabs
                    value={value}
                    onChange={handleChange}
                    backgroundColor="paper.default"
                    aria-label="Service Tabs"
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                    style={{
                        color: "primary",
                        backgroundColor: theme.palette.background.default,
                    }}
                >
                    <Tab label="Test" {...a11yProps(0)} />
                    <Tab label="Test 2" {...a11yProps(1)} />
                </Tabs> */}
            </Box>

            <Box
                sx={{
                    backgroundColor: "paper.default",
                    minHeight: "100%",
                    py: 3,
                    p: { md: 5, lg: 10, xl: 15 },
                }}
            >
                <AboutContent />
                {/* <TabPanel value={value} index={0}>
                    test
                </TabPanel>
                <TabPanel value={value} index={1}>
                    test 2
                </TabPanel> */}
            </Box>
        </>
    );
};

export default AboutUs;
