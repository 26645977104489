import { center } from "@turf/turf";
import { BCDATA_API_ENDPOINT, BCDATA_API_ENDPOINT_V2 } from "src/Config";

import moment from "moment";
import {
    Timestamp,
    addDoc,
    collection,
    deleteDoc,
    doc,
    getDoc,
    getDocs,
    getFirestore,
    onSnapshot,
    orderBy,
    query,
    setDoc,
    updateDoc,
    where,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";

import {
    USER_COLLECTION,
    TIMBERSALE_COLLECTION,
    TIMBERSALE_DOCUMENTS,
    BIDDER_COLLECTION,
} from "src/Constants";
import { store } from "src/redux/Store";
import {
    CacheSaleRegions,
    GetClosedSalesFromCache,
    GetOpenSalesFromCache,
    GetSaleRegionsFromCache,
    SetCutBlocksInCache,
    GetUserFromCache,
    CacheOpenSales,
    CacheClosedSales,
    GetSalesFromCacheByIds,
} from "./cache";

async function GetSalesFromSaleIds(saleIds) {
    let sales = await GetAllSales();
    let filteredSales = sales.filter((sale) => saleIds.includes(sale.ffid));
    return filteredSales;
}

export async function GetRegionsForSales(sales) {
    let regions = [
        ...new Set(sales.map((obj) => obj.region_name.replace("-", " "))),
    ];
    return regions;
}

// function that will retrieve the bid and sale information for a sale
export async function GetSaleBidsAndSaleInformationForBidder(
    bidderClientNumber
) {
    let bidderHistory = await GetBidderHistory(bidderClientNumber);

    let mappedBidAndSale = {};

    for (const obj of Object.values(bidderHistory)) {
        let s = await GetSale(obj.ffid);

        if (s === null || s?.ffid === undefined) {
            continue;
        }
        mappedBidAndSale[obj.ffid] = { bid: obj, sale: s };
    }
    return mappedBidAndSale;
}

async function GetOpenSales() {
    let sales = await GetOpenSalesFromCache();
    if (sales === undefined) {
        const db = getFirestore();
        let currentDate = new Date();

        const loadTime = Timestamp.fromMillis(currentDate - 7 * 60 * 60 * 1000);
        console.debug("querying db for open sales", loadTime);
        let openSalesQuery = query(
            collection(db, TIMBERSALE_COLLECTION),
            where("closed_datetime", ">=", loadTime),
            orderBy("closed_datetime")
        );
        sales = [];

        await getDocs(openSalesQuery).then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                sales.push(doc.data());
            });
        });

        await CacheOpenSales(sales);
    }
    if (sales === undefined) {
        return null;
    }
    return sales;
}

async function GetAllSales() {
    const openSales = await GetOpenSales();
    const closedSales = await GetClosedSales();

    return [...openSales, ...closedSales];
}

async function GetClosedSales() {
    let sales = await GetClosedSalesFromCache();

    if (sales === undefined) {
        const db = getFirestore();
        let currentDate = new Date();

        const loadTime = Timestamp.fromMillis(currentDate - 7 * 60 * 60 * 1000);

        console.debug("querying db for closed sales");
        let openSalesQuery = query(
            collection(db, TIMBERSALE_COLLECTION),
            where("closed_datetime", "<", loadTime),
            orderBy("closed_datetime", "desc")
        );
        sales = [];

        await getDocs(openSalesQuery).then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                sales.push(doc.data());
            });
        });

        await CacheClosedSales(sales);
    }
    if (sales === undefined) {
        return null;
    }

    return sales;
}

async function GetSaleFromCache(saleId) {
    let sales = await GetAllSales();
    let s = sales.find((sale) => sale.ffid === saleId);

    if (s) {
        return s;
    }

    return undefined;
}

async function GetSale(saleId) {
    try {
        let sale = await GetSaleFromCache(saleId);
        if (sale === undefined) {
            const db = getFirestore();
            let docRef = doc(db, TIMBERSALE_COLLECTION, saleId);
            sale = await getDoc(docRef);
            return sale.data();
        } else {
            return sale;
        }
    } catch (error) {
        return null;
    }
}

export async function GetFutureSales() {
    const db = getFirestore();
    let collectionRef = collection(db, "bcts-future-sales");
    let fSales = await getDocs(collectionRef);
    return fSales.docs.map((sale) => ({ id: sale.id, ...sale.data() }));
}

export async function GetSaleRegions() {
    let regions = await GetSaleRegionsFromCache();

    if (
        regions === undefined ||
        (Array.isArray(regions) && regions.length === 0)
    ) {
        regions = await GetOperationAreas();
        regions.sort(function (a, b) {
            var textA = a.pretty_name.toUpperCase();
            var textB = b.pretty_name.toUpperCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
        await CacheSaleRegions(regions);
    }
    return regions;
}

async function CreateUser(user) {
    try {
        const db = getFirestore();
        let docRef = doc(db, USER_COLLECTION, user.uid);
        return await setDoc(docRef, {
            id: user.uid,
            name: user.name,
            email: user.email,
            organization: user.organization ? user.organization : "",
            phone_number: user.phone_number ? user.phone_number : "",
            date_joined: new Date(),
            trial_started: new Date(),
        });
    } catch (err) {
        return null;
    }
}

async function UpdateUser(user) {
    try {
        const db = getFirestore();

        let docRef = doc(db, USER_COLLECTION, getAuth().currentUser.uid);
        let newUser = {
            id: user.id,
            name: user.name,
            email: user.email,
            organization: user.organization ? user.organization : "",
            phone_number: user.phone_number ? user.phone_number : "",
            updated_at: new Date(),
        };

        return await updateDoc(docRef, newUser);
    } catch (err) {
        return null;
    }
}

async function GetUser(docId) {
    try {
        let user = await GetUserFromCache();
        if (user === undefined) {
            const db = getFirestore();
            user = await getDoc(doc(db, USER_COLLECTION, docId));

            store.dispatch({
                type: "Set_User",
                payload: user.data(),
            });

            return user.data();
        }
        return user;
    } catch (error) {
        return null;
    }
}

async function GetCutBlocks(saleId) {
    const response = await fetch(BCDATA_API_ENDPOINT, {
        method: "POST",
        mode: "cors",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Request-Headers": "*",
        },
        body: JSON.stringify({
            target: "fta",
            query: saleId,
        }),
    });
    const json = response.json();

    await SetCutBlocksInCache(saleId, json);

    return json;
}

function GetGeojsonCenter(geojson) {
    if (geojson === undefined || !geojson) {
        return;
    }
    return center(geojson);
}

export async function GetGeojsonCutblocksForFutrueSales(region) {
    if (query === "") return;
    let url = `${BCDATA_API_ENDPOINT_V2}/bcdw/bcts_sales_schedule?query=DIVI_SHORT_CODE='${region}'`;
    console.log(url);
    const response = await fetch(url, {
        method: "GET",
        mode: "cors",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Request-Headers": "*",
        },
    })
        .then((response) => {
            console.log(response);
            if (response.status === 200) {
                return response.json();
            } else {
                return null;
            }
        })
        .catch((error) => {
            console.error("Error fetching cutblocks", error);
            return null;
        });

    if (response !== null) {
        return response;
    }
    return null;
}

export async function GetGeojsonForFfid(ffid) {
    if (ffid === "") return;
    let url = `${BCDATA_API_ENDPOINT_V2}/bcdw/fta?query=CUT_BLOCK_FOREST_FILE_ID='${ffid}'`;

    const response = await fetch(url, {
        method: "GET",
        mode: "cors",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Request-Headers": "*",
        },
    })
        .then((response) => {
            if (response.status === 200) {
                return response.json();
            } else {
                return null;
            }
        })
        .catch((error) => {
            console.error("Error fetching cutblocks", error);
            return null;
        });

    if (response !== null) {
        return response;
    }
    return null;
}

export async function GetGeojsonCutblocks(query) {
    if (query === "") return;
    let url = `${BCDATA_API_ENDPOINT_V2}/bcdw/fta?query=${query}`;

    const response = await fetch(url, {
        method: "GET",
        mode: "cors",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Request-Headers": "*",
        },
    })
        .then((response) => {
            if (response.status === 200) {
                return response.json();
            } else {
                return null;
            }
        })
        .catch((error) => {
            console.error("Error fetching cutblocks", error);
            return null;
        });

    if (response !== null) {
        return response;
    }
    return null;
}

export function CreateGeojsonCutblockQueryForFfids(ids) {
    let query = "";
    ids.forEach((id) => {
        if (id.includes(" ")) {
            id?.split(" ").forEach((subId) => {
                query += `CUT_BLOCK_FOREST_FILE_ID='${subId}' OR `;
            });
        }
        query += `CUT_BLOCK_FOREST_FILE_ID='${id}' OR `;
    });
    return query.substring(0, query.length - 4);
}

async function GetSaleDocument(ffid) {
    const db = getFirestore();

    var saleDocuments = await getDocs(
        collection(db, TIMBERSALE_DOCUMENTS, ffid, "attachments")
    );
    return saleDocuments.docs
        .map((doc) => {
            return { id: doc.id, ...doc.data() };
        })
        .filter((document) => !document.id.toLowerCase().includes("zip"));
}

async function GetSaleBids(ffid) {
    const db = getFirestore();
    return await getDocs(collection(db, TIMBERSALE_COLLECTION, ffid, "bids"))
        .then((querySnapshot) => {
            return querySnapshot.docs.map((doc) => doc.data());
        })
        .catch((error) => {
            return null;
        });
}

// Need to cache this
async function GetBidderHistory(clientId) {
    const db = getFirestore();

    let bidderHistory = await getDocs(
        collection(db, BIDDER_COLLECTION, clientId, "bids")
    )
        .then((querySnapshot) => {
            if (querySnapshot.empty) {
                return {};
            }
            return querySnapshot.docs.map((doc) => {
                let ffid = "";
                if (doc.id.length > 5) {
                    ffid = doc.id.substring(0, 6);
                } else {
                    ffid = doc.id;
                }
                return { ffid: ffid, uid: doc.id, ...doc.data() };
            });
        })
        .catch((error) => {
            return null;
        });
    return bidderHistory;
}

async function GetBidder(bidId) {
    const db = getFirestore();
    var bidder = await getDoc(doc(db, "bcts-bids", bidId));
    return bidder.data();
}

async function GetBidders() {
    const db = getFirestore();
    const q = query(collection(db, "bcts-bidders"), orderBy("client_name"));
    var bidders = [];
    await getDocs(q).then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            bidders.push(doc.data());
        });
    });

    return bidders;
}

export async function logUserSaleVisit(userId, data) {
    await logUserActivity(userId, "saleVisits", data);
}

export async function logUserBidderVisit(userId, data) {
    await logUserActivity(userId, "bidderVisits", data);
}

async function logUserActivity(userId, subCollection, data) {
    const db = getFirestore();
    const docRef = collection(
        db,
        "userActivity",
        getAuth().currentUser.uid,
        subCollection
    );

    await addDoc(docRef, data);
}

async function GetBidderById(bidderId) {
    const db = getFirestore();
    var bidder = await getDoc(doc(db, "bcts-bidders", bidderId));
    return bidder.data();
}
async function GetOperationAreas() {
    const db = getFirestore();
    var operationAreas = await getDocs(collection(db, "regions"));
    return operationAreas.docs.map((doc) => doc.data());
}

async function CreateSubscriptionCheckoutSession(
    priceId,
    success_url_extension
) {
    const db = getFirestore();
    let docRef = await addDoc(
        collection(
            db,
            "customers",
            getAuth().currentUser.uid,
            "checkout_sessions"
        ),
        {
            automatic_tax: true, // Automatically calculate tax based on the customer's address
            tax_id_collection: true, // Collect the customer's tax ID
            trial_from_plan: true,
            allow_promotion_codes: true,
            price: priceId,
            success_url: `${window.location.origin}${success_url_extension}`,
            cancel_url: window.location.origin,
        }
    );
    const unsubscribe = onSnapshot(docRef, (doc) => {
        const { error, url } = doc.data();
        if (error) {
            // Show an error to your customer and
            // inspect your Cloud Function logs in the Firebase console.
            alert(`An error occured: ${error.message}`);
        }
        if (url) {
            // We have a Stripe Checkout URL, let's redirect.

            window.location.assign(url);
        }
    });
}

async function GetSubscription(docId) {
    try {
        const db = getFirestore();
        let docSnap = await getDoc(doc(db, "subscriptions", docId));
        return docSnap.data();
    } catch (error) {
        return null;
    }
}

async function DeleteSubscription(docId) {
    try {
        const db = getFirestore();
        deleteDoc(doc(db, "subscriptions", docId));
        return true;
    } catch (error) {
        return false;
    }
}

function formatDate(date) {
    let parts = date?.split("-");
    let x = null;
    if (parts && parts.length < 3) {
        x = moment(date, "YYYY/MM/DD HH:mm");
    } else {
        x = moment(date, "YYYY-MM-DD h:mm:ss A");
    }

    return x;
}

function formatDateAsString(date, format = "dddd MMM Do YYYY, h:mma") {
    return formatDate(date).format(format);
}

function SortObjectByKeys(obj) {
    const keys = Object.keys(obj);

    // Step 2: Sort the keys
    keys.sort();

    // Step 3: Create a new object with sorted keys
    const sortedObj = {};
    for (let key of keys) {
        sortedObj[key] = obj[key];
    }
    return sortedObj;
}

function SortObjectByValue(obj) {
    // Convert the object into an array of key-value pairs
    const entries = Object.entries(obj);

    // Sort the array based on the values (second element of each pair)
    entries.sort((a, b) => b[1] - a[1]);

    // Convert the sorted array back into an object
    const sortedObj = {};
    for (const [key, value] of entries) {
        sortedObj[key] = value;
    }

    return sortedObj;
}

export {
    GetAllSales,
    GetSaleBids,
    GetSalesFromSaleIds,
    GetOpenSales,
    GetClosedSales,
    GetSale,
    GetBidderHistory,
    GetBidders,
    GetBidderById,
    GetCutBlocks,
    GetSaleDocument,
    GetOperationAreas,
    GetGeojsonCenter,
    UpdateUser,
    CreateUser,
    GetUser,
    GetBidder,
    CreateSubscriptionCheckoutSession,
    GetSubscription,
    DeleteSubscription,
    formatDate,
    formatDateAsString,
    SortObjectByKeys,
    SortObjectByValue,
};
