import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import { Typography, Box } from "@material-ui/core";
import { store } from "src/redux/Store";

const SaleStateSwitch = (props) => {
    const [checked, setChecked] = React.useState(true);
    const handleChange = (event) => {
        setChecked(event.target.checked);
        props.setSaleStateFilter(event.target.checked);
        store.dispatch({
            type: "Set_Sale_State_Filter",
            payload: event.target.checked,
        });
    };

    useEffect(() => {
        props.handleSearchChange();
    }, [checked]);

    useEffect(() => {
        let saleState = store.getState().filter.sale_state;

        if (saleState !== undefined) {
            setChecked(saleState);
            props.setSaleStateFilter(saleState);
        }
    }, []);

    return (
        <Box display="flex" alignItems="center">
            <Typography>Closed</Typography>
            <Switch
                color="accent"
                checked={checked}
                disabled={props.isLoading}
                onChange={handleChange}
                inputProps={{ "aria-label": "controlled" }}
            />
            <Typography>Open for Bid</Typography>
        </Box>
    );
};

export default SaleStateSwitch;
