import React from "react";
import { Box, Container, TextField, Typography } from "@material-ui/core";
import PasswordTextField from "src/components/shared/PasswordTextField";

const UserRegistration = ({
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    touched,
    values,
}) => {
    return (
        <>
            <Box
                sx={{
                    backgroundColor: "paper.default",
                    display: "flex",
                    flexDirection: "row",
                    height: "100%",
                    justifyContent: "center",
                }}
            >
                <Container maxWidth="sm">
                    <Box>
                        <Typography color="secondary" variant="h4">
                            Create your account
                        </Typography>
                    </Box>
                    <TextField
                        error={Boolean(touched.name && errors.name)}
                        fullWidth
                        helperText={touched.name && errors.name}
                        label="Name"
                        margin="normal"
                        name="name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.name}
                        variant="outlined"
                    />
                    <TextField
                        fullWidth
                        helperText={touched.organization && errors.organization}
                        label="Organization (Optional)"
                        margin="normal"
                        name="organization"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.organization}
                        variant="outlined"
                    />
                    <TextField
                        error={Boolean(
                            touched.phonenumber && errors.phonenumber
                        )}
                        fullWidth
                        helperText={touched.phonenumber && errors.phonenumber}
                        label="Phone Number (Optional)"
                        margin="normal"
                        name="phonenumber"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.phonenumber}
                        variant="outlined"
                    />
                    <TextField
                        error={Boolean(touched.email && errors.email)}
                        fullWidth
                        helperText={touched.email && errors.email}
                        label="Email Address"
                        margin="normal"
                        name="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="email"
                        value={values.email}
                        variant="outlined"
                    />
                    <PasswordTextField
                        errors={errors}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        handleSubmit={handleSubmit}
                        isSubmitting={isSubmitting}
                        touched={touched}
                        value={values.password}
                        label="Password"
                        name="password"
                    />
                    <PasswordTextField
                        errors={errors}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        handleSubmit={handleSubmit}
                        isSubmitting={isSubmitting}
                        touched={touched}
                        value={values.passwordConfirmation}
                        label="Confirm Password"
                        name="passwordConfirmation"
                    />
                </Container>
            </Box>
        </>
    );
};

export default UserRegistration;
