export const LANDING_PAGE = "/app/sales";
export const SALE_PAGE_BASE = "/app/sale/";
export const LOGITNOW_BASE_PRODUCT =
    process.env.REACT_APP_LOGITNOW_BASE_PRODUCT;

export const STRIPE_PAYMENT_PORTAL_LINK =
    process.env.REACT_APP_STRIPE_PAYMENT_PORTAL_LINK;
// Collections
export const USER_COLLECTION = "users";
export const TIMBERSALE_COLLECTION = "bcts-sales";
export const TIMBERSALE_DOCUMENTS = process.env.REACT_APP_TIMBERSALE_DOCUMENTS;

export const BIDDER_COLLECTION = "bcts-bidders";

export const LOGIT_LEGAL_DOCUMENTS = {
    privacy: `https://storage.cloud.google.com/${process.env.REACT_APP_LOGIT_LEGAL_DOCUMENTS}/privacy-policy.pdf`,
    terms: `https://storage.cloud.google.com/${process.env.REACT_APP_LOGIT_LEGAL_DOCUMENTS}/terms-and-conditions.pdf`,
};

export const MAILCHIMP_CONTACT_US =
    "https://us20.list-manage.com/contact-form?u=a2dab33d5fbdb1ee039959c06&form_id=95fa048738208a59932f03567e5b539d";
