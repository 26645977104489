import React from "react";
import LogitHelmet from "./shared/LogitHelmet";
import { Box, Container, Avatar } from "@material-ui/core";

const Loading = () => {
    return (
        <>
            <LogitHelmet pageName={"Loading"} />

            <Box
                sx={{
                    backgroundColor: "background.default",
                    display: "flex",
                    flexDirection: "column",
                    height: "20%",
                    justifyContent: "center",
                }}
            >
                <Container maxWidth="sm" style={{ justifyContent: "center" }}>
                    <Avatar
                        // src='/static/images/assets/SplashLogo.png'
                        sx={{
                            height: 50,
                            width: 50,
                        }}
                    />
                </Container>
            </Box>
        </>
    );
};

export default Loading;
