import "react-perfect-scrollbar/dist/css/styles.css";
import {
    useNavigate,
    useRoutes,
    useMatch,
    useLocation,
} from "react-router-dom";
import { ThemeProvider } from "@material-ui/core";
import GlobalStyles from "src/components/GlobalStyles";
import { useEffect, useState, useLayoutEffect } from "react";
import "src/mixins/chartjs";
import theme from "src/theme";
import publicRoutes from "src/publicRoutes";
import privateRoutes from "./privateRoutes";

import { getAuth } from "firebase/auth";
import { getDocs, getFirestore, collection } from "firebase/firestore";

import paymentRoutes from "./paymentRoutes";
import { store } from "src/redux/Store";
import { LANDING_PAGE, SALE_PAGE_BASE } from "./Constants";
import { IsOnFreeTrial } from "./utils/auth";
import { GetAllSales } from "./utils/data";

const App = () => {
    const publicRouting = useRoutes(publicRoutes);
    const privateRouting = useRoutes(privateRoutes);
    const paymentRouting = useRoutes(paymentRoutes);
    const [, setUserId] = useState(null);
    const [userSignedIn, setUserSignedIn] = useState(false);
    const [userSubscribed, setUserSubscribed] = useState(false);
    const navigate = useNavigate();
    const match = useMatch(`${SALE_PAGE_BASE}:saleId`);
    const [saleId, setSaleId] = useState(
        match ? (match.params ? match.params.saleId : null) : null
    );
    const db = getFirestore();
    useEffect(() => {
        async function preloadAllSales() {
            await GetAllSales();
        }
        preloadAllSales();
    }, []);
    useEffect(() => {
        getAuth().onAuthStateChanged((user) => {
            if (user) {
                setUserSignedIn(true);
                setUserId(user.uid);

                // check if on free trial
                IsOnFreeTrial(user).then((trialing) => {
                    if (trialing) {
                        setUserSubscribed(true);
                        store.dispatch({
                            type: "Set_Is_User_Subscribed",
                            payload: true,
                        });
                        store.dispatch({
                            type: "Set_Last_Sale_Url",
                            payload: saleId,
                        });

                        saleId == null
                            ? navigate(LANDING_PAGE, { replace: true })
                            : navigate(`/app/sales/${saleId}`, {
                                  replace: true,
                              });
                    } else {
                        // check if user is subscribed

                        getDocs(
                            collection(
                                db,
                                "customers",
                                getAuth().currentUser.uid,
                                "subscriptions"
                            )
                        ).then(function (querySnapshot) {
                            if (querySnapshot.empty) {
                                setUserSubscribed(false);

                                navigate("/payment", { replace: true });
                            }
                            querySnapshot.forEach(async function (doc) {
                                if (
                                    doc.data()["status"] === "active" ||
                                    doc.data()["status"] === "trialing"
                                ) {
                                    setUserSubscribed(true);
                                    store.dispatch({
                                        type: "Set_Is_User_Subscribed",
                                        payload: true,
                                    });
                                    store.dispatch({
                                        type: "Set_Last_Sale_Url",
                                        payload: saleId,
                                    });
                                    saleId == null
                                        ? navigate(LANDING_PAGE, {
                                              replace: true,
                                          })
                                        : navigate(
                                              `${SALE_PAGE_BASE}${saleId}`,
                                              {
                                                  replace: true,
                                              }
                                          );
                                } else {
                                    setUserSubscribed(false);

                                    navigate("/payment", {
                                        replace: true,
                                        state: { saleId: saleId },
                                    });
                                }
                            });
                        });
                    }
                });
            } else {
                setUserSignedIn(false);
                saleId == null
                    ? navigate("/home", { replace: true })
                    : navigate("login");
                setSaleId(null);
            }
        });
    }, []);

    // eslint-disable-next-line no-unused-vars
    const unsubscribe = store.subscribe(() => {
        if (!store.getState().subscription.user_subscribed) {
            if (store.getState().signup.product_id === "") {
                setUserSubscribed(false);
                navigate("/home", { replace: true });
            }
        } else {
            getDocs(
                collection(
                    db,
                    "customers",
                    getAuth().currentUser.uid,
                    "subscriptions"
                )
            )
                .then(function (querySnapshot) {
                    querySnapshot.forEach(async function (doc) {
                        if (
                            doc.data()["status"] === "trialing" ||
                            doc.data()["status"] === "active"
                        ) {
                            setUserSubscribed(true);
                        }
                    });
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    });

    const ScrollToTopWrapper = ({ children }) => {
        const location = useLocation();
        useLayoutEffect(() => {
            document.documentElement.scrollTo(0, 0);
        }, [location.pathname]);
        return children;
    };

    return (
        <ThemeProvider theme={theme}>
            <GlobalStyles />
            <ScrollToTopWrapper>
                {userSignedIn
                    ? userSubscribed
                        ? privateRouting
                        : paymentRouting
                    : publicRouting}
            </ScrollToTopWrapper>
        </ThemeProvider>
    );
};

export default App;
