// eslint-disable-next-line import/no-anonymous-default-export
export default {
    email: "",
    firstName: "",
    lastName: "",
    password: "",
    passwordConfirmation: "",
    policy: false,
    companyName: "",
    companyAddress: "",
    companyPrimaryOperationArea: "",
    companySecondaryOperationAreas: [],
};
