import { Pie } from "react-chartjs-2";
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableHead,
    Typography,
    colors,
    useTheme,
} from "@material-ui/core";
import { TableRow } from "@mui/material";
import ScrollBar from "react-perfect-scrollbar";

const SpeciesBreakdown = (props) => {
    const theme = useTheme();
    const backgroundColors = [
        theme.palette.primary.main,
        colors.red[600],
        colors.cyan[600],
        colors.orange[600],
        colors.indigo[500],
        colors.amber[600],
        colors.blue[500],
        colors.yellow[500],
        colors.deepPurple[500],
    ];

    const options = {
        animation: false,
        cutoutPercentage: 70,
        layout: { padding: 1 },
        legend: {
            display: false,
        },

        maintainAspectRatio: false,
        responsive: true,
        tooltips: {
            backgroundColor: theme.palette.background.paper,
            bodyFontColor: theme.palette.text.secondary,
            borderColor: theme.palette.divider,
            borderWidth: 1,
            enabled: true,
            footerFontColor: theme.palette.text.secondary,
            intersect: false,
            mode: "index",
            titleFontColor: theme.palette.text.primary,
        },
    };

    const species = [];

    let idx = 0;

    const sortedSpecies = Object.entries(props.sale.species).sort(
        (a, b) => parseFloat(b[1]) - parseFloat(a[1])
    );

    for (const [key, value] of sortedSpecies) {
        species.push({
            name: key,
            value: parseFloat(value),
            percent: (
                (parseFloat(value) /
                    (props.sale.total_volume !== ""
                        ? parseFloat(props.sale.total_volume)
                        : parseFloat(props.sale.net_volume))) *
                100
            ).toFixed(1),
            color: backgroundColors[idx],
        });
        idx += 1;
    }
    species.sort((a, b) => b.value - a.value);
    const data = {
        datasets: [
            {
                data: species.map(({ percent }) => percent),
                backgroundColor: species.map(({ color }) => color),
                borderWidth: 4,
                borderColor: colors.common.white,
                hoverBorderColor: colors.common.white,
                circumference: 360,
            },
        ],
        labels: species.map(({ name }) => name),
    };

    return (
        <Card {...props} style={{ maxHeight: "500px", overflowY: "scroll" }}>
            <CardHeader title="Species Breakdown" />
            <Divider />
            <CardContent>
                <Box
                    sx={{
                        height: 300,
                        position: "relative",
                    }}
                >
                    <Pie data={data} options={options} />
                </Box>
                <Divider variant="middle" sx={{ mt: 2 }} />
                <ScrollBar style={{ maxHeight: "400px", overflowY: "scroll" }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="h6">
                                        Species
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="h6">
                                        Volume (m3)
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="h6">
                                        Percent
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {species.map(({ name, value, color, percent }) => (
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="body2">
                                            {name}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body2">
                                            {value.toLocaleString("en-US")}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body2">
                                            {percent}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </ScrollBar>
            </CardContent>
        </Card>
    );
};

export default SpeciesBreakdown;
