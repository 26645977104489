import React, { useEffect } from "react";
import { Box, Container } from "@material-ui/core";
import TimberSaleListToolbar from "../components/saleList/TimberSaleListToolbar";
import TimberSaleList from "src/components/saleList/TimberSaleList";
import { useState } from "react";
import { GetSaleRegions, GetOpenSales, GetClosedSales } from "../utils/data";

import LogitHelmet from "src/components/shared/LogitHelmet";

const SaleList = () => {
    const [sales, setSales] = useState([]);
    const [saleRegions, setSaleRegions] = useState([]);
    const [filteredSales, setFilteredSales] = useState([]);

    //FILTERS
    const [viewingOpenSales, setViewingOpenSales] = useState(true);
    const [regionFilter, setRegionFilter] = useState([]);
    const [searchSaleFilter, setSearchSaleFilter] = useState("");

    const [isLoading, setIsLoading] = useState(true);

    async function GetSalesForCurrentState() {
        if (viewingOpenSales) {
            let openSales = await GetOpenSales();
            if (openSales === undefined) {
                return;
            }
            setSales(openSales);
        } else {
            let closedSales = await GetClosedSales();
            if (closedSales === undefined) {
                return;
            }
            setSales(closedSales);
        }
    }
    useEffect(() => {
        async function GetAndSetSales() {
            await GetSalesForCurrentState();
        }
        GetAndSetSales();
    }, []);

    // filter
    useEffect(() => {
        async function SetFilteredSales() {
            // apply region filter
            setIsLoading(true);
            let filteredSales = sales;
            if (Array.isArray(regionFilter) && regionFilter.length) {
                filteredSales = filteredSales.filter((row) => {
                    if (
                        Array.isArray(regionFilter) &&
                        regionFilter.length &&
                        (regionFilter.includes(row.region_name) ||
                            regionFilter.includes(
                                row.region_name.replace(" ", "-")
                            ) ||
                            regionFilter.includes(
                                row.region_name.replace("-", " ")
                            ))
                    ) {
                        return true;
                    }
                    return null;
                });
            } else {
                filteredSales = sales;
            }
            const filteredResults = filteredSales.filter((row) => {
                if (row.ffid) {
                    if (
                        row.ffid
                            .toLowerCase()
                            .includes(searchSaleFilter.toLowerCase())
                    ) {
                        return true;
                    } else if (row.location) {
                        if (
                            row.location
                                .toLowerCase()
                                .includes(searchSaleFilter.toLowerCase())
                        ) {
                            return true;
                        }
                    }
                }

                return null;
            });
            setFilteredSales(filteredResults);
            setIsLoading(false);
        }
        SetFilteredSales();
    }, [sales, regionFilter, searchSaleFilter, viewingOpenSales]);

    // runs the read of the correct sale state
    useEffect(() => {
        async function GetAndSetSales() {
            setIsLoading(true);
            await GetSalesForCurrentState();
        }
        GetAndSetSales();
    }, [viewingOpenSales]);

    useEffect(
        (regionFilter, searchSaleFilter) => {
            async function GetAndSetSaleRegions() {
                let regions = await GetSaleRegions();
                setSaleRegions(regions);
            }

            GetAndSetSaleRegions();
        },
        [regionFilter, searchSaleFilter]
    );
    function handleSearchChange() {
        setSearchSaleFilter(searchSaleFilter);
    }

    return (
        <div>
            <LogitHelmet pageName={"Open Sales"} />
            <Box
                sx={{
                    backgroundColor: "background.default",
                    minHeight: "100%",
                    with: "100%",
                    py: 3,
                    justifyContent: "center",
                }}
            >
                <Container maxWidth={false}>
                    <TimberSaleListToolbar
                        handleSearchChange={handleSearchChange}
                        setRegionFilter={setRegionFilter}
                        setSearchSaleFilter={setSearchSaleFilter}
                        setSaleStateFilter={setViewingOpenSales}
                        saleRegions={saleRegions}
                        isLoading={isLoading}
                    />
                    <>
                        <Box sx={{ pt: 3 }}>
                            <TimberSaleList
                                sales={filteredSales}
                                type={viewingOpenSales}
                                isLoading={isLoading}
                            />
                        </Box>
                    </>
                </Container>
            </Box>
        </div>
    );
};

export default SaleList;
