import { AppBar, Box, Button, Tab, Tabs } from "@material-ui/core";

import { ArrowBack } from "@material-ui/icons";
import { useLocation, useParams, useNavigate } from "react-router";
import { useEffect, useState } from "react";

import LogitHelmet from "src/components/shared/LogitHelmet";

import { GetSale, logUserSaleVisit } from "src/utils/data";

import SaleTab from "src/components/timbersale/SaleTab";
import AttachmentTab from "src/components/timbersale/AttachmentTab";
import BidTab from "src/components/timbersale/BidTab";
import { a11yProps, TabPanel } from "src/components/shared/LogitTabs";
import { GetSaleBids } from "src/utils/data";
import { getAppBarTopPadding } from "src/utils/helpers";

import { LANDING_PAGE } from "src/Constants";
import Share from "src/components/timbersale/Share";
import {
    addDoc,
    collection,
    getFirestore,
    serverTimestamp,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";

const Sale = (props) => {
    const navigation = useLocation();
    const location = useLocation();
    const currentPath = location.pathname;
    const { saleId } = useParams();

    const navigate = useNavigate();

    // eslint-disable-next-line no-unused-vars
    const [sale, setSale] = useState(navigation.state);
    const [saleBids, setSaleBids] = useState([]);
    const [pageHasLoaded, setPageHasLoaded] = useState(false);
    const [value, setValue] = useState(1);
    const db = getFirestore();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [appBarTopPadding, setAppBarTopPadding] = useState(64);

    useEffect(() => {
        async function GetBids() {
            let saleBids = null;
            if (sale) {
                if (sale.oppid) {
                    saleBids = await GetSaleBids(sale.ffid + sale.oppid);
                } else {
                    saleBids = await GetSaleBids(sale.ffid);
                }
            } else {
                return;
            }

            let winningBid = 0;
            saleBids.forEach((bid, index) => {
                if (index === 0) {
                    winningBid = bid.bid_per_cubic_m;
                    saleBids[index].difference = 0;
                } else {
                    let difference = 0;

                    difference = winningBid - bid.bid_per_cubic_m;

                    saleBids[index].difference = difference;
                }
            });

            setSaleBids(saleBids);
        }

        GetBids();
        setAppBarTopPadding(getAppBarTopPadding());

        setPageHasLoaded(true);
    }, [sale]);

    useEffect(() => {
        async function logViewToUser() {
            await logUserSaleVisit(getAuth().currentUser.uid, {
                saleId: saleId,
                url: currentPath,
                timestamp: serverTimestamp(),
            });
        }

        async function logViewToSale() {}
        logViewToUser();
    }, [saleId]);

    useEffect(() => {
        async function GetSaleData() {
            var sale = await GetSale(saleId);
            if (sale) {
                setSale(sale);
            } else {
                navigate(LANDING_PAGE, { replace: true });
            }
        }

        if (sale == null) {
            GetSaleData();
        }

        var dashboardNav = document.getElementById("dashNav");

        if (dashboardNav) {
            var rect = dashboardNav.getBoundingClientRect();

            if (rect) {
                setAppBarTopPadding(rect.bottom);
            }
        }

        setPageHasLoaded(true);
    }, []);

    useEffect(() => {
        saleBids.sort(
            (a, b) =>
                Number.parseFloat(b.bid_per_cubic_m) -
                Number.parseFloat(a.bid_per_cubic_m)
        );
    }, [saleBids]);

    return (
        <>
            {sale == null ? null : (
                <>
                    <LogitHelmet pageName={`Sale ${sale.ffid}`} />

                    <AppBar
                        color="white"
                        sx={{
                            top: appBarTopPadding,
                            left: { md: "none", lg: "256px" },
                            color: "accent",
                        }}
                    >
                        <Box
                            eleveation={0}
                            sx={{
                                borderBottom: 1,
                                borderColor: "divider",
                                elevation: 0,
                            }}
                            display="flex"
                            flexDirection="row"
                        >
                            <Tabs
                                position="sticky"
                                top={0}
                                value={value}
                                onChange={handleChange}
                                aria-label="Sale Tabs"
                                variant="scrollable"
                                indicatorColor="primary"
                                scrollButtons={false}
                                allowScrollButtonsMobile
                            >
                                <Button
                                    startIcon={<ArrowBack />}
                                    onClick={() =>
                                        window.history.length > 2
                                            ? window.history.back()
                                            : navigate(LANDING_PAGE)
                                    }
                                >
                                    back
                                </Button>
                                <Tab label="Sale" {...a11yProps(0)} />
                                <Tab label="Attachments" {...a11yProps(1)} />
                                {Date.parse(sale.closed_time) > Date.now() ? (
                                    <Tab
                                        label="Bid Calculator"
                                        {...a11yProps(2)}
                                    />
                                ) : (
                                    <Tab label="Bids" {...a11yProps(2)} />
                                )}{" "}
                                <Share />
                            </Tabs>
                        </Box>
                    </AppBar>
                    {/* <Share /> */}
                    <Box
                        sx={{
                            backgroundColor: "background.default",
                            minHeight: "100%",
                            py: 3,
                            pt: 5,
                            display: "sticky",
                        }}
                    >
                        <TabPanel value={value} index={1}>
                            <SaleTab sale={sale} />
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <AttachmentTab sale={sale} />
                        </TabPanel>
                        <TabPanel value={value} index={3}>
                            <BidTab sale={sale} bids={saleBids} />
                        </TabPanel>
                    </Box>
                </>
            )}
        </>
    );
};

export default Sale;
