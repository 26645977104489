import {
    Button,
    Box,
    TextField,
    IconButton,
    Tooltip,
    Grow,
    Snackbar,
    useTheme,
} from "@material-ui/core";

import ShareIcon from "@material-ui/icons/Share";
import ContentCopyIcon from "@material-ui/icons/ContentCopy";
import CancelIcon from "@material-ui/icons/Cancel";
import EmailIcon from "@material-ui/icons/Email";
import { useState } from "react";
import Typography from "src/theme/typography";
const Share = (props) => {
    const [isSharingActive, setIsSharingActive] = useState(false);
    const [copyToClipboardClicked, setCopyToClipboardClicked] = useState(false);

    const theme = useTheme();
    function handleClose() {
        setCopyToClipboardClicked(false);
    }

    return (
        <>
            <Snackbar
                open={copyToClipboardClicked}
                autoHideDuration={2000}
                onClose={handleClose}
                message="Copied Link to Clipboard"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            />
            <Box
                sx={{
                    // height: "80%",
                    alignSelf: "center",
                    display: "flex",
                }}
            >
                {isSharingActive ? (
                    <>
                        <Grow
                            in={isSharingActive}
                            style={{ transformOrigin: "0 0 0" }}
                            {...(isSharingActive ? { timeout: 250 } : {})}
                        >
                            <Tooltip title="Copy to Clipboard">
                                <IconButton
                                    onClick={() => {
                                        navigator.clipboard.writeText(
                                            window.location.href
                                        );
                                        setCopyToClipboardClicked(true);
                                    }}
                                >
                                    <ContentCopyIcon
                                        sx={{
                                            fill: theme.palette.accent.main,
                                        }}
                                    />
                                </IconButton>
                            </Tooltip>
                        </Grow>
                        <Grow
                            in={isSharingActive}
                            style={{ transformOrigin: "0 0 0" }}
                            {...(isSharingActive ? { timeout: 500 } : {})}
                        >
                            <Tooltip title="Share via Email">
                                <IconButton
                                    onClick={() =>
                                        window.open(
                                            `mailto:?subject=Checkout%20This%20Timbersale%20On%20LogitNow&body=${window.location.href}`,
                                            "_blank"
                                        )
                                    }
                                >
                                    <EmailIcon
                                        sx={{
                                            fill: theme.palette.accent.main,
                                        }}
                                    />
                                </IconButton>
                            </Tooltip>
                        </Grow>
                        <Grow
                            in={isSharingActive}
                            style={{ transformOrigin: "0 0 0" }}
                            {...(isSharingActive ? { timeout: 750 } : {})}
                        >
                            <Tooltip title="Cancel">
                                <IconButton
                                    onClick={() => setIsSharingActive(false)}
                                >
                                    <CancelIcon
                                        sx={{
                                            fill: theme.palette.accent.main,
                                        }}
                                    />
                                </IconButton>
                            </Tooltip>
                        </Grow>
                    </>
                ) : (
                    <Button
                        color="accent"
                        variant="contained"
                        onClick={() => setIsSharingActive(true)}
                        endIcon={<ShareIcon />}
                    >
                        share
                    </Button>
                )}
            </Box>
        </>
    );
};

export default Share;
