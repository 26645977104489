import React from "react";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { FloatingItem } from "../shared/FloatingItem";
import SignUpButton from "../shared/SignUpButton";

const FounderFeature = ({ theme }) => {
    return (
        <Grid container display="flex">
            <Grid
                item
                mt={2}
                mb={2}
                xs={9}
                md={4}
                fullWidth
                height="300px"
                width="300px"
            >
                <Icon
                    sx={{
                        display: "flex",
                        height: "inherit",
                        width: "inherit",
                        justifyContent: "center",
                        borderRadius: "50%",
                    }}
                >
                    <img
                        alt="Thumbnail"
                        src="/static/images/assets/team/brad.png"
                    />
                </Icon>
            </Grid>
            <Grid item xs={9} md={8} pl={5}>
                <FloatingItem>
                    <Typography
                        variant="h4"
                        color={theme.palette.secondary.main}
                    >
                        Brad Crump, creator of LogitNow
                    </Typography>
                </FloatingItem>
                <FloatingItem>
                    <Typography
                        variant="body1"
                        color={theme.palette.secondary.main}
                    >
                        When I served as an Operations Manager for a logging
                        contractor in the Cariboo, I found myself wrestling with
                        clunky, outdated tools. Frustrated by their limitations,
                        I took matters into my own hands and crafted a solution
                        that I'd love to use myself. That's how I created a
                        platform that houses all the essential data for my job.
                        But here's the thing;
                    </Typography>
                    <Typography
                        variant="body1"
                        color={theme.palette.secondary.main}
                        pt={2}
                    >
                        I'm not just about data and numbers; I'm all about
                        connecting with Timber Buyers and Loggers, because I've
                        been there. My goal is to offer a platform that
                        simplifies the Timber Buying and Management process. So,
                        let's make your work smoother, simpler, and more
                        efficient, together. Because I'm not just a builder; I'm
                        a fellow member of the logging community, here to make
                        your life easier.
                    </Typography>
                    <SignUpButton />
                </FloatingItem>
            </Grid>
        </Grid>
    );
};

export default FounderFeature;
