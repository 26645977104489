/* eslint-disable react-hooks/exhaustive-deps */
/* TODO: the above line needs to be removed and the code fixed to follow the convention*/
import React, { useEffect, useState } from "react";

import PerfectScrollbar from "react-perfect-scrollbar";
import {
    Box,
    Card,
    Chip,
    CircularProgress,
    IconButton,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    Tooltip,
} from "@material-ui/core";
import { NEW_SALE_THRESHOLD, CLOSING_SALE_THRESHOLD } from "src/Config";
import { useNavigate } from "react-router";
import { IsSaleValid } from "../../utils/validate";
import ClosingSoonChip from "src/components/timbersale/chips//ClosingSoonChip";
import NewChip from "src/components/timbersale/chips//NewChip";
import PreAdChip from "src/components/timbersale/chips//PreAdChip";
import { store } from "src/redux/Store";
import LogitTableSkeleton from "../shared/LogitTableSkeleton";
import moment from "moment/moment";

import { formatCurrency } from "src/utils/helpers";
import { formatDateAsString } from "src/utils/data";
import { Grid } from "@mui/material";

const options = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
};

const TimberSaleList = ({ sales, type, isLoading, ...rest }) => {
    const navigate = useNavigate();

    const [limit, setLimit] = useState(
        type
            ? store.getState().sales.open_sales_current_page_limit
            : store.getState().sales.closed_sales_current_page_limit
    );
    const [page, setPage] = useState(
        type
            ? store.getState().sales.open_sales_current_page
            : store.getState().sales.closed_sales_current_page
    );
    const [openTimberSales, setOpenTimberSales] = useState(undefined);

    const [startIndex, setStartIndex] = useState(
        type
            ? store.getState().sales.open_sales_current_page * limit
            : store.getState().sales.closed_sales_current_page * limit
    );

    useEffect(() => {
        setOpenTimberSales(sales);
    }, [sales]);

    useEffect(() => {
        if (!type) {
            setPage(store.getState().sales.closed_sales_current_page);
            setStartIndex(
                store.getState().sales.closed_sales_current_page * limit
            );
        }
    }, [store.getState().sales.closed_sales_current_page]);

    useEffect(() => {
        if (type) {
            setPage(store.getState().sales.open_sales_current_page);
            setStartIndex(
                store.getState().sales.open_sales_current_page * limit
            );
        }
    }, [store.getState().sales.open_sales_current_page]);

    useEffect(() => {
        if (type) {
            setLimit(store.getState().sales.open_sales_current_page_limit);
        }
    }, [store.getState().sales.open_sales_current_page_limit]);

    useEffect(() => {
        if (!type) {
            setLimit(store.getState().sales.closed_sales_current_page_limit);
        }
    }, [store.getState().sales.closed_sales_current_page_limit]);

    const handleLimitChange = (event) => {
        setLimit(event.target.value);
        if (event.target.value >= sales.length) {
            setStartIndex(0);
            setPage(0);
        }
        if (type) {
            store.dispatch({
                type: "Set_Open_Sales_Current_Page_Limit",
                payload: event.target.value,
            });
        } else {
            store.dispatch({
                type: "Set_Closed_Sales_Current_Page_Limit",
                payload: event.target.value,
            });
        }
    };

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
        if (type) {
            store.dispatch({
                type: "Set_Open_Sales_Current_Page",
                payload: newPage,
            });
        } else {
            store.dispatch({
                type: "Set_Closed_Sales_Current_Page",
                payload: newPage,
            });
        }
        setStartIndex(newPage * limit);
    };

    const handleSaleSelect = async (index, isSaleValid, text) => {
        if (isSaleValid && index != null) {
            let ffid = openTimberSales[startIndex + parseInt(index)].ffid;
            let oppid = openTimberSales[startIndex + parseInt(index)].oppid;

            let saleId = ffid;

            if (oppid !== undefined) {
                saleId = `${ffid}${oppid}`;
            }

            navigate("/app/sale/" + saleId, {
                state: openTimberSales[startIndex + parseInt(index)],
            });
        }
    };
    const loadTime = new Date();

    return (
        <Card {...rest}>
            <PerfectScrollbar>
                <Box sx={{ minWidth: 1050 }}>
                    <Table>
                        <TableHead>
                            {!isLoading ? (
                                <TableRow>
                                    <TableCell>Sale Id</TableCell>
                                    <TableCell>Cat</TableCell>
                                    <TableCell>Region</TableCell>
                                    <TableCell>Location</TableCell>
                                    <TableCell>Volume (m3)</TableCell>
                                    <TableCell>Density (m3/ha)</TableCell>
                                    <TableCell>
                                        <Tooltip
                                            enterDelay={300}
                                            title="For Cruise-based sales, this value is calculated based on Total Volume. If the sale does not have a 'Total Volume', 'Net Volume' is used."
                                        >
                                            <span>Stumpage Rate ($/m3)</span>
                                        </Tooltip>
                                    </TableCell>
                                    {/* <TableCell>Stumpage Type</TableCell> */}
                                    {type ? (
                                        <TableCell>Bidding Closes</TableCell>
                                    ) : (
                                        <>
                                            <TableCell>
                                                Winning Bid ($/m3)
                                            </TableCell>
                                            <TableCell>
                                                Bidding Closed
                                            </TableCell>
                                        </>
                                    )}
                                </TableRow>
                            ) : (
                                <LogitTableSkeleton cellCount={8} />
                            )}
                        </TableHead>
                        {isLoading ? (
                            <CircularProgress
                                sx={{
                                    m: 4,
                                }}
                            />
                        ) : (
                            <TableBody>
                                {openTimberSales !== undefined
                                    ? openTimberSales
                                          .slice(startIndex, startIndex + limit)
                                          .map((sale, index) => (
                                              <TableRow
                                                  hover
                                                  key={sale.id}
                                                  onClick={(e) =>
                                                      handleSaleSelect(
                                                          index,
                                                          IsSaleValid(sale)
                                                      )
                                                  }
                                                  id={index}
                                                  style={
                                                      IsSaleValid(sale)
                                                          ? {}
                                                          : {
                                                                background:
                                                                    "#E6E6E6",
                                                            }
                                                  }
                                              >
                                                  <TableCell
                                                      variant="head"
                                                      id={index}
                                                  >
                                                      <IconButton
                                                          key={sale.id}
                                                          id={index}
                                                      >
                                                          <Chip
                                                              color={
                                                                  type
                                                                      ? "accent"
                                                                      : "gray"
                                                              }
                                                              label={
                                                                  sale.ffid
                                                                      ? sale.ffid
                                                                      : ""
                                                              }
                                                              key={sale.id}
                                                              id={index}
                                                              size="small"
                                                              onClick={(e) => {
                                                                  handleSaleSelect(
                                                                      null,
                                                                      IsSaleValid(
                                                                          sale
                                                                      )
                                                                  );
                                                              }}
                                                          ></Chip>
                                                      </IconButton>
                                                      {sale.is_pread ? (
                                                          <IconButton
                                                              key={sale.id}
                                                              id={index}
                                                              onClick={(e) =>
                                                                  handleSaleSelect(
                                                                      null,
                                                                      IsSaleValid(
                                                                          sale
                                                                      )
                                                                  )
                                                              }
                                                          >
                                                              <PreAdChip />{" "}
                                                          </IconButton>
                                                      ) : null}
                                                      {sale.is_cancelled ? (
                                                          <IconButton
                                                              key={sale.id}
                                                              id={index}
                                                              onClick={(e) =>
                                                                  handleSaleSelect(
                                                                      null,
                                                                      IsSaleValid(
                                                                          sale
                                                                      )
                                                                  )
                                                              }
                                                          >
                                                              <Chip
                                                                  color="error"
                                                                  label="Cancelled"
                                                                  size="small"
                                                                  variant="outlined"
                                                              />{" "}
                                                          </IconButton>
                                                      ) : null}
                                                      {(new Date(
                                                          sale.closed_time
                                                      ) -
                                                          loadTime) /
                                                          (1000 * 3600 * 24) <
                                                          CLOSING_SALE_THRESHOLD &&
                                                      (new Date(
                                                          sale.closed_time
                                                      ) -
                                                          loadTime) /
                                                          (1000 * 3600 * 24) >
                                                          0 ? (
                                                          <IconButton
                                                              key={sale.id}
                                                              id={index}
                                                              onClick={(e) =>
                                                                  handleSaleSelect(
                                                                      null,
                                                                      IsSaleValid(
                                                                          sale
                                                                      )
                                                                  )
                                                              }
                                                          >
                                                              <ClosingSoonChip
                                                                  sale={sale}
                                                              />
                                                          </IconButton>
                                                      ) : null}

                                                      {(loadTime -
                                                          new Date(
                                                              sale.open_date
                                                          )) /
                                                          (1000 * 3600 * 24) <
                                                      NEW_SALE_THRESHOLD ? (
                                                          <IconButton
                                                              key={sale.id}
                                                              id={index}
                                                              onClick={(e) =>
                                                                  handleSaleSelect(
                                                                      null,
                                                                      IsSaleValid(
                                                                          sale
                                                                      )
                                                                  )
                                                              }
                                                          >
                                                              <NewChip />
                                                          </IconButton>
                                                      ) : null}
                                                  </TableCell>
                                                  <TableCell id={index}>
                                                      {sale.category
                                                          ? sale.category
                                                          : ""}
                                                  </TableCell>
                                                  <TableCell id={index}>
                                                      {sale.region_name
                                                          ? sale.region_name
                                                          : ""}
                                                  </TableCell>
                                                  <TableCell id={index}>
                                                      {sale.location
                                                          ? sale.location
                                                          : ""}
                                                  </TableCell>
                                                  <Tooltip
                                                      enterDelay={200}
                                                      arrow={true}
                                                      placement="left"
                                                      title={
                                                          sale.net_area
                                                              ? sale.net_volume
                                                                  ? "Net Volume"
                                                                  : sale.total_volume
                                                                  ? "Gross Volume"
                                                                  : ""
                                                              : ""
                                                      }
                                                  >
                                                      <TableCell id={index}>
                                                          {sale.net_volume
                                                              ? `${parseFloat(
                                                                    sale.net_volume
                                                                ).toLocaleString(
                                                                    "en-US"
                                                                )}`
                                                              : sale.total_volume
                                                              ? `${parseFloat(
                                                                    sale.total_volume
                                                                ).toLocaleString(
                                                                    "en-US"
                                                                )}`
                                                              : ""}
                                                      </TableCell>
                                                  </Tooltip>
                                                  <TableCell id={index}>
                                                      {sale.net_area
                                                          ? sale.net_volume
                                                              ? (
                                                                    parseFloat(
                                                                        sale.net_volume
                                                                    ) /
                                                                    parseFloat(
                                                                        sale.net_area
                                                                    )
                                                                ).toFixed(1)
                                                              : sale.total_volume
                                                              ? (
                                                                    parseFloat(
                                                                        sale.total_volume
                                                                    ) /
                                                                    parseFloat(
                                                                        sale.net_area
                                                                    )
                                                                ).toFixed(1)
                                                              : ""
                                                          : ""}
                                                  </TableCell>

                                                  <TableCell id={index}>
                                                      {sale.stumpage_rate_per_cubic_m
                                                          ? formatCurrency(
                                                                sale.stumpage_rate_per_cubic_m
                                                            )
                                                          : ""}
                                                  </TableCell>

                                                  {type ? (
                                                      <TableCell id={index}>
                                                          {formatDateAsString(
                                                              sale.closed_time,
                                                              "dddd MMM Do, h:mma"
                                                          )}
                                                      </TableCell>
                                                  ) : (
                                                      <>
                                                          <TableCell>
                                                              {sale.winning_client_name ===
                                                              ""
                                                                  ? sale.bid_note
                                                                  : sale.winning_bid_per_cubic_m ===
                                                                    0
                                                                  ? "< CA$0.01"
                                                                  : formatCurrency(
                                                                        sale.winning_bid_per_cubic_m
                                                                    )}
                                                          </TableCell>
                                                          <TableCell>
                                                              {formatDateAsString(
                                                                  sale.closed_time
                                                              )}
                                                          </TableCell>
                                                      </>
                                                  )}
                                                  {/* <TableCell id={index}>
                                                {type
                                                    ? sale.closed_time
                                                        ? new Date(
                                                              sale.closed_time
                                                          ).toLocaleDateString(
                                                              "en-US",
                                                              options
                                                          )
                                                        : "NA"
                                                    : sale.winning_client_name ===
                                                      ""
                                                    ? sale.bid_note
                                                    : sale.winning_bid_total
                                                    ? sale.stumpage_type ===
                                                      "cruise_based"
                                                        ? `$${parseFloat(
                                                              sale.winning_bid_total
                                                          ).toFixed(2)}`
                                                        : `$${parseFloat(
                                                              sale.winning_bid_per_cubic_m
                                                          ).toFixed(2)}`
                                                    : sale.is_pread
                                                    ? "Pre Ad"
                                                    : "Not yet available"}
                                            </TableCell> */}
                                              </TableRow>
                                          ))
                                    : null}
                            </TableBody>
                        )}
                    </Table>
                </Box>
            </PerfectScrollbar>
            <TablePagination
                component="div"
                count={sales.length}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={[5, 10, 25]}
            />
        </Card>
    );
};

export default TimberSaleList;
