import { Tooltip, Chip } from "@material-ui/core";
import { useEffect, useState } from "react";
import { CLOSING_SALE_THRESHOLD } from "src/Config";

function daysDifferenceFromToday(inputDate) {
    // Create Date objects for today's date and the input date
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set time to midnight
    inputDate.setHours(0, 0, 0, 0); // Set time to midnight

    // Calculate the time difference in milliseconds
    const timeDifference = inputDate - today;
    // Calculate the difference in days
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    return daysDifference;
}

const ClosingSoonChip = ({ sale }) => {
    let [label, setLabel] = useState("");

    useEffect(() => {
        if (sale) {
            let remainingTime;

            const daysUntilClosed = daysDifferenceFromToday(
                new Date(sale.closed_time)
            );
            const isClosingToday = daysUntilClosed === 0;
            const isClosingTomorrow = daysUntilClosed === 1;

            if (isClosingToday) {
                remainingTime = "Today";
            } else if (isClosingTomorrow) {
                remainingTime = "Tomorrow";
            } else if (daysUntilClosed === 7) {
                remainingTime = "1 week";
            } else {
                remainingTime = `${daysUntilClosed} days`;
            }
            setLabel(remainingTime);
        }
    }, [sale]);

    return (
        <Chip
            color="closing"
            label={`${label}`}
            size="small"
            variant="outlined"
        />
    );
};

export default ClosingSoonChip;
