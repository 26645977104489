import { useState } from "react";
import {
    Box,
    Button,
    Card,
    CardHeader,
    Divider,
    IconButton,
    Snackbar,
    Alert,
    CircularProgress,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import SendIcon from "@material-ui/icons/Send";
import CancelIcon from "@material-ui/icons/Cancel";
import { SendResetPasswordEmail } from "src/utils/auth";

const SettingsPassword = (props) => {
    const [isEditingPassword, setIsEditingPassword] = useState(false);
    const [sendingUpdatePasswordEmail, setSendingUpdatePasswordEmail] =
        useState(false);
    const [passwordResetEmailSent, setPasswordResetEmailSent] = useState(false);
    const [isErrorSendingEmail] = useState(false);

    const handleClose = () => {
        setPasswordResetEmailSent(false);
    };

    async function handleSendVerificationEmail() {
        setSendingUpdatePasswordEmail(true);
        var result = await SendResetPasswordEmail(props.user.email);
        setSendingUpdatePasswordEmail(false);
        if (result) {
            setPasswordResetEmailSent(true);
            setIsEditingPassword(false);
        } else {
            isErrorSendingEmail(true);
        }
    }

    return (
        <>
            <Snackbar
                open={passwordResetEmailSent}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity="success">
                    Successfully sent the password reset email
                </Alert>
            </Snackbar>
            <form {...props}>
                <Card>
                    <CardHeader
                        subheader="Update password"
                        title="Password"
                        action={
                            <IconButton
                                aria-label="settings"
                                onClick={() =>
                                    setIsEditingPassword(!isEditingPassword)
                                }
                            >
                                {isEditingPassword ? (
                                    <CancelIcon />
                                ) : (
                                    <EditIcon />
                                )}
                            </IconButton>
                        }
                    />
                    <Divider />
                    {isEditingPassword ? (
                        <>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    p: 2,
                                }}
                            >
                                <Button
                                    color="primary"
                                    variant="contained"
                                    endIcon={<SendIcon />}
                                    onClick={() =>
                                        handleSendVerificationEmail()
                                    }
                                >
                                    {sendingUpdatePasswordEmail && (
                                        <CircularProgress size={14} />
                                    )}
                                    {!sendingUpdatePasswordEmail &&
                                        "Send Password Reset Email"}
                                </Button>
                            </Box>
                        </>
                    ) : null}
                </Card>
            </form>
        </>
    );
};

export default SettingsPassword;
