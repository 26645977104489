import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { useEffect } from "react";
import { store } from "src/redux/Store";
import { CacheRegionFilter } from "src/utils/cache";
import { Box, Skeleton } from "@material-ui/core";
import { Chip } from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 200,
        },
    },
};

const RegionFilter = (props) => {
    const [region, setRegion] = React.useState([]);
    useEffect(() => {
        let regions = store.getState().filter.regions;
        if (regions.length > 0) {
            setRegion(regions);
            props.setRegionFilter(regions);
        }

        props.handleSearchChange();
    }, []);

    useEffect(() => {
        let regions = store.getState().filter.regions;
        if (regions.length > 0) {
            setRegion(regions);
            props.setRegionFilter(regions);
        }

        props.handleSearchChange();
    }, [region]);

    const handleChange = async (event) => {
        const {
            target: { value },
        } = event;
        let regions = typeof value === "string" ? value.split(",") : value;
        setRegion(regions);

        props.setRegionFilter(regions);
        await CacheRegionFilter(regions);
        props.handleSearchChange();
    };

    return props.saleRegions.length === 0 ? (
        <Skeleton />
    ) : (
        <FormControl
            sx={{ m: 1, width: "100%", height: "auto", maxWidth: "350px" }}
        >
            <InputLabel id="demo-multiple-checkbox-label">
                Business Area
            </InputLabel>

            <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={region}
                onChange={handleChange}
                input={<OutlinedInput label="Business Area" />}
                renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.25 }}>
                        {selected.map((value) => (
                            <Chip color="primary" key={value} label={value} />
                        ))}
                    </Box>
                )}
                MenuProps={MenuProps}
            >
                {props.saleRegions.map((r) => (
                    <MenuItem key={r.pretty_name} value={r.pretty_name}>
                        <Checkbox
                            color="primary"
                            checked={region.indexOf(r.pretty_name) > -1}
                        />
                        <ListItemText primary={r.pretty_name} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default RegionFilter;
