import {
    Container,
    Grid,
    IconButton,
    Card,
    CardHeader,
    Divider,
} from "@material-ui/core";

import AttachmentList from "src/components/timbersale//AttachmentList";

import { useLocation } from "react-router";
import { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { GetDownloadUrl } from "src/utils/cloudStorage";
import { isMobile } from "src/utils/helpers";
import OpenInNew from "@material-ui/icons/OpenInNew";

const AttachmentTab = (props) => {
    const navigation = useLocation();
    const [sale, setSale] = useState(props.sale);
    const [currentAttachment, setCurrentAttachment] = useState(null);
    const [currentAttachmentName, setCurrentAttachmentName] = useState(null);
    useEffect(() => {
        setSale(props.sale);
        pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    }, [navigation.state]);

    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener("resize", handleWindowSizeChange);
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    const [numPages, setNumPages] = useState(null);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    function onAttachmentFullScreen() {
        window.open(currentAttachment);
    }

    async function onAttachmentSelect(attachment) {
        /* when attachment is not defined (i.e. when the user clicks on the "search" button), attachment is an event instead of an attachment object.
           using id is a hack to make sure we're looking at an attachment */
        if (attachment && attachment.id) {
            var download_url = await GetDownloadUrl(attachment.url);
            setCurrentAttachment(download_url);
            setCurrentAttachmentName(attachment.id);
            if (isMobile()) {
                window.open(download_url);
            }
        }
    }

    return (
        <Container maxWidth={false}>
            <Grid container spacing={3}>
                <Grid item lg={12} md={12} xl={12} xs={12}>
                    <AttachmentList
                        ffid={sale.oppid ? sale.ffid + sale.oppid : sale.ffid}
                        onSelect={onAttachmentSelect}
                        currentAttachment={currentAttachment}
                        currentAttachmentName={currentAttachmentName}
                    />
                </Grid>
                {isMobile() ? null : (
                    <Grid item lg={12} md={12} xl={12} xs={12}>
                        <Card m={3}>
                            <CardHeader
                                title={currentAttachmentName}
                                avatar={
                                    <div>
                                        <IconButton
                                            onClick={onAttachmentFullScreen}
                                        >
                                            <OpenInNew />
                                        </IconButton>
                                    </div>
                                }
                            ></CardHeader>
                            <Divider />
                            <div
                                id="this-container"
                                style={{
                                    justifyContent: "center",
                                    overflow: "hidden",
                                    zoom: "100%",
                                    overflowY: "scroll",
                                    overflowX: "scroll",
                                    height: "auto",
                                    maxHeight: 1000,
                                    alignItems: "center",
                                }}
                            >
                                {currentAttachment ? (
                                    <Document
                                        file={currentAttachment}
                                        onLoadSuccess={onDocumentLoadSuccess}
                                    >
                                        {Array.from(
                                            new Array(numPages),
                                            (el, index) => (
                                                <Page
                                                    key={`page_${index + 1}`}
                                                    pageNumber={index + 1}
                                                    debug={true}
                                                    width={
                                                        isMobile()
                                                            ? "auto"
                                                            : document
                                                                  .getElementById(
                                                                      "this-container"
                                                                  )
                                                                  .getBoundingClientRect()
                                                                  .width
                                                    }
                                                />
                                            )
                                        )}
                                    </Document>
                                ) : null}
                            </div>
                        </Card>
                    </Grid>
                )}
            </Grid>
        </Container>
    );
};

export default AttachmentTab;
