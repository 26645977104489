import { TIMBERSALE_COLLECTION } from "src/Constants";
import { store } from "src/redux/Store";

const { GetBidderHistory, GetCutBlocks, GetSale } = require("./data");

export async function GetUserFromCache(id) {
    let user = store.getState().subscription.user;
    return user;
}
export async function GetBidderHistoryFromCache(client_id) {
    let cachedBidderHistory = store.getState().bid.bidder_history;

    let bidderHistory = await GetBidderHistory(client_id);

    return bidderHistory;
}

export async function GetOpenSalesFromCache() {
    return store.getState().sales.openSales;
}

export async function GetClosedSalesFromCache() {
    return store.getState().sales.closedSales;
}

export async function GetCutBlocksFromCache(saleId) {
    let saleGeojsons = store.getState().geojson.geojson;
    if (saleId in saleGeojsons) {
        return saleGeojsons[saleId];
    }

    return await GetCutBlocks(saleId);
}

export async function SetCutBlocksInCache(saleId, json) {
    let saleGeojsons = store.getState().geojson.geojson;
    saleGeojsons[saleId] = json;

    await store.dispatch({
        type: "Set_Sale_Geojson",
        payload: saleGeojsons,
    });
}

export async function GetSalesFromCacheByIds(saleIds) {}

export async function CacheSales(sales) {
    store.dispatch({
        type: "Set_Sales",
        payload: sales,
    });
}

export async function CachePayloadByType(type, payload) {
    await store.dispatch({
        type: type,
        payload: payload,
    });
}

export async function CacheOpenSales(sales) {
    await CachePayloadByType("Set_Open_Sales", sales);
}

export async function CacheClosedSales(sales) {
    await CachePayloadByType("Set_Closed_Sales", sales);
}

export async function GetSaleRegionsFromCache() {
    return store.getState().sales.regions;
}

export async function CacheSaleRegions(regions) {
    await store.dispatch({
        type: "Set_Regions",
        payload: regions,
    });
}

export async function CacheRegionFilter(regions) {
    await store.dispatch({
        type: "Set_Region_Filter",
        payload: regions,
    });
}
