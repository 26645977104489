import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    Box,
    Button,
    Typography,
    TextField,
    Grid,
    InputAdornment,
    List,
    ListItem,
    ListItemText,
    CircularProgress,
    SvgIcon,
    Divider,
    useTheme,
    ButtonGroup,
    IconButton,
} from "@material-ui/core";
import CopyrightIcon from "@material-ui/icons/Copyright";
import MailIcon from "@material-ui/icons/Mail";
import Thumbnail from "src/components/Thumbnail";
import { Facebook, LinkedIn, GitHub, Mail, YouTube } from "@material-ui/icons";
import ContactUsButton from "../shared/ContactUsButton";

const Footer = () => {
    const theme = useTheme();

    const navigate = useNavigate();

    return (
        <div
            sx={{
                position: "absolute",
                bottom: 0,
                left: 0,
                boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.2)",
            }}
        >
            <Grid
                container
                p={5}
                sx={{
                    backgroundColor: theme.palette.secondary.main,
                    boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.2)",
                    display: "flex",
                    flexDirection: "row",
                    height: "auto", //It can be set to auto later
                    justifyContent: "space-around",
                    alignItems: "stretch",
                }}
            >
                <Grid
                    container
                    spacing={2}
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        height: "auto", //It can be set to auto later
                        justifyContent: "space-around",
                    }}
                >
                    <Grid
                        item
                        xl={3}
                        lg={3}
                        md={3}
                        xs={12}
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            height: "auto", //It can be set to auto later
                            justifyContent: "flex-start",
                            color: "primary.contrastText",
                            alignItems: "center",
                            pb: 3,
                        }}
                    >
                        <Thumbnail />

                        <Typography color="primary.contrastText" variant="h4">
                            Logit Now
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xl={3}
                        lg={3}
                        md={3}
                        xs={12}
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            height: "auto", //It can be set to auto later
                            justifyContent: "flex-start",
                            color: "primary.contrastText",
                            alignItems: "center",
                            pb: 3,
                        }}
                    >
                        <Grid item>
                            <Typography
                                color="primary.contrastText"
                                variant="h4"
                            >
                                Company
                            </Typography>
                        </Grid>
                        <Grid item>
                            <List>
                                <ListItem
                                    disablePadding
                                    button={true}
                                    onClick={() => navigate("/services")}
                                >
                                    <ListItemText primary="Our Services" />
                                </ListItem>

                                <ContactUsButton
                                    color="secondary"
                                    disableElevation
                                />
                            </List>
                        </Grid>
                    </Grid>

                    <Grid
                        item
                        xl={3}
                        lg={3}
                        md={3}
                        xs={12}
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            height: "auto", //It can be set to auto later
                            justifyContent: "flex-start",
                            color: "primary.contrastText",
                            alignItems: "center",
                            pb: 3,
                        }}
                    >
                        <Grid item>
                            <Typography
                                color="primary.contrastText"
                                variant="h4"
                            >
                                Legal
                            </Typography>
                        </Grid>
                        <Grid item>
                            <List>
                                <ListItem
                                    disablePadding
                                    button={true}
                                    onClick={() => navigate("/legal/terms")}
                                >
                                    <ListItemText primary="Terms and Conditions" />
                                </ListItem>
                                <ListItem
                                    disablePadding
                                    button={true}
                                    onClick={() => navigate("/legal/privacy")}
                                >
                                    <ListItemText primary="Privacy Policy" />
                                </ListItem>
                            </List>
                        </Grid>
                    </Grid>

                    <Grid
                        item
                        xl={3}
                        lg={3}
                        md={3}
                        xs={12}
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            height: "auto",
                            justifyContent: "center",
                            alignItems: "center",
                            pb: 3,
                        }}
                    >
                        <ButtonGroup
                            variant="outlined"
                            color="primary"
                            aria-label="large outlined button group"
                            backgroundColor="secondary"
                            size="large"
                        >
                            <IconButton
                                size="large"
                                sx={{
                                    color: theme.palette.accent.main,
                                }}
                                onClick={() =>
                                    window.open(
                                        "https://www.facebook.com/LogitAnalytics",
                                        "_blank"
                                    )
                                }
                            >
                                <Facebook />
                            </IconButton>
                            <IconButton
                                // edge="end"
                                size="large"
                                sx={{
                                    color: theme.palette.accent.main,
                                }}
                                onClick={() =>
                                    window.open(
                                        "https://www.linkedin.com/company/logit-analytics",
                                        "_blank"
                                    )
                                }
                            >
                                <LinkedIn styles={{}} />
                            </IconButton>
                            <IconButton
                                size="large"
                                sx={{
                                    color: theme.palette.accent.main,
                                }}
                                onClick={() =>
                                    window.open(
                                        "https://www.youtube.com/channel/UCLcLNiV2h-BTVR_3SobtwdQ",
                                        "_blank"
                                    )
                                }
                            >
                                <YouTube />
                            </IconButton>
                            <IconButton
                                // edge="end"
                                size="large"
                                sx={{
                                    color: theme.palette.accent.main,
                                }}
                                onClick={() =>
                                    window.open(
                                        "https://github.com/LogItNow",
                                        "_blank"
                                    )
                                }
                            >
                                <GitHub styles={{}} />
                            </IconButton>
                            <IconButton
                                size="large"
                                sx={{
                                    color: theme.palette.accent.main,
                                }}
                                onClick={() =>
                                    window.open(
                                        "mailto:support@logitnow.ca",
                                        "_blank"
                                    )
                                }
                            >
                                <Mail />
                            </IconButton>
                        </ButtonGroup>
                    </Grid>
                </Grid>
                <Grid item>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            p: 1,
                        }}
                    >
                        <Typography color="#fff" variant="body2">
                            {new Date().getFullYear()} Logit Analytics Ltd.
                        </Typography>
                        <CopyrightIcon sx={{ fill: "white" }} />
                    </Box>
                </Grid>
            </Grid>
            <Divider sx={{ backgroundColor: "primary.contrastText" }} />
        </div>
    );
};

export default Footer;
