import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { CreateSubscriptionCheckoutSession } from "src/utils/data";
import {
    Alert,
    AlertTitle,
    Box,
    Button,
    CircularProgress,
    Container,
    Typography,
} from "@material-ui/core";
import {
    collection,
    doc,
    getDoc,
    getDocs,
    getFirestore,
    query,
    where,
} from "firebase/firestore";
import ProductCard from "src/components/product/ProductCard";
import LogitHelmet from "src/components/shared/LogitHelmet";
import { store } from "src/redux/Store";
import {
    LANDING_PAGE,
    LOGITNOW_BASE_PRODUCT,
    SALE_PAGE_BASE,
} from "src/Constants";
import { WindowSharp } from "@material-ui/icons";

const Payment = () => {
    const navigate = useNavigate();
    const navigation = useLocation();
    const [product, setProduct] = useState({});
    const [prices, setPrices] = useState([]);
    const [saleId, setSaleId] = useState(
        navigation.state ? navigation.state.saleId : null
    );
    const db = getFirestore();

    const [priceId, setPriceId] = useState(null);
    const [priceOptionSelected, setPriceOptionSelected] = useState(true);
    const [subscriptionButtonClicked, setSubscriptionButtonClicked] =
        useState(false);

    async function f() {
        const docRef = doc(db, "products", LOGITNOW_BASE_PRODUCT);
        getDoc(docRef).then(async function (doc) {
            if (doc.exists()) {
                setProduct(doc.data());

                let priceQuery = query(
                    collection(db, "products", LOGITNOW_BASE_PRODUCT, "prices"),
                    where("active", "==", true)
                );

                const priceSnap = await getDocs(priceQuery);
                const promise = priceSnap.docs.map(async (price) => {
                    return { id: price.id, ...price.data() };
                });
                var pricesArray = await Promise.all(promise);

                setPrices(pricesArray);
            }
        });
    }
    useEffect(() => {
        f();
    }, []);

    function handlePriceSelect(priceId) {
        setPriceId(priceId);
        setPriceOptionSelected(true);
    }

    async function Subscribe() {
        if (!priceId) {
            setPriceOptionSelected(false);
            return;
        }
        setSubscriptionButtonClicked(true);
        CreateSubscriptionCheckoutSession(
            priceId,
            saleId == null ? "" : `${SALE_PAGE_BASE}${saleId}`
        );
        setTimeout(() => {
            store.dispatch({
                type: "Set_Is_User_Subscribed",
                payload: true,
            });

            saleId == null
                ? navigate(LANDING_PAGE, {
                      replace: true,
                  })
                : navigate(`${SALE_PAGE_BASE}${saleId}`, { replace: true });
        }, 3000);
    }

    return (
        <>
            <LogitHelmet pageName={"Subscription"} />
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    pt: 25,
                    marginBottom: 30,
                }}
            >
                <Container maxWidth="md">
                    <Typography color="secondary" variant="h4">
                        Pick a Pricing Option
                    </Typography>

                    <Box sx={{ height: 0.05, flexGrow: 0.1 }} />
                    <ProductCard
                        product={product}
                        prices={prices}
                        handleSelect={handlePriceSelect}
                    />
                    <Box sx={{ height: 0.05, flexGrow: 0.1 }} />
                    {!priceOptionSelected ? (
                        <Alert severity="error" variant="body2">
                            <AlertTitle>Error</AlertTitle>
                            Select a pricing option
                        </Alert>
                    ) : null}
                    <Button
                        color="primary"
                        disabled={subscriptionButtonClicked}
                        fullWidth
                        size="large"
                        variant="contained"
                        onClick={() => {
                            Subscribe();
                        }}
                    >
                        {subscriptionButtonClicked && (
                            <CircularProgress size={14} />
                        )}
                        {!subscriptionButtonClicked && "Proceed to Payment"}
                    </Button>
                </Container>
            </Box>
        </>
    );
};

export default Payment;
