import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Divider,
    Typography,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { collection, doc, getDoc, getFirestore } from "firebase/firestore";

const AccountProfile = (props) => {
    const [user, setUser] = useState(props.user);
    const db = getFirestore();
    useEffect(() => {
        async function GetAndSetUser() {
            let user = await getDoc(doc(db, "users", props.user.id));
            setUser(user.data());
        }
        GetAndSetUser();
    }, [user.id]);

    return (
        <Card {...props}>
            <CardContent>
                <Box
                    sx={{
                        alignItems: "center",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <Avatar
                        src={user.avatar}
                        sx={{
                            height: 100,
                            width: 100,
                        }}
                    />
                    <Typography color="textPrimary" gutterBottom variant="h3">
                        {user.name}
                    </Typography>
                    <Typography color="textPrimary" gutterBottom variant="h4">
                        {user.organization}
                    </Typography>
                </Box>
            </CardContent>
            <Divider />
            <CardActions>
                <Button color="primary" fullWidth variant="text">
                    Upload picture
                </Button>
            </CardActions>
        </Card>
    );
};

export default AccountProfile;
