import {
    createUserWithEmailAndPassword,
    getAuth,
    signInWithEmailAndPassword,
} from "firebase/auth";
import { getFirestore, getDoc, doc } from "firebase/firestore";
import { FREE_TRIAL_DAYS } from "src/Config";

async function SignInWithEmailAndPassword(email, password) {
    try {
        var userCredential = signInWithEmailAndPassword(
            getAuth(),
            email,
            password
        );
        if (userCredential) {
            return true;
        }
    } catch (error) {
        return false;
    }
}

async function SignUpWithEmailAndPassword(email, password) {
    try {
        console.debug("SignUpWithEmailAndPassword creating user");
        const auth = await getAuth();

        let cred = createUserWithEmailAndPassword(auth, email, password).then(
            (userCredential) => {
                return userCredential;
            }
        );
        return cred;
    } catch (error) {
        console.log(error);
        return null;
    }
}

async function SendResetPasswordEmail(email) {
    try {
        if (!email) {
            return false;
        }
        getAuth().sendPasswordResetEmail(email);
        return true;
    } catch (error) {
        return false;
    }
}

async function SignOutUserAsync() {
    try {
        getAuth().signOut();
    } catch (error) {
        console.log(error);
    }
}

async function IsOnFreeTrial(user) {
    const db = getFirestore();
    const userRef = doc(db, "users", user.uid);
    let trialing = await getDoc(userRef).then((doc) => {
        if (doc.exists) {
            let dateJoined = doc.data().date_joined.toDate();

            const trialPeriodEnds = new Date(dateJoined);
            trialPeriodEnds.setDate(
                trialPeriodEnds.getDate() + FREE_TRIAL_DAYS
            );

            const currentDate = new Date();

            // Compare the timestamps
            const isTrialing =
                trialPeriodEnds.getTime() > currentDate.getTime();
            if (isTrialing) {
                return true;
            } else {
                return false;
            }
        }
    });

    return trialing;
}

export {
    SignInWithEmailAndPassword,
    SignUpWithEmailAndPassword,
    SignOutUserAsync,
    SendResetPasswordEmail,
    IsOnFreeTrial,
};
