import { Navigate } from "react-router-dom";
import MainLayout from "src/components/MainLayout";
import Login from "src/pages/Login";
import Services from "src/pages/Services";
import NotFound from "src/pages/NotFound";
import Register from "src/pages/Register";
import Verification from "./pages/Verification";
import ForgotPassword from "./pages/ForgotPassword";
import Home from "src/pages/Home";
import Legal from "./pages/Legal";
import AboutUs from "./pages/AboutUs";

const publicRoutes = [
  {
    path: "/",
    element: <MainLayout />,
    children: [
      { path: "home", element: <Home /> },
      { path: "app/sale/:saleId", element: <Home /> },
      { path: "services", element: <Services /> },
      { path: "about", element: <AboutUs /> },
      { path: "legal", element: <Legal tab={0} /> },
      { path: "legal/privacy", element: <Legal tab={1} /> },
      { path: "legal/terms", element: <Legal tab={0} /> },
      { path: "login", element: <Login /> },
      { path: "register", element: <Register /> },
      { path: "register/verification", element: <Verification /> },
      { path: "login/forgotpassword", element: <ForgotPassword /> },
      { path: "404", element: <NotFound /> },
      { path: "/", element: <Navigate to="/home" /> },
      { path: "*", element: <Navigate to="/home" /> },
    ],
  },
];

export default publicRoutes;
