import React, { useState } from "react";
import { Box, Grid, Typography, Tabs, Tab, useTheme } from "@material-ui/core";
import LogitHelmet from "src/components/shared/LogitHelmet";
import { a11yProps, TabPanel } from "src/components/shared/LogitTabs";
import Automate from "src/components/services/Automate";
import Drone from "src/components/services/Drone";
import Programming from "src/components/services/Programming";
const Services = () => {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const theme = useTheme();
    return (
        <>
            <LogitHelmet pageName={"Services"}></LogitHelmet>

            <Box
                elevation={3}
                pt={5}
                sx={{
                    borderTop: 1,
                    borderBottom: 1,
                    borderColor: "divider",
                    elevation: 2,
                    backgroundColor: theme.palette.secondary.main,
                }}
            >
                <Grid
                    container
                    backgroundColor="primary"
                    display="flex"
                    justifyContent="center"
                    p={10}
                >
                    <Grid item xs={12} md={7} pb={3}>
                        <Typography
                            variant="h1"
                            color={theme.palette.white.main}
                            xs={12}
                            elevation={10}
                        >
                            Services
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <Typography
                            variant="body1"
                            color={theme.palette.white.main}
                        >
                            At Logit, we do a lot more than build software for
                            forestry. Regardless of your industry, we love to
                            take on challenges that see your time-savings grow.
                            We've integrated mission-critical software for
                            multi-national corporations and worked with small,
                            local businesses alike. Check out just a few of our
                            services below!
                        </Typography>
                    </Grid>
                </Grid>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    backgroundColor="paper.default"
                    aria-label="Service Tabs"
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                    style={{
                        color: "primary",
                        backgroundColor: theme.palette.paper.default,
                    }}
                >
                    <Tab label="Programming" {...a11yProps(0)} />
                    <Tab label="Automation" {...a11yProps(1)} />
                    <Tab label="Drones" {...a11yProps(2)} />
                </Tabs>
            </Box>

            <Box
                sx={{
                    backgroundColor: "paper.default",
                    minHeight: "100%",
                    py: 0,
                    p: { md: 2, lg: 5, xl: 5 },
                }}
            >
                <TabPanel value={value} index={0}>
                    <Programming />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Automate />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <Drone />
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <Typography>GIS</Typography>
                </TabPanel>
                <TabPanel value={value} index={4}>
                    <Typography>IT</Typography>
                </TabPanel>
                <TabPanel value={value} index={5}>
                    <Typography>Ortho</Typography>
                </TabPanel>
            </Box>
        </>
    );
};

export default Services;
